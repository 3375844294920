.button__01 {
  text-align: right;
  @include mq(sp) {
    text-align: center;
  }
  a {
    position: relative;
    display: inline-block;
    border: 1px solid $hard;
    background: #fff;
    font-size: 18px;
    line-height: 50px;
    @include mq(sp) {
      @include fz_vw(16);
      border: 0;
      background: $main;
      line-height: 40px;
    }
    span {
      position: relative;
      display: block;
      padding: 0 70px 0 50px;
      color: $hard;
      z-index: 1;
      @include mq(sp) {
        color: #fff;
      }
      &::before {
        position: absolute;
        top: 50%;
        right: 20px;
        content: "";
        display: block;
        width: 30px;
        height: 1px;
        background: $hard;
        transition: .3s;
        @include mq(sp) {
          background: #fff;
        }
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 20px;
        content: "";
        display: block;
        width: 10px;
        height: 1px;
        background: $hard;
        transform: translateY(-4px) rotate(45deg);
        transition: .3s;
        @include mq(sp) {
          width: 7px;
          background: #fff;
          transform: translateY(-3px) rotate(45deg);
        }
      }
    }
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 0;
      background: $sub;
      transition: .3s;
    }
    &:hover {
      &::before {
        @include mq(pc) {
          width: 100%;
        }
      }
      span {
        &::before {
          @include mq(pc) {
            right: 10px;
            width: 40px;
          }
        }
        &::after {
          @include mq(pc) {
            right: 10px;
          }
        }
      }
    }
  }
}

.button__02 {
  text-align: center;
  @include mq(sp) {
    text-align: center;
  }
  a {
    position: relative;
    display: inline-block;
    border: 1px solid $hard;
    background: #fff;
    font-size: 25px;
    font-weight: 500;
    line-height: 92px;
    text-align: left;
    @include mq(sp) {
      @include fz_vw(18);
      line-height: 65px;
    }
    span {
      position: relative;
      display: block;
      width: 310px;
      padding: 0 70px 0 50px;
      color: $hard;
      z-index: 1;
      @include mq(sp) {
        width: 60vw;
        padding: 0 50px 0 30px;
      }
      &::before {
        position: absolute;
        top: 50%;
        right: 20px;
        content: "";
        display: block;
        width: 30px;
        height: 1px;
        background: $hard;
        transition: .3s;
        @include mq(sp) {
        }
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 20px;
        content: "";
        display: block;
        width: 10px;
        height: 1px;
        background: $hard;
        transform: translateY(-4px) rotate(45deg);
        transition: .3s;
        @include mq(sp) {
          width: 7px;
          transform: translateY(-3px) rotate(45deg);
        }
      }
    }
    &::before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 0;
      background: $sub;
      transition: .3s;
    }
    &:hover {
      &::before {
        @include mq(pc) {
          width: 100%;
        }
      }
      span {
        &::before {
          @include mq(pc) {
            right: 10px;
            width: 40px;
          }
        }
        &::after {
          @include mq(pc) {
            right: 10px;
          }
        }
      }
    }
  }
}