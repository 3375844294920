/* @media class
-------------------------------- */

.sp {
  display: none;
  @include mq(sp) {
    display: block;
  }
}
.tab {
  display: none;
  @include mq(tab) {
    display: block;
  }
}
.pc {
  display: none;
  @include mq(pc) {
    display: block;
  }
}
.spi {
  display: none;
  @include mq(sp) {
    display: inline;
  }
}
.tabi {
  display: none;
  @include mq(tab) {
    display: inline;
  }
}
.pci {
  display: none;
  @include mq(pc) {
    display: inline;
  }
}


/* @group float
-------------------------------- */
.right {
  float: right;
}
.left {
  float: left;
}
.img-left {
  float: left;
  margin: 0 20px 10px 0;
}
.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @status font color
-------------------------------- */
.error-message {
  color: #f40000;
}
