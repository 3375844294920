.area-map {
  margin-bottom: 80px;
  //background: url(../img/top/img_map_02.png) center -220px no-repeat;
  background: url(../img/top/img_map_03.png) center 200px no-repeat;
  @include mq(sp) {
    margin-bottom: 40px;
    background: none;
  }
  &__inner {
    @include base-wrap--sp();
    position: relative;
    height: 1200px;
    @include mq(sp) {
      height: auto;
    }
  }

  &__main {
    @include flex();
    position: absolute;
    top: 130px;
    left: 0;
    @include mq(sp) {
      position: static;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
    &__title {
      padding: 25px 30px;
      background: $black;
      color: #fff;
      font-size: 22px;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      @include mq(sp) {
        @include fz_vw(18);
        padding: 5px 5vw;
      }
      .bge-title-h2, .bge-title-h3 {
        padding: 0;
        background: none;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.5;
        @include mq(sp) {
          @include fz_vw(18);
        }
      }
      a {
        display: block;
        color: #fff;
      }
    }
    ul {
      @include flex(wrap, flex-start);
      width: 450px;
      padding: 30px 0 30px 50px;
      background: $light-gray;
      font-size: 17px;
      @include mq(sp) {
        width: auto;
        padding: 5px 5vw;
      }
      li {
        width: 45%;
        margin-right: 5%;
        @include mq(sp) {
          width: auto;
          margin-right: 0;
          border-bottom: 1px solid $black;
        }
        &::before {
          content: "・";
          @include mq(sp) {
            display: none;
          }
        }
        &:last-child {
          @include mq(sp) {
            border-bottom: 0;
          }
        }
        a {
          text-decoration: underline;
          @include mq(sp) {
            display: block;
            padding: 10px 0;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__part {
    position: absolute;
    padding: 20px 40px 30px 30px;
    background: $main;
    color: #fff;
    @include mq(sp) {
      position: static;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0;
      text-align: center;
    }
    &__title {
      margin-bottom: 6px;
      font-size: 22px;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      @include mq(sp) {
        @include fz_vw(18);
        margin-bottom: 0;
        padding: 5px 5vw;
      }
      .bge-title-h2, .bge-title-h3 {
        padding: 0;
        background: none;
        font-size: 22px;
        font-weight: 700;
        line-height: 1.5;
        @include mq(sp) {
          @include fz_vw(18);
        }
      }
      a {
        display: block;
        color: #fff;
      }
    }
    ul {
      font-size: 17px;
      @include mq(sp) {
        padding: 5px 5vw;
        background: $sub;
      }
      li {
        @include mq(sp) {
          border-bottom: 1px solid $main;
        }
        &::before {
          content: "・";
          @include mq(sp) {
            display: none;
          }
        }
        &:last-child {
          @include mq(sp) {
            border-bottom: 0;
          }
        }
        a {
          color: #fff;
          text-decoration: underline;
          @include mq(sp) {
            display: block;
            padding: 10px 0;
            color: $main;
            text-decoration: none;
          }
        }
      }
    }

    &--01 {
      top: 740px;
      right: 100px;
    }
    &--02 {
      top: 860px;
      right: 400px;
    }
    &--03 {
      top: 330px;
      left: 420px;
    }
    &--04 {
      top: 340px;
      left: 0;
    }
    &--05 {
      top: 900px;
      left: 160px;
      @media screen and (max-width: 1160px) {
        left: 0;
      }
    }
  }
}

.area-parts1 {
  margin: 0 0 20px;
  padding: 20px 0 0;
  border-top: 1px solid $black;
  @include mq(sp) {
    margin: 0 0 12px;
    padding: 12px 0 0;
  }
  &.last {
    border-bottom: 1px solid $black;
  }
  &__name {
    font-size: 24px;
    font-weight: 400;
    @include mq(sp) {
      @include fz_vw(17);
    }
  }
  &__info {
    font-size: 23px;
    font-weight: 300;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
}
.last {
  .area-parts1 {
    margin-bottom: 140px;
    padding-bottom: 20px;
    border-bottom: 1px solid $black;
    @include mq(sp) {
      margin-bottom: 50px;
      padding-bottom: 12px;
    }
  }
}

.area-parts2 {
  @include flex(nowrap, space-between);
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid $black;
  @include mq(sp) {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  &__body {
    width: 760px;
    @include mq(sp) {
      width: auto;
    }
  }
  &__photo {
    width: 360px;
    @include mq(sp) {
      width: auto;
    }
  }

  &__name {
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 400;
    @include mq(sp) {
      @include fz_vw(17);
    }
  }
  &__address {
    font-size: 23px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
  &__link {
    margin: 15px 0;
    text-align: left;
    a {
      display: inline-block;
      padding: 0 20px 4px;
      border-radius: 20px;
      background: $main;
      color: #fff;
      font-size: 15px;
      appearance: none;
      @include mq(sp) {
        @include fz_vw(12);
      }
      &:hover {
        transform: translateY(2px);
      }
    }
    .bgt-btn-container {
      text-align: left;
    }
  }
  &__info {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
  &__description {
    font-size: 16px;
    @include mq(sp) {
      @include fz_vw(13);
      margin-bottom: 20px;
    }
  }
}
.last {
  .area-parts2 {
    margin-bottom: 140px;
    padding-bottom: 0;
    border-bottom: none;
    @include mq(sp) {
      margin-bottom: 50px;
    }
  }
}

.area-parts3 {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid $black;

  &__name {
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 400;
    @include mq(sp) {
      @include fz_vw(17);
    }
  }
  &__address {
    font-size: 23px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
  &__link {
    margin: 15px 0;
    text-align: left;
    a {
      display: inline-block;
      padding: 0 20px 4px;
      border-radius: 20px;
      background: $main;
      color: #fff;
      font-size: 15px;
      appearance: none;
      @include mq(sp) {
        @include fz_vw(12);
      }
      &:hover {
        transform: translateY(2px);
      }
    }
    .bgt-btn-container {
      text-align: left;
    }
  }
  &__info {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
  &__description {
    font-size: 16px;
    @include mq(sp) {
      @include fz_vw(13);
    }
  }
}
.last {
  .area-parts3 {
    margin-bottom: 140px;
    padding-bottom: 0;
    border-bottom: none;
    @include mq(sp) {
      margin-bottom: 50px;
    }
  }
}

.main {
  .bgb-area-parts1, .bgb-area-parts2, .bgb-area-parts3 {
    margin-top: -80px;
    padding-top: 80px;
  }
}


