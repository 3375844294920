/* index */

.contact-index {
  @include box-wrap();
  @include flex(nowrap,space-between);
  &__inner {
    width: 470px;
    padding: 60px 0 0;
    background: $light-gray;
    @include mq(sp) {
      width: auto;
      margin-bottom: 60px;
      padding: 30px 0 0;
    }
  }
  &__title {
    .bge-title-h2 {
      padding: 20px 0;
      background: #fff;
      text-align: center;
      font-size: 25px;
      @include mq(sp) {
        @include fz_vw(17);
      }
    }
  }
  &__box {
    padding: 40px 30px;
    @include mq(sp) {
      padding: 30px 5vw;
    }
  }
  &__button {
    margin-bottom: 40px;
    @include mq(sp) {
      padding: 0 3vw;
      margin-bottom: 20px;
    }
    a {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 0 70px 0 50px;
      background: #fff;
      font-size: 24px;
      line-height: 70px;
      appearance: none;
      @include mq(sp) {
        padding: 0 20px 0 0;
        font-size: 18px;
        line-height: 56px;
      }
      span {
        position: relative;
        display: inline-block;
        color: #fff;
        z-index: 1;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: "";
        display: block;
        width: 100%;
        background: $main;
        transition: .3s;
      }
      &::after {
        position: absolute;
        right: 20px;
        top: 50%;
        content: "";
        display: block;
        width: 40px;
        height: 15px;
        background: url(../img/common/icn_arrow_bold.svg) 0 0 no-repeat;
        background-size: 40px 15px;
        transform: translateY(-50%);
        transition: .3s;
      }
      &:hover {
        span {
          color: $main;
        }
        &::before {
          left: auto;
          right: 0;
          width: 0;
        }
        &::after {
          background-image: url(../img/common/icn_arrow_bold_r.svg);
        }
      }
    }
  }
  &__main {
    padding: 30px;
    background: #fff;
    @include mq(sp) {
      padding: 15px;
    }
    &__title {
      margin-bottom: 20px;
      @include mq(sp) {
        margin-bottom: 10px;
      }
      .bge-title-h3 {
        padding: 15px 0;
        border-top: 1px solid $main;
        border-bottom: 1px solid $main;
        color: $main;
        font-size: 22px;
        font-weight: 400;
        text-align: center;
        @include mq(sp) {
          @include fz_vw(16);
          padding: 8px 0;
        }
      }
    }
    &__number {
      margin-bottom: 10px;
      font-size: 36px;
      font-weight: 500;
      line-height: 1.3;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(24);
        margin-bottom: 5px;
      }
      span {
        font-size: 30px;
        @include mq(sp) {
          @include fz_vw(20);
        }
      }
    }
    &__name {
      font-size: 20px;
      font-weight: 300;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(14);
      }
    }
  }
}

/* form */

.form-info {
  @include box-wrap();
  &__title {
    color: $main;
    font-size: 34px;
    font-weight: 500;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(19);
    }
  }
  &__message {
    margin: 60px 0;
    padding: 20px 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    font-size: 21px;
    font-weight: 300;
    @include mq(sp) {
      @include fz_vw(13);
      margin: 30px 0;
      padding: 10px 0;
    }
  }
}

.form-index {
  @include box-wrap();
}

.form-table {
  tr {
    th {
      width: 280px;
      padding: 16px 0 0;
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      vertical-align: top;
      @include mq(sp) {
        @include fz_vw(15);
        display: block;
        width: 100%;
        padding: 0;
      }
    }
    td {
      padding: 5px 0;
      font-size: 20px;
      @include mq(sp) {
        @include fz_vw(15);
        display: block;
        width: 100%;
        padding-top: 0;
        padding-bottom: 30px;
      }
      input[type=text] {
        width: 100%;
      }
      textarea {
        width: 100%;
      }
    }
  }
}


.form-agree {
  margin: 120px 0;
  @include mq(sp) {
    margin: 60px 0;
  }
  &__title {
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $main;
    font-size: 24px;
    font-weight: 500;
    @include mq(sp) {
      @include fz_vw(17);
    }
  }
  &__check {
    font-size: 21px;
    @include mq(sp) {
      @include fz_vw(14);
    }
    &__text {
      margin-bottom: 20px;
      @include mq(sp) {
        margin-bottom: 10px;
      }
    }
    &__list {
      margin-bottom: 120px;
      @include mq(sp) {
        margin-bottom: 60px;
      }
    }
  }

  .form-checkbox {
    text-align: center;
    label {
      font-size: 21px;
      @include mq(sp) {
        @include fz_vw(17);
      }
    }
  }
}

.alert-message {
  margin-bottom: 30px;
  padding: 30px 40px;
  border: 6px solid $sub;
  @include mq(sp) {
    padding: 15px 20px;
  }
}
.error-message {
  color: $black !important;
  font-weight: bold;
}
.form-error {
  border: 2px solid $main !important;
  background: $sub !important;
}

.form-confirm {
  @include box-wrap();
  .form-notice {
    margin: 60px 0;
    padding: 20px 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    font-size: 21px;
    font-weight: 300;
    @include mq(sp) {
      @include fz_vw(13);
      margin: 30px 0;
      padding: 10px 0;
    }
  }
  .form-table {
    margin-bottom: 120px;
    @include mq(sp) {
      margin-bottom: 60px;
    }
    tr {
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
    }
    th, td {
      padding: 20px 0;
      @include mq(sp) {
        padding: 0;
      }
    }
  }
}

.form-end {
  @include box-wrap();
  margin-top: 120px;
  @include mq(sp) {
    margin-top: 60px;
  }
  &__text {
    margin-bottom: 120px;
    @include mq(sp) {
      margin-bottom: 60px;
    }
  }
}