.recruit-index {
  @include box-wrap();
  &__header {
    margin-bottom: 60px;
    padding: 20px 0;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
    font-size: 21px;
    font-weight: 300;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(15);
      margin-bottom: 30px;
    }
  }

  &__message {
    margin-bottom: 80px;
    font-size: 21px;
    font-weight: 300;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(14);
      margin-bottom: 50px;
    }
  }

  &__contact {
    &__message {
      margin-bottom: 30px;
      font-size: 26px;
      font-weight: 400;
      text-align: center;
      @include mq(sp) {
        @include fz_vw(18);
        margin-bottom: 15px;
      }

      a {
        display: inline-block;
        color: $main;

        &::before {
          position: absolute;
          left: 50%;
          bottom: 0;
          content: "";
          display: block;
          width: 0;
          height: 1px;
          background: $main;
          transition: .5s;
        }
        &:hover {
          transform: translateY(1px);
          &::before {
            left: 0;
            width: 100%;
          }
        }
        &[target=_blank] {
          &::after {
            content: "";
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-left: 6px;
            background: url(../img/common/icn_target_r.svg) right top no-repeat;
            background-size: 24px 24px;
            transform: translateY(3px);
            @include mq(sp) {
              width: 16px;
              height: 16px;
              margin-left: 2px;
              background-position: right top;
              background-size: 16px 16px;
              transform: translateY(1px);
            }
          }
        }
      }
    }
  }

  &__box {
    width: 520px;
    margin: 0 auto 80px;
    padding: 30px 0;
    background: $sub;
    text-align: center;
    @include mq(sp) {
      width: auto;
      margin-bottom: 50px;
      padding: 15px 0;
    }
    &__number {
      font-size: 38px;
      font-weight: 500;
      @include mq(sp) {
        @include fz_vw(26);
      }
      span {
        font-size: 30px;
        @include mq(sp) {
          @include fz_vw(20);
        }
      }
    }
    &__name {
      font-size: 20px;
      font-weight: 300;
      @include mq(sp) {
        @include fz_vw(14);
      }
    }
  }

  &__button {
    margin-bottom: 40px;
    @include mq(sp) {
      padding: 0 3vw;
      margin-bottom: 20px;
    }
    a {
      position: relative;
      display: inline-block;
      padding: 0 90px 0 70px;
      border: 3px solid $main;
      background: #fff;
      font-size: 24px;
      line-height: 80px;
      appearance: none;
      @include mq(sp) {
        width: 100%;
        padding: 0 20px 0 0;
        font-size: 18px;
        line-height: 56px;
      }
      span {
        position: relative;
        display: inline-block;
        color: #fff;
        z-index: 1;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: "";
        display: block;
        width: 100%;
        background: $main;
        transition: .3s;
      }
      &::after {
        position: absolute;
        right: 20px;
        top: 50%;
        content: "";
        display: block;
        width: 40px;
        height: 15px;
        background: url(../img/common/icn_arrow_bold.svg) 0 0 no-repeat;
        background-size: 40px 15px;
        transform: translateY(-50%);
        transition: .3s;
      }
      &:hover {
        span {
          @include mq(pc) {
            color: $main;
          }
        }
        &::before {
          @include mq(pc) {
            left: auto;
            right: 0;
            width: 0;
          }
        }
        &::after {
          @include mq(pc) {
            background-image: url(../img/common/icn_arrow_bold_r.svg);
          }
        }
      }
    }
  }
}