.page-title {
  position: relative;
  height: 170px;
  padding-top: 35px;
  background: url(../img/common/bg_pagetitle.png) center no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  overflow: hidden;
  @include mq(sp) {
    height: 99px;
    padding-top: 24px;
    background: url(../img/common/sp/bg_pagetitle.png) center no-repeat;
    background-size: contain;
  }
  &__main {
    font-family: $font-noto;
    font-size: 38px;
    font-weight: 500;
    line-height: 1.5;
    -webkit-text-size-adjust: none;
    @include mq(sp) {
      font-size: 21px;
    }
  }
  &__sub {
    font-family: $font-roboto;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.5;
    -webkit-text-size-adjust: none;
    @include mq(sp) {
      font-size: 15px;
    }
  }
}

.sub-navi {
  background: $black;
  &__list {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    @include mq(sp) {
      flex-wrap: wrap;
    }
    li {
      @include mq(sp) {
        width: 50%;
        border-bottom: 1px solid #fff;
      }
      &:nth-child(odd) {
        @include mq(sp) {
          border-right: 1px solid #fff;
        }
      }
      a {
        display: block;
        padding: 6px 25px 10px;
        color: #fff;
        font-size: 18px;
        @include mq(sp) {
          font-size: 16px;
          text-align: center;
        }
        &:hover {
          background: #fff;
          color: $main;
        }
      }

      &.current {
        a {
          background: #fff;
          color: $black;
          font-weight: 500;
        }
      }
    }
  }
}

.page-navi {
  @include box-wrap--sp();
  margin-bottom: 120px;
  padding-top: 50px;
  border-top: 1px solid $main;
  @include mq(sp) {
    margin-bottom: 40px;
    padding-top: 30px;
  }
  ul {
    @include flex-sp(wrap,space-between);
  }
  li {
    width: 230px;
    @include mq(sp) {
      width: 44vw;
      margin-bottom: 2vw;
    }
    a {
      position: relative;
      display: block;
      height: 140px;
      padding-top: 50px;
      text-align: center;
      overflow: hidden;
      @include mq(sp) {
        @include fz_vw(16);
        height: 87px;
        padding-top: 30px;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        transition: .5s;
      }
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(47,142,222,.2);
        transition: .5s;
      }
      &:hover {
        &::before {
          transform: scale(1.2,1.2);
        }
        &::after {
          opacity: 0;
        }

      }
      span {
        position: relative;
        color: #fff;
        font-size: 25px;
        font-weight: 500;
        text-shadow: 2px 2px 2px rgba(0,0,0,.7);
        z-index: 2;
      }
    }
  }

  &__service {
    li {
      width: 300px;
      @include mq(sp) {
        width: 100%;
        margin-bottom: 20px;
      }
      a {
        height: 190px;
        padding-top: 75px;
        @include mq(sp) {
          @include fz_vw(25);
          height: 180px;
          padding-top: 70px;
        }
        span {
          font-size: 27px;
        }
      }
    }
  }

  &__company {
    li {
      a {
        span {
          @include mq(sp) {
            @include fz_vw(16);
          }
        }
      }
    }
  }

  &__service li:nth-child(1) a::before {
    background-image: url(../img/service/img_service_02.jpg);
  }
  &__service li:nth-child(2) a::before {
    background-image: url(../img/service/img_service_03.jpg);
  }
  &__service li:nth-child(3) a::before {
    background-image: url(../img/service/img_service_01.jpg);
  }

  &__company li:nth-child(1) a::before {
    background-image: url(../img/company/img_company_02.jpg);
  }
  &__company li:nth-child(2) a::before {
    background-image: url(../img/company/img_company_01.jpg);
  }
  &__company li:nth-child(3) a::before {
    background-image: url(../img/company/img_company_03.jpg);
  }
  &__company li:nth-child(4) a::before {
    background-image: url(../img/company/img_company_04.jpg);
  }
}

.bge-contents a:not([href]):hover img {
  opacity: 1;
}
