@keyframes zoom {
  50% {
    transform: scale(1.05);
  }
}
@keyframes horizontal {
    0% { transform:translateX( -3px); }
  100% { transform:translateX(  0px); }
}
@keyframes vertical {
    0% { transform:translateY(-20px); }
  100% { transform:translateY(  0px); }
}
@keyframes circle {
    0% { transform:rotate(0); }
  100% { transform:rotate(360deg); }
}

/* TOPPAGE hero mv scroll animation */
//animation: 1s ease .05s infinite scroll;
@-webkit-keyframes scroll {
  0% {bottom: 20px;opacity: 1;}
  50% {opacity:1;}
  100% {bottom: 0;opacity:0;}
}
@keyframes scroll {
  0% {bottom: 20px;opacity: 1;}
  50% {opacity:1;}
  100% {bottom: 0;opacity:0;}
}

/* pagetop hover animation */
//animation: 1s ease .05s infinite pagetop;
@-webkit-keyframes pagetop {
  0% {top: 50%;opacity: 1;}
  50% {opacity:1;}
  100% {top: 30%;opacity:0;}
}
@keyframes pagetop {
  0% {top: 50%;opacity: 1;}
  50% {opacity:1;}
  100% {top: 30%;opacity:0;}
}

@keyframes slowloop {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
