.hero {
  @include base(1500,0,0);
  position: relative;
  overflow: hidden;
  &_wrap {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(243,250,254,1) 100%);
  }
  &__copy {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0 50px 0 10px;
    background: #fff;
    font-size: 44px;
    line-height: 100px;
    @include mq(sp) {
      padding: 0 10px 0 5px;
      font-size: 21px;
      line-height: 50px;
    }
    &::before {
      position: absolute;
      left: -80px;
      top: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 80px 100px 0;
      border-color: transparent #ffffff transparent transparent;
      @include mq(sp) {
        left: -30px;
        border-width: 0 30px 50px 0;
      }
    }
  }
  &__logo {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3;
    &__inner {
      @include base-wrap();
      padding-top: 30px;
      @include mq(sp) {
        padding-top: 20px;
      }
    }
    img {
      width: 330px;
      height: 384px;
      transform: translateX(-50px);
      @include mq(sp) {
        width: 140px;
        height: 171px;
        transform: translateX(30px);
      }
    }

    &__image01 {
      width: 316px;
      height: 384px;
      margin-left: -50px;
      @include mq(sp) {
        width: 140px;
        height: 171px;
        margin-left: 30px;
      }
      svg {
        width: 315px;
        height: 384px;
        @include mq(sp) {
          width: 140px;
          height: 171px;
        }
      }
      .la {
        stroke-dasharray: 2000;
        stroke-dashoffset: 0;
        fill: transparent;
        animation: logo_anime0 3s ease-in 3s forwards;
      }
    }
    &__image02 {
      margin-top: -190px;
      margin-left: 20px;
      width: 180px;
      height: 27px;
      @include mq(sp) {
        position: absolute;
        top: 100px;
        left: 55px;
        width: 90px;
        height: 13px;
        margin-top: 0;
        margin-left: 0;
      }
      .lt1 {
        paint-order: stroke;
        stroke-dasharray: 2000;
        stroke-dashoffset: 0;
        fill: transparent;
        animation: logo_anime1 2s ease-in 4s forwards;
      }
      svg {
        width: 180px;
        height: 27px;
        overflow: visible;
        @include mq(sp) {
          width: 90px;
          height: 13px;
        }
      }
    }
    &__image03 {
      margin-left: 15px;
      width: 186px;
      height: 14px;
      @include mq(sp) {
        position: absolute;
        top: 114px;
        left: 52px;
        width: 93px;
        height: 7px;
        margin-top: 0;
        margin-left: 0;
      }
      .lt2 {
        paint-order: stroke;
        stroke-dasharray: 2000;
        stroke-dashoffset: 0;
        fill: transparent;
        animation: logo_anime2 2s ease-in 5s forwards;
      }
      svg {
        overflow: visible;
        width: 186px;
        height: 14px;
        @include mq(sp) {
          width: 93px;
          height: 7px;
        }
      }
    }
  }
}

@keyframes logo_anime0 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: .5;
    stroke: $acc;
    fill: transparent
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill: $acc;
  }
}
@keyframes logo_anime1 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: .5;
    stroke: $acc;
    fill: transparent
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 3px;
    stroke: #fff;
    fill: $acc;
  }
}
@keyframes logo_anime2 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: .5;
    stroke: $black;
    fill: transparent
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 3px;
    stroke: #fff;
    fill: $black;
  }
}

.slider {
  .slider-image {
    vertical-align: bottom;
  }
  img {
    vertical-align: bottom;
  }
  .slick-active {
    img {
      /* transform: scale(1.0); */

      //animation-duration: 8s;
      //animation-timing-function: ease-out;
      //animation-name: slickActive1;
    }
  }
}

/* TOPPAGE slick slide first image animation */
@-webkit-keyframes slickActive1 {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}
@keyframes slickActive1 {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
}

.top-news {
  margin-bottom: 60px;
  padding: 100px 0 60px;
  background: $sub;
  @include mq(sp) {
    margin-bottom: 30px;
    padding: 30px 0;
  }
  &__box {
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 10px 60px;
    @include mq(sp) {
      margin-top: 5px;
      margin-bottom: 15px;
      padding: 5px 20px;
    }
  }
  &__feed {
    @include box-wrap--sp();
    @include flex(wrap);
    li {
      width: 32%;
      margin-right: 2%;
      @include mq(sp) {
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      a {
        display: block;
        padding: 14px 0;
        font-size: 17px;
        @include mq(sp) {
          @include fz_vw(15);
          padding: 7px 0;
          line-height: 1.5;
        }
        .image {
          display: block;
          margin-bottom: 20px;
          overflow: hidden;
          img {
            width: 100%;
            height: 220px;
            object-fit: cover;
            transition: .5s;
            transform: scale(1.1);
          }
        }
        .date {
          margin-right: 20px;
          color: $gray;
          font-size: 14px;
          @include mq(sp) {
            @include fz_vw(12);
            margin-right: 10px;
          }
        }
        .category {
          display: inline-block;
          margin-right: 20px;
          padding: 0 20px 2px;
          background: $hard;
          color: #fff;
          font-size: 12px;
          text-align: center;
          transform: translateY(-2px);
          @include mq(sp) {
            margin-right: 0;
            font-size: 10px;
          }
        }
        .title {
          display: block;
          margin-top: 10px;
          line-height: 1.5;
          transition: .5s;
          @include mq(sp) {
            display: block;
            margin-top: 4px;
          }
        }
        &:hover {
          @include mq(pc) {
            transform: translateY(1px);
          }
          .image {
            img {
              transform: scale(1);
            }
          }
          .title {
            @include mq(pc) {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  .button__01 {
    @include box-wrap--sp();
  }
}

.top-service {
  @include base-wrap--sp();
  position: relative;
  margin-bottom: 100px;
  padding-top: 140px;
  @include mq(sp) {
    margin-bottom: 50px;
    padding-top: 50px;
  }
  &__logo {
    position: absolute;
    top: 140px;
    right: 220px;
    @include mq(sp) {
      position: static;
      padding: 20px 0 0;
      text-align: center;
    }
    img {
      width: 196px;
      height: 240px;
      @include mq(sp) {
        width: 36vw;
        height: 160px;
      }
    }
  }
  &__box {
    @include flex();
    position: relative;
    padding-bottom: 30px;
    @include mq(sp) {
      padding-bottom: 0;
    }
    &__image {
      width: 660px;
      @include mq(sp) {
        width: auto;
      }
    }
    &__text {
      align-self: center;
      @include mq(sp) {
        position: static;
        width: 80vw;
        margin: 0 auto;
        transform: translateY(-30px);
      }
      a {
        display: block;
        width: 420px;
        padding: 30px;
        color: #fff;
        background: $main;
        transform: translateX(-30px);
        @include mq(sp) {
          width: 80vw;
          padding: 20px;
          transform: translate(0);
        }
        &:hover {
          @include mq(pc) {
            color: #fff;
            transform: translate(-30px,3px);
          }
          .top-service__box__text__button span::before {
            @include mq(pc) {
              right: 10px;
              width: 40px;
            }
          }
          .top-service__box__text__button span::after {
            @include mq(pc) {
              right: 10px;
            }
          }
        }
      }
      &__title {
        margin-bottom: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid #fff;
        font-size: 35px;
        font-weight: 300;
        line-height: 1;
        @include mq(sp) {
          @include fz_vw(23);
          margin-bottom: 10px;
          padding-bottom: 8px;
        }
      }
      &__copy {
        margin-bottom: 30px;
        font-size: 18px;
        @include mq(sp) {
          @include fz_vw(13);
          margin-bottom: 15px;
        }
      }
      &__button {
        text-align: right;
        span {
          position: relative;
          display: inline-block;
          padding: 4px 60px 5px 30px;
          border: 1px solid #fff;
          font-size: 17px;
          letter-spacing: 3px;
          @include mq(sp) {
            @include fz_vw(13);
          }
          &::before {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "";
            display: block;
            width: 30px;
            height: 1px;
            background: #fff;
            transition: .3s;
          }
          &::after {
            position: absolute;
            top: 50%;
            right: 20px;
            content: "";
            display: block;
            width: 8px;
            height: 8px;
            border-right: 1px solid #fff;
            border-top: 1px solid #fff;
            transform: translateY(-4px) rotate(45deg);
            transition: .3s;
            @include mq(sp) {
              width: 7px;
              transform: translateY(-4px) rotate(45deg);
            }
          }
        }
      }
    }
    &.first {
      padding: 100px 0 30px;
      @include mq(sp) {
        padding: 50px 0 0;
      }
    }
    &.reverse {
      flex-direction: row-reverse;
      .top-service__box__text {
        a {
          transform: translateX(30px);
          @include mq(sp) {
            transform: translate(0);
          }
          &:hover {
            @include mq(pc) {
              transform: translate(30px,3px);
            }
          }
        }
      }
    }
  }

  &__next--01 {
    position: relative;
    left: 36%;
    width: 70px;
    @include mq(sp) {
      width: 35px;
      margin-bottom: 20px;
    }
    svg {
      width: 70px;
      height: 120px;
      @include mq(sp) {
        width: 35px;
        height: 60px;
      }
    }
  }
  &__next--02 {
    position: relative;
    left: 60%;
    width: 70px;
    @include mq(sp) {
      width: 35px;
      margin-bottom: 20px;
    }
    svg {
      width: 70px;
      height: 120px;
      @include mq(sp) {
        width: 35px;
        height: 60px;
      }
    }
  }
}

@keyframes na_anime {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill: $acc;
  }
}

.top-area {
  //background: url(../img/top/img_map_02.png) center -240px no-repeat;
  background: url(../img/top/img_map_03.png) center 200px no-repeat;
  @include mq(sp) {
    background: none;
  }
  &__inner {
    @include base-wrap--sp();
    position: relative;
    height: 1200px;
    @include mq(sp) {
      height: auto;
    }
  }

  &__main {
    @include flex();
    position: absolute;
    top: 130px;
    left: 0;
    @include mq(sp) {
      position: static;
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
    &__title {
      padding: 25px 30px;
      background: $main;
      color: #fff;
      font-size: 22px;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      @include mq(sp) {
        @include fz_vw(18);
        padding: 5px 5vw;
      }
    }
    &__list {
      @include flex(wrap, flex-start);
      width: 450px;
      padding: 30px 0 30px 50px;
      background: $sub;
      font-size: 17px;
      @include mq(sp) {
        width: auto;
        padding: 5px 5vw;
      }
      li {
        width: 45%;
        margin-right: 5%;
        @include mq(sp) {
          width: auto;
          margin-right: 0;
          border-bottom: 1px solid $black;
        }
        &::before {
          content: "・";
          @include mq(sp) {
            display: none;
          }
        }
        &:last-child {
          @include mq(sp) {
            border-bottom: 0;
          }
        }
        a {
          text-decoration: underline;
          @include mq(sp) {
            display: block;
            padding: 10px 0;
            text-decoration: none;
          }
        }
      }
    }
  }

  &__part {
    position: absolute;
    padding: 20px 40px 30px 30px;
    background: $main;
    color: #fff;
    @include mq(sp) {
      position: static;
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 0;
      text-align: center;
    }
    &__title {
      margin-bottom: 6px;
      font-size: 22px;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      @include mq(sp) {
        @include fz_vw(18);
        margin-bottom: 0;
        padding: 5px 5vw;
      }
    }
    &__list {
      font-size: 17px;
      @include mq(sp) {
        padding: 5px 5vw;
        background: $sub;
      }
      li {
        @include mq(sp) {
          border-bottom: 1px solid $main;
        }
        &::before {
          content: "・";
          @include mq(sp) {
            display: none;
          }
        }
        &:last-child {
          @include mq(sp) {
            border-bottom: 0;
          }
        }
        a {
          color: #fff;
          text-decoration: underline;
          @include mq(sp) {
            display: block;
            padding: 10px 0;
            color: $main;
            text-decoration: none;
          }
        }
      }
    }

    &--01 {
      top: 740px;
      right: 100px;
    }
    &--02 {
      top: 860px;
      right: 400px;
    }
    &--03 {
      top: 330px;
      left: 420px;
    }
    &--04 {
      top: 340px;
      left: 0;
    }
    &--05 {
      top: 900px;
      left: 160px;
      @media screen and (max-width: 1160px) {
        left: 0;
      }
    }
  }

  .button__01 {
    position: absolute;
    right: 100px;
    bottom: 70px;
    @include mq(sp) {
      position: static;
      margin: 30px 0 60px;
    }
  }
}

.top-company {
  @include base-wrap--sp();
  padding: 0 0 80px;
  @include mq(sp) {
    padding: 0 0 50px;
  }

  &__info {
    padding-top: 60px;
    @include mq(sp) {
      padding: 30px 0 0;
    }
  }

  &__items {
    @include flex(wrap, space-between);
    padding: 90px 0 0;
    @include mq(sp) {
      padding: 40px 0 0;
    }
  }
  &__item {
    position: relative;
    width: 46.7%;
    margin-bottom: 60px;
    @include mq(sp) {
      width: auto;
      margin-bottom: 30px;
    }
    a {
      position: relative;
      display: block;
      &::after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: "";
        display: block;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        background: $main;
        transition: .3s;
        z-index: -1;
      }
    }
    &__title {
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      width: 220px;
      padding: 20px;
      background: #fff;
      color: $hard;
      line-height: 1;
      transform: translateY(-50%);
      @include mq(sp) {
        width: 140px;
      }
      .ja {
        margin-bottom: 10px;
        font-size: 25px;
        font-weight: 500;
        letter-spacing: 4px;
        @include mq(sp) {
          @include fz_vw(18);
          margin-bottom: 5px;
          letter-spacing: 2px;
        }
      }
      .en {
        font-family: $font-roboto;
        font-size: 17px;
        @include mq(sp) {
          @include fz_vw(12);
        }
      }
    }
    &__image {
      padding: 0 10px 10px 0;
      overflow: hidden;
      transition: .3s;
    }
  }
  a:hover {
    &::after {
    }
    .top-company__item__image {
      @include mq(pc) {
        transform: translate(10px,10px);
      }
      img {
        @include mq(pc) {
          opacity: 1;
        }
      }
    }
  }
}

.top-group {
  @include base-wrap();
  position: relative;
  margin-bottom: 150px;
  background: $main;
  @include mq(sp) {
    margin-bottom: 80px;
  }
  &::before {
    position: absolute;
    top: 0;
    left: -42px;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 308px 42px;
    border-color: transparent transparent $main transparent;
  }
  &::after {
    position: absolute;
    top: 0;
    right: -42px;
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 308px 42px 0 0;
    border-color: $main transparent transparent transparent;
  }

  a {
    @include flex(nowrap, space-between);
  }

  &__main {
    padding: 40px;
    @include mq(sp) {
      display: flex;
      padding: 30px;
    }
    .button {
      color: #fff;
      @include mq(sp) {
        margin-top: 6px;
      }
      span {
        position: relative;
        display: inline-block;
        padding: 6px 60px 7px 30px;
        border: 1px solid #fff;
        font-size: 17px;
        letter-spacing: 3px;
        @include mq(sp) {
          @include fz_vw(13);
        }
        &::before {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "";
          display: block;
          width: 30px;
          height: 1px;
          background: #fff;
          transition: .3s;
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-right: 1px solid #fff;
          border-top: 1px solid #fff;
          transform: translateY(-4px) rotate(45deg);
          transition: .3s;
          @include mq(sp) {
            width: 7px;
            transform: translateY(-4px) rotate(45deg);
          }
        }
      }
    }
  }

  &__title {
    color: #fff;
    line-height: 1;
    @include mq(sp) {
      margin-right: 20px;
    }
    .en {
      margin-bottom: 10px;
      font-family: $font-roboto;
      font-size: 63px;
      font-weight: 300;
      letter-spacing: 8px;
      transition: .3s;
      -webkit-font-smoothing: antialiased;
      @include mq(sp) {
        @include fz_vw(34);
        margin-bottom: 5px;
        letter-spacing: 4px;
      }
    }
    .ja {
      margin-bottom: 80px;
      font-size: 21px;
      font-weight: 300;
      text-indent: 10px;
      @include mq(sp) {
        @include fz_vw(13);
        margin-bottom: 0;
        text-indent: 4px;
      }
    }
  }

  &__image {
    position: relative;
    margin-right: 30px;
    overflow: hidden;
    @include mq(sp) {
      margin-right: 0;
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 308px 0 0 123px;
      border-color: transparent transparent transparent $main;
      @include mq(sp) {
        display: none;
      }
    }
    img {
      transition: 2s !important;
      @include mq(sp) {
      }
    }
  }

  a:hover {
    .button span::before {
      @include mq(pc) {
        right: 10px;
        width: 40px;
      }
    }
    .button span::after {
      @include mq(pc) {
        right: 10px;
      }
    }
    .top-group__image img {
      @include mq(pc) {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }
}

.top-recruit {
  position: relative;
  height: 465px;
  margin-bottom: 120px;
  padding-top: 140px;
  background: url(../img/top/bg_recruit.jpg) center no-repeat;
  background-size: cover;
  @include mq(nav) {
    height: auto;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 80px;
    background-image: url(../img/top/sp/bg_recruit.jpg);
  }
  .button__02 {
    margin-bottom: 40px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }

  &__main {
    position: absolute;
    top: 80px;
    left: 0;
    @include mq(nav) {
      position: static;
    }
  }

  &__title {
    width: 400px;
    margin-bottom: 20px;
    padding: 40px 0;
    background: $main;
    color: #fff;
    line-height: 1;
    text-align: center;
    @include mq(sp) {
      width: 60vw;
      margin-bottom: 10px;
      padding: 20px 0;
    }
    .en {
      margin-bottom: 10px;
      font-family: $font-roboto;
      font-size: 55px;
      font-weight: 300;
      letter-spacing: 8px;
      transition: .3s;
      -webkit-font-smoothing: antialiased;
      @include mq(sp) {
        @include fz_vw(34);
        margin-bottom: 5px;
        letter-spacing: 4px;
      }
    }
    .ja {
      font-size: 21px;
      font-weight: 300;
      @include mq(sp) {
        @include fz_vw(13);
        margin-bottom: 0;
      }
    }
  }

  &__copy {
    margin-left: 50px;
    margin-bottom: 20px;
    color: #fff;
    font-size: 20px;
    @include mq(sp) {
      @include fz_vw(16);
    }
  }
}


























