.side {
  .widget-area {
    .widget {
      margin-bottom: 30px;
      @include mq(sp) {
        margin-bottom: 15px;
      }
      ul {
        padding: 10px 0 0;
        li {
          a {
            position: relative;
            display: block;
            padding: 10px 0 10px 18px;
            border-bottom: 1px dotted $black;
            line-height: 1.2;
            &::before {
              position: absolute;
              top: 10px;
              left: 0;
              content: '・';
            }
            &:hover {
              color: $main;
            }
          }
        }
      }
    }

    .widget-blog-calendar {
      border: 1px solid $gray;
      font-family: roboto;
      text-align: center;
      td,th {
        border-top: 1px solid $light-gray;
        border-right: 1px solid $light-gray;
      }
      td > a {
        display: block;
        background: $sub;
        color: #000;
        text-decoration: underline;
      }
      th {
        border-top: 1px solid $gray;
        border-bottom: 1px solid $gray;
        font-weight: normal;
      }
      th.saturday {
        color: #00f;
      }
      th.sunday {
        color: #f00;
      }
    }
  }
}