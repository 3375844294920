/* 全共通 */
.wrapper {
  position: relative;
  padding-top: 88px;
  @include mq(nav) {
    min-width: auto;
    padding-top: 60px;
  }
  &--top {
    .main {
      overflow: hidden;
    }
  }
  &--default {
    .main {
      @include base-wrap--sp();
      padding: 120px 0;
      @include mq(sp) {
        padding: 50px 0;
      }
    }
  }
  &--free {
    .main {
      @include mq(sp) {
        padding: 50px 0;
      }
      .bge-contents > div:not(:first-child) {
        @include base-wrap--sp();
      }
    }
  }
  &--blog {
    .main {
      @include base-wrap--sp();
      @include flex(nowrap,space-between);
      padding: 120px 0;
      @include mq(sp) {
        padding: 50px 0;
      }
      .contents {
        width: 70%;
        @include mq(sp) {
          width: auto;
        }
      }
      .side {
        width: 28%;
        @include mq(sp) {
          width: auto;
        }
      }
    }
  }
}

/* ロードインアニメーション */
/*.wrapper--default {
  .main {
    opacity: 0;
    transform: translateY(100px);
    transition: 2s ease;
  }
  &.load-done {
    .main {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
.wrapper--free {
  .main {
    opacity: 0;
    transform: translateY(100px);
    transition: 2s ease;
  }
  &.load-done {
    .main {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}*/