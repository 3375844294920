.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 100;
  @include mq(nav) {
  }
  &__inner {
    @include base-wrap();
    @include flex(nowrap,space-between);
  }
  &__logo {
    padding: 15px 0 0;
    @include mq(nav) {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 101;
      width: 100%;
      padding: 10px;
      background: #fff;
    }
    a {
      display: block;
    }
    .image {
      display: block;
      @include mq(nav) {
        display: inline-block;
        margin-left: 5px;
        vertical-align: middle;
      }
      img {
        width: 340px;
        height: 56px;
        transition: .2s;
        @include mq(nav) {
          width: 250px;
          height: 40px;
        }
      }
    }
  }
}

.gnavi {
  @include mq(nav) {
    display: none;
  }
  &__list {
    @include flex();
    & > li {
      & > a {
        position: relative;
        display: block;
        margin: 0 10px;
        padding: 30px 0;
        font-size: 16px;
        font-weight: 700;
        &::before {
          position: absolute;
          bottom: 20px;
          left: 50%;
          content: "";
          display: block;
          width: 0;
          height: 2px;
          background: $acc;
          transition: .2s;
        }
        &:hover {
          color: $black;
          &::before {
            width: 100%;
            transform: translateX(-50%);
          }
        }
      }
      &:hover {
        .gnavi__sub--wrap {
          top: 88px;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  &__title {
    height: 100%;
    color: #fff;
    font-size: 32px;
    a {
      position: relative;
      display: block;
      height: 100%;
      color: #fff;
      -webkit-font-smoothing: antialiased;
      &:hover {
        color: #fff;
        .sub::before {
          width: 110%;
        }
      }
    }
    .en {
      display: block;
      padding-top: 30px;
      font-family: $font-roboto;
      font-size: 34px;
      letter-spacing: 5px;
      line-height: 1.3;
    }
    .ja {
      display: block;
      font-family: $font-noto;
      font-size: 13px;
      font-weight: 500;
    }
    .sub {
      position: absolute;
      bottom: 30px;
      right: 50px;
      display: inline-block;
      font-family: $font-noto;
      font-size: 13px;
      font-weight: 500;
      &::after {
        display: inline-block;
        content: "";
        width: 7px;
        height: 7px;
        border-right: 1px solid #fff;
        border-top: 1px solid #fff;
        transform: translateX(8px) rotate(45deg);
      }
      &::before {
        position: absolute;
        bottom: -3px;
        left: 0;
        content: "";
        display: block;
        width: 0;
        height: 1px;
        background: #fff;
        transition: .2s;
      }
    }
  }
  &__sub {
    @include base-wrap();
    @include flex();
    &--wrap{
      position: absolute;
      left: 0;
      width: 100%;
      background: linear-gradient(90deg, $main 0%, $main 50%, $black 50%, $black 100%);
      position: absolute;
      visibility: hidden;
      top: 88px;
      -webkit-transition: .5s;
      transition: .5s;
      opacity: 0;
      z-index: 90;
    }
    & > dt {
      width: 20%;
      & > a {
        display: block;
      }
    }
    & > dd {
      position: relative;
      width: 20%;
      padding: 30px;
      background: #403D3C;
      &:not(:last-child)::before {
        position: absolute;
        top: 50%;
        right: 0;
        content: "";
        display: block;
        width: 1px;
        height: 75%;
        background: #fff;
        transform: translateY(-50%);
      }
      &.col3 {
        width: 26.6666666%;
        padding: 30px 70px;
      }
      & > a {
        display: block;
        img {
          transition: .5s;
        }
        &:hover {
          img {
            transform: scale(1.1);
          }
          .gnavi__sub__title {
            background-position: 8px 8px;
          }
        }
      }
    }
    &__image {
      overflow: hidden;
    }
    &__title {
      position: relative;
      margin-top: 20px;
      background: url(../img/common/icn_arrow_bold.svg) 0 8px no-repeat;
      background-size: 30px 10px;
      color: #fff;
      font-size: 20px;
      line-height: 1.3;
      text-align: center;
      transition: .2s;
    }
  }
}
.gnavi__sub li a {
  .gnavi__sub__image {
    position: relative;
    overflow: hidden;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.3);
      opacity: 0;
      transition: .2s;
      z-index: 100;
    }
    img {
      transition: .5s;
    }
  }
  &:hover {
    .gnavi__sub__image {
      &::before {
        opacity: 1;
      }
      img {
        transform: scale(1.1,1.1);
      }
    }
  }
}

.snavi {
  margin-top: 20px;
  @include mq(nav) {
    display: none;
  }
  &__list {
    @include flex();
    li {
      padding-right: 20px;
      a {
        position: relative;
        display: block;
        width: 170px;
        padding-left: 50px;
        background: $hard;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: 48px;
        -webkit-font-smoothing: antialiased;
        &::after {
          position: absolute;
          top: 50%;
          left: -15px;
          content: "";
          display: block;
          width: 44px;
          height: 9px;
          background: url(../img/common/icn_arrow_long.svg) 0 0 no-repeat;
          background-size: 44px 9px;
          transform: translateY(-70%);
          transition: .2s;
        }
        &:hover::after {
          left: 0;
        }
      }
    }
  }
}

.header.changed {
  background: rgba(255,255,255,.9);
  .header__logo {
    padding: 5px 0 0;
    .image {
      width: 280px;
      img {
        width: 280px;
        height: 46px;
      }
    }
  }
  .gnavi__list > li > a {
    padding: 15px 0;
  }
  .gnavi__list > li > a::before {
    bottom: 15px;
  }
  .gnavi__list > li:hover .gnavi__sub--wrap {
    top: 58px;
  }
  .snavi {
    margin-top: 8px;
  }
  .snavi__list li:last-child a {
    line-height: 42px;
  }
}

.sp-navi {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(64,61,60,.95);
  z-index: 110;
  &--inner {
    height: 100vh;
    padding-top: 60px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
}
.spnav {
  &__list {
    padding: 5vw;
    & > li {
      border-bottom: 1px solid #fff;
      a, span {
        position: relative;
        display: block;
        padding: 12px 0;
        font-size: 20px;
        text-align: left;
      }
      & > a, & > span {
        display: flex;
        justify-content: space-between;
        color: #fff;
        .en {
          font-family: $font-roboto;
          font-size: 20px;
          letter-spacing: 3px;
        }
        .ja {
          display: inline-block;
          width: 8em;
          font-family: $font-noto;
          font-size: 15px;
          font-weight: 300;
          transform: translateY(4px);
        }
      }
      & > a {
        &::after {
          border-top-width: 2px;
          border-right-width: 2px;
        }
      }
      & > span {
        &::before {
          position: absolute;
          top: 50%;
          right: 10px;
          content: "";
          display: block;
          width: 16px;
          height: 2px;
          background: #fff;
          transform: translateX(calc(50% - 1px));
          transform-origin: center;
          transition: .2s;
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 10px;
          content: "";
          display: block;
          width: 2px;
          height: 0;
          background: #fff;
          transform: translateY(calc(-50% + 1px));
          transform-origin: center;
          transition: .2s;
        }
      }
      .active-title {
        &::after {
          height: 16px;
        }
      }
    }
    &__sub {
      display: none;
      padding-bottom: 20px;
      li {
        a {
          padding: 5px 30px;
          color: #fff;
          font-size: 18px;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  &__privacy {
    text-align: center;
    a {
      color: #fff;
    }
  }

  &__info {
    padding: 30px 0 200px;
    color: #fff;
    text-align: center;
    &__contact {
      margin: 0 5vw 20px;
      a {
        display: block;
        background: $main;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        line-height: 50px;
        text-indent: -40px;
        span {
          padding-left: 50px;
          background: url(../img/common/icn_arrow_bold.svg) left 50% no-repeat;
          background-size: 40px 15px;
        }
      }
    }
    &__tel {
      line-height: 1.2;
      margin: 0 5vw;
      p {
        margin-bottom: 5px;
        a {
          display: block;
          color: #fff;
          font-family: $font-noto;
          font-size: 26px;
          font-weight: 500;
          span {
            padding: 7px 0 10px 34px;
            display: inline-block;
            background: url(../img/common/icn_tel.svg) left center no-repeat;
            background-size: 24px 24px;
          }
        }
      }
    }
    &__address {
      padding-top: 20px;
      font-size: 16px;
      line-height: 1.2;
      span {
        display: inline-block;
        margin-bottom: 8px;
        padding: 2px 8px 3px 10px;
        border: 1px solid $gray;
        font-size: 13px;
        font-weight: 500;
      }
    }
    &__logo {
      padding-top: 20px;
      img {
        width: 240px;
        height: 40px;
      }
    }
    &__name {
      font-size: 20px;
    }
  }
}
