.pagetop {
  position: fixed;
  right: 20px;
  bottom: 70px;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background: $hard;
  text-indent: -1000em;
  cursor: pointer;
  z-index: 1000;
  @include mq(sp) {
    bottom: 70px;
    width: 40px;
    height: 40px;
  }
  &::after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    border-top: 2px solid #fff;
    border-left: 2px solid #fff;
    transform: translate(-48%,-30%) rotate(45deg);
    @include mq(sp) {
      width: 10px;
      height: 10px;
    }
  }
  &:hover {
    &::after {
      animation: 1s ease .05s infinite pagetop;
    }
  }
}

.btm-contact {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  background: rgba(255,255,255,.7);
  @include mq(sp) {
    margin-bottom: 0;
  }
  &__inner {
    @include base-wrap();
    padding: 50px 0;
    text-align: center;
    @include mq(sp) {
      padding: 30px 0;
    }
  }
  .title-btm__01 {
    color: $hard;
    .en {
      display: block;
      font-family: $font-roboto;
      font-size: 40px;
      letter-spacing: 3px;
      line-height: 1.2;
      @include mq(sp) {
        @include fz_vw(33);
      }
    }
    .ja {
      display: block;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;
      @include mq(sp) {
        @include fz_vw(13);
      }
    }
  }
  &__message {
    margin: 30px 0;
    color: $black;
    font-size: 18px;
    @include mq(sp) {
      @include fz_vw(13);
      margin: 20px 0;
    }
  }
  &__tel {
    position: relative;
    margin-bottom: 30px;
    font-size: 46px;
    line-height: 1;
    @include mq(sp_tab) {
      display: inline-block;
      margin-bottom: 20px;
    }
    @include mq(sp) {
      @include fz_vw(32);
      margin-bottom: 20px;
    }
    span {
      padding-left: 40px;
      background: url(../img/common/icn_tel_r.svg) 0 center no-repeat;
      background-size: 36px 36px;
      color: $hard;
      font-weight: 500;
      transition: .2s;
      @include mq(sp) {
        padding-left: 32px;
        background-size: 28px 28px;
        background-position: 0 calc(50% + 2px);
      }
    }
    a {
      &:hover {
        span {
          @include mq(pc) {
            background-position: 0 calc(50% + 2px);
          }
        }
      }
    }
  }
  &__button {
    a {
      position: relative;
      display: inline-block;
      padding: 0 70px 0 50px;
      background: #fff;
      font-size: 21px;
      line-height: 70px;
      @include mq(sp) {
        @include fz_vw(16);
        line-height: 56px;
      }
      span {
        position: relative;
        display: inline-block;
        color: #fff;
        z-index: 1;
      }
      &::before {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        content: "";
        display: block;
        width: 100%;
        background: $hard;
        transition: .3s;
      }
      &::after {
        position: absolute;
        right: 20px;
        top: 50%;
        content: "";
        display: block;
        width: 40px;
        height: 15px;
        background: url(../img/common/icn_arrow_bold.svg) 0 0 no-repeat;
        background-size: 40px 15px;
        transform: translateY(-50%);
        transition: .3s;
      }
      &:hover {
        span {
          @include mq(pc) {
            color: $hard;
          }
        }
        &::before {
          @include mq(pc) {
            left: auto;
            right: 0;
            width: 0;
          }
        }
        &::after {
          @include mq(pc) {
            background-image: url(../img/common/icn_arrow_bold_r.svg);
          }
        }
      }
    }
  }
}


.footer {
  padding: 70px 0 0;
  background: url(../img/common/bg_footer.jpg) 0 0 no-repeat;
  background-size: cover;
  color: #fff;
  @include mq(sp) {
    padding-bottom: 0;
  }
  &__wrap {
    padding-top: 40px;
    background: rgba(2,0,0,.75);
  }
  &__inner {
    @include base-wrap();
    @include flex();
  }
  a {
    color: #fff;
    &:hover {
      @include mq(pc) {
        color: $main;
      }
    }
  }
  &__info {
    width: 30%;
    @include mq(sp) {
      width: 100%;
    }
    &__logo {
      display: inline-block;
      vertical-align: middle;
      @include mq(sp) {
        display: block;
        text-align: center;
      }
      img {
        width: 240px;
        height: 40px;
      }
    }
    &__address {
      padding-top: 10px;
      padding-left: 20px;
      font-size: 12px;
      @include mq(sp) {
        @include fz_vw(11);
        margin-bottom: 10px;
        padding-left: 24%;
      }
    }
  }
}

.fnavi {
  @include flex();
  width: 70%;
  margin-bottom: 50px;
  @include mq(sp) {
    display: none;
  }
  a {
    position: relative;
    display: inline-block;
    font-size: 17px;
  }
  &__list {
    margin-right: 5%;
    line-height: 2;
    &:nth-child(1) {
      width: 22%;
    }
    &:nth-child(2) {
      width: 23%;
    }
    &:nth-child(3) {
      width: 23%;
    }
    &:nth-child(4) {
      width: 32%;
      margin-right: 0;
    }
    & > li {
      margin-bottom: 10px;
    }
    &__sub {
      li {
        font-size: 15px;
        a {
          &::before {
            content: "・ ";
          }
        }
      }
    }
  }
}

.copyright {
  padding-bottom: 20px;
  text-align: center;
  font-size: 12px;
  @include mq(sp) {
    padding: 15px 0;
    font-size: 11px;
  }
  a {
    color: #fff;
    &:hover {
      @include mq(pc) {
        color: #fff;
      }
    }
  }
}