html {
  width: 100%;
  height: 100%;
  font-size: 100%;
}
body {
  width: 100%;
  height: 100%;
	margin: 0;
	padding: 0;
  background: #fff;
  font-family: $font-noto;
  font-size: $base-fs;
	font-style: normal;
	font-weight: 300;
	color: $black;
	-webkit-text-size-adjust: none;
	/* -webkit-font-smoothing: antialiased; */
  letter-spacing: 1px;
  line-height: $base-lh;
  word-break: normal;
  line-break: strict;
  overflow-x: hidden;
  @include mq(sp) {
    //@include fz_vw($reset-fs);
    font-size: $sp-fs;
  }
}
a {
	color: $black;
  text-decoration: none;
  transition: .2s;
  &:hover {
    color: $gray;
    transition: .2s;
  }
  &:visited {
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.main {
  img {
    transition: .2s;
  }
  a:hover img {
    @include mq(pc) {
      opacity: 0.8;
    }
  }
}
