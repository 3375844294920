@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
/* font-weight: 600 */
@keyframes zoom {
  50% {
    transform: scale(1.05);
  }
}

@keyframes horizontal {
  0% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes vertical {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* TOPPAGE hero mv scroll animation */
@-webkit-keyframes scroll {
  0% {
    bottom: 20px;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes scroll {
  0% {
    bottom: 20px;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    bottom: 0;
    opacity: 0;
  }
}

/* pagetop hover animation */
@-webkit-keyframes pagetop {
  0% {
    top: 50%;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 30%;
    opacity: 0;
  }
}

@keyframes pagetop {
  0% {
    top: 50%;
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 30%;
    opacity: 0;
  }
}

@keyframes slowloop {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
}

main, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
}

ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

a {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #403d3c;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  width: 100%;
  height: 100%;
  font-size: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  color: #403D3C;
  -webkit-text-size-adjust: none;
  /* -webkit-font-smoothing: antialiased; */
  letter-spacing: 1px;
  line-height: 1.8;
  word-break: normal;
  line-break: strict;
  overflow-x: hidden;
}

@media screen and (max-width: 760px) {
  body {
    font-size: 13px;
  }
}

a {
  color: #403D3C;
  text-decoration: none;
  transition: .2s;
}

a:hover {
  color: #686C67;
  transition: .2s;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.main img {
  transition: .2s;
}

@media screen and (min-width: 761px) {
  .main a:hover img {
    opacity: 0.8;
  }
}

/* @media class
-------------------------------- */
.sp {
  display: none;
}

@media screen and (max-width: 760px) {
  .sp {
    display: block;
  }
}

.tab {
  display: none;
}

.pc {
  display: none;
}

@media screen and (min-width: 761px) {
  .pc {
    display: block;
  }
}

.spi {
  display: none;
}

@media screen and (max-width: 760px) {
  .spi {
    display: inline;
  }
}

.tabi {
  display: none;
}

.pci {
  display: none;
}

@media screen and (min-width: 761px) {
  .pci {
    display: inline;
  }
}

/* @group float
-------------------------------- */
.right {
  float: right;
}

.left {
  float: left;
}

.img-left {
  float: left;
  margin: 0 20px 10px 0;
}

.img-right {
  float: right;
  margin: 0 0 10px 20px;
}

/* @status font color
-------------------------------- */
.error-message {
  color: #f40000;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./images/ajax-loader.gif") center center no-repeat;
}

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  z-index: 1000;
  display: block !important;
  width: 54px;
  height: 58px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  overflow: visible;
}

@media screen and (max-width: 760px) {
  .slick-prev,
  .slick-next {
    width: 25px;
    height: 25px;
  }
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
}

.slick-prev {
  left: 12%;
  background: url(../img/top/slide_prev.png) 0 0 no-repeat;
  background-size: 54px auto;
}

@media screen and (max-width: 760px) {
  .slick-prev {
    left: 10px;
    background-size: 25px auto;
  }
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-next {
  right: 12%;
  background: url(../img/top/slide_next.png) 0 0 no-repeat;
  background-size: 54px auto;
}

@media screen and (max-width: 760px) {
  .slick-next {
    right: 10px;
    background-size: 25px auto;
  }
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-gallery .slick-prev {
  left: 6px;
  background: none;
}

.slick-gallery .slick-prev::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-top: 4px solid #6bc8ee;
  border-right: 4px solid #6bc8ee;
  transform: rotate(225deg);
}

.slick-gallery .slick-next {
  right: -26px;
  background: none;
}

.slick-gallery .slick-next::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-top: 4px solid #6bc8ee;
  border-right: 4px solid #6bc8ee;
  transform: rotate(45deg);
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 40px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.top-spirits .slick-dots {
  bottom: -55px;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 0 10px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #6bc8ee;
  content: '';
  text-align: center;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  background: #6bc8ee;
  color: black;
}

@media screen and (max-width: 749px) {
  .slick-dots li,
  .slick-dots li button,
  .slick-dots li button:before {
    width: 9px;
    height: 9px;
    margin: 0 5px;
    border-radius: 50%;
  }
}

.hero-fadeIn-01 {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top left;
  transition: .5s ease 1s;
}

.hero-fadeIn-01.show {
  opacity: 1;
  transform: scaleY(1);
}

.hero-fadeIn-02 {
  opacity: 0;
  transform: translateY(20px);
  transition: 2s ease 3s;
}

.hero-fadeIn-02.show {
  opacity: 1;
  transform: translateY(0px);
}

.title-fadeLeftIn a {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease;
}

.title-fadeLeftIn a .en {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease .5s;
}

.title-fadeLeftIn a .ja {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease .7s;
}

.title-fadeLeftIn.show a, .title-fadeLeftIn.show .en, .title-fadeLeftIn.show .ja {
  opacity: 1;
  transform: translateX(0px);
}

.title-fadeLeftIn-nl .en {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease;
}

.title-fadeLeftIn-nl .ja {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease .2s;
}

.title-fadeLeftIn-nl.show .en, .title-fadeLeftIn-nl.show .ja {
  opacity: 1;
  transform: translateX(0px);
}

.title-fadeIn-nl .en {
  opacity: 0;
  transform: translateY(20px);
  transition: .5s ease;
}

.title-fadeIn-nl .ja {
  opacity: 0;
  transform: translateY(20px);
  transition: .5s ease .2s;
}

.title-fadeIn-nl.show .en, .title-fadeIn-nl.show .ja {
  opacity: 1;
  transform: translateX(0px);
}

.scroll-fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: 1s ease;
}

.scroll-fadeIn.show {
  opacity: 1;
  transform: translateY(0px);
}

.scroll-fadeTopIn {
  opacity: 0;
  transform: translateY(-50px);
  transition: 1s ease;
}

.scroll-fadeTopIn.show {
  opacity: 1;
  transform: translateY(0px);
}

.scroll-fadeLeftIn {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease;
}

.scroll-fadeLeftIn.show {
  opacity: 1;
  transform: translateX(0px);
}

@media screen and (max-width: 760px) {
  .scroll-fadeLeftIn.show.top-service__box__text {
    transform: translateY(-30px);
  }
}

.scroll-fadeRightIn {
  opacity: 0;
  transform: translateX(400px);
  transition: .5s ease;
}

.scroll-fadeRightIn.show {
  opacity: 1;
  transform: translateX(0px);
}

@media screen and (max-width: 760px) {
  .scroll-fadeRightIn.show.top-service__box__text {
    transform: translateY(-30px);
  }
}

.scroll-svg {
  opacity: 0;
}

.scroll-svg.show {
  opacity: 1;
}

.scroll-svg.show .na {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  stroke-width: .5;
  stroke: #D00014 !important;
  animation: na_anime 1s ease-in 0s forwards;
}

.scroll-imageIn {
  overflow: hidden;
}

.scroll-imageIn a::after {
  transform: translateX(calc(-100% - 10px));
  transition: .5s ease;
}

.scroll-imageIn a > div {
  transform: translateX(-100%);
  transition: .5s ease .3s;
}

.scroll-imageIn.show a::after, .scroll-imageIn.show a > div {
  transform: translateX(0px);
}

@media screen and (min-width: 761px) {
  .scroll-imageIn.d1 a::after {
    transition-delay: .5s;
  }
}

@media screen and (min-width: 761px) {
  .scroll-imageIn.d1 a > div {
    transition-delay: .8s;
  }
}

.bge-contents .bge-title-h2 {
  padding: 10px 20px;
  background: #F3F2EE;
  font-size: 23px;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  .bge-contents .bge-title-h2 {
    font-size: 15px;
  }
}

.bge-contents .bge-title-h3 {
  padding-bottom: 8px;
  border-bottom: 1px solid #686C67;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  .bge-contents .bge-title-h3 {
    font-size: 14px;
  }
}

.title-page__01 .bge-title-h2, .title-page__01 .bge-title-h3 {
  background: none;
  border-bottom: 4px solid #6bc8ee;
  font-size: 32px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .title-page__01 .bge-title-h2, .title-page__01 .bge-title-h3 {
    border-bottom: 2px solid #6bc8ee;
    font-size: 18px;
  }
}

.title-page__02 .bge-title-h2, .title-page__02 .bge-title-h3 {
  background: none;
  color: #6bc8ee;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-page__02 .bge-title-h2, .title-page__02 .bge-title-h3 {
    font-size: 19px;
    font-size: 5.06667vw;
  }
}

.title-page__03 .bge-title-h2, .title-page__03 .bge-title-h3 {
  margin-bottom: 50px;
  padding: 4px 0;
  background: #403D3C;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-page__03 .bge-title-h2, .title-page__03 .bge-title-h3 {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 25px;
  }
}

.title-page__04 .bge-title-h2, .title-page__04 .bge-title-h3 {
  margin-bottom: 50px;
  padding: 4px 0;
  background: #6bc8ee;
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-page__04 .bge-title-h2, .title-page__04 .bge-title-h3 {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 25px;
  }
}

.title-page__05 .bge-title-h2, .title-page__05 .bge-title-h3 {
  margin-bottom: 50px;
  padding: 0;
  background: none;
  color: #403D3C;
  font-size: 40px;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-page__05 .bge-title-h2, .title-page__05 .bge-title-h3 {
    font-size: 23px;
    font-size: 6.13333vw;
    margin-bottom: 25px;
  }
}

.title-page__06 {
  margin: 160px 0 90px;
}

@media screen and (max-width: 760px) {
  .title-page__06 {
    margin: 100px 0 50px;
  }
}

.title-page__06 .bgt-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  background: #6bc8ee;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  .title-page__06 .bgt-container {
    width: 120px;
    height: 120px;
  }
}

.title-page__06 .bgt-container::after {
  position: absolute;
  right: 40px;
  bottom: 40px;
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(90deg);
}

@media screen and (max-width: 760px) {
  .title-page__06 .bgt-container::after {
    right: 20px;
    bottom: 20px;
    width: 14px;
    height: 14px;
  }
}

.title-page__06 .bge-title-h2, .title-page__06 .bge-title-h3 {
  display: block;
  margin-left: 12px;
  padding: 30px 0 0;
  border: 0;
  color: #fff;
  font-size: 33px;
  font-weight: 300;
  transform: rotate(-45deg);
}

@media screen and (max-width: 760px) {
  .title-page__06 .bge-title-h2, .title-page__06 .bge-title-h3 {
    font-size: 20px;
    font-size: 5.33333vw;
    margin-left: 4px;
    padding: 16px 0 0;
  }
}

.title-page__07 {
  margin-bottom: 40px !important;
}

.title-page__07 .bge-title-h2, .title-page__07 .bge-title-h3, .title-page__07 .bge-title-h4 {
  margin-bottom: 40px;
  padding: 10px 0;
  border-top: 1px solid #6bc8ee;
  border-bottom: 1px solid #6bc8ee;
  background: none;
  color: #6bc8ee;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-page__07 .bge-title-h2, .title-page__07 .bge-title-h3, .title-page__07 .bge-title-h4 {
    font-size: 20px;
    font-size: 5.33333vw;
    margin-bottom: 25px;
    padding: 5px 0;
  }
}

[data-bgb].bgb-opt--mb-maxlarge {
  margin: 0 0 100px;
}

@media screen and (max-width: 760px) {
  [data-bgb].bgb-opt--mb-maxlarge {
    margin: 0 0 50px;
  }
}

.bgb-opt--mb-large {
  margin-bottom: 180px !important;
}

@media screen and (max-width: 760px) {
  .bgb-opt--mb-large {
    margin-bottom: 4em !important;
  }
}

[data-bgb="text-image1"] {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

@media screen and (max-width: 760px) {
  [data-bgb="text-image1"] {
    flex-wrap: wrap;
  }
}

[data-bgb="text-image1"]::before, [data-bgb="text-image1"]::after {
  display: none;
}

[data-bgb="text-image1"] .bgt-grid {
  float: none;
  align-self: center;
}

[data-bgb="text-image1"] .bgt-grid--first {
  padding-left: 0;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  [data-bgb="text-image1"] .bgt-grid--first {
    margin-bottom: 10px;
    padding-right: 0;
  }
}

[data-bgb="text-image1"] .bgt-grid--last {
  padding-left: 20px;
  padding-right: 0;
}

@media screen and (max-width: 760px) {
  [data-bgb="text-image1"] .bgt-grid--last {
    margin-bottom: 10px;
    padding-left: 0;
  }
}

[data-bgb="text-image2"] {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;
}

@media screen and (max-width: 760px) {
  [data-bgb="text-image2"] {
    flex-wrap: wrap;
  }
}

[data-bgb="text-image2"]::before, [data-bgb="text-image2"]::after {
  display: none;
}

[data-bgb="text-image2"] .bgt-grid {
  float: none;
  align-self: center;
}

[data-bgb="text-image2"] .bgt-grid--first {
  padding-left: 20px;
  padding-right: 0;
}

@media screen and (max-width: 760px) {
  [data-bgb="text-image2"] .bgt-grid--first {
    margin-bottom: 10px;
    padding-left: 0;
  }
}

[data-bgb="text-image2"] .bgt-grid--last {
  padding-left: 0;
  padding-right: 20px;
}

@media screen and (max-width: 760px) {
  [data-bgb="text-image2"] .bgt-grid--last {
    margin-bottom: 10px;
    padding-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .reverse-sp[data-bgb="text-image1"], .reverse-sp[data-bgb="text-image2"] {
    flex-direction: column-reverse;
  }
}

.bge-contents.bge_contents.bge_content .image-sp-change__pc {
  position: relative;
  padding-bottom: 10px;
}

.bge-contents.bge_contents.bge_content .image-sp-change__pc::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  content: "　パソコン版　";
  background: #ff0;
}

.bge-contents.bge_contents.bge_content .image-sp-change__sp {
  position: relative;
}

.bge-contents.bge_contents.bge_content .image-sp-change__sp::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  content: "　スマホ版　";
  background: #ff0;
}

@media screen and (max-width: 760px) {
  .wrapper .main .contents .bge-contents .image-sp-change__pc {
    display: none;
  }
}

.wrapper .main .contents .bge-contents .image-sp-change__sp {
  display: none;
}

@media screen and (max-width: 760px) {
  .wrapper .main .contents .bge-contents .image-sp-change__sp {
    display: block;
  }
}

/* 全共通 */
.wrapper {
  position: relative;
  padding-top: 88px;
}

@media screen and (max-width: 1090px) {
  .wrapper {
    min-width: auto;
    padding-top: 60px;
  }
}

.wrapper--top .main {
  overflow: hidden;
}

.wrapper--default .main {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 120px 0;
}

@media screen and (max-width: 760px) {
  .wrapper--default .main {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .wrapper--default .main {
    padding: 50px 0;
  }
}

@media screen and (max-width: 760px) {
  .wrapper--free .main {
    padding: 50px 0;
  }
}

.wrapper--free .main .bge-contents > div:not(:first-child) {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  .wrapper--free .main .bge-contents > div:not(:first-child) {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

.wrapper--blog .main {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 120px 0;
}

@media screen and (max-width: 760px) {
  .wrapper--blog .main {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .wrapper--blog .main {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .wrapper--blog .main {
    padding: 50px 0;
  }
}

.wrapper--blog .main .contents {
  width: 70%;
}

@media screen and (max-width: 760px) {
  .wrapper--blog .main .contents {
    width: auto;
  }
}

.wrapper--blog .main .side {
  width: 28%;
}

@media screen and (max-width: 760px) {
  .wrapper--blog .main .side {
    width: auto;
  }
}

/* ロードインアニメーション */
/*.wrapper--default {
  .main {
    opacity: 0;
    transform: translateY(100px);
    transition: 2s ease;
  }
  &.load-done {
    .main {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
.wrapper--free {
  .main {
    opacity: 0;
    transform: translateY(100px);
    transition: 2s ease;
  }
  &.load-done {
    .main {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}*/
.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 100;
}

.header__inner {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .header__inner {
    width: auto;
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .header__inner {
    display: block;
    width: auto;
  }
}

.header__logo {
  padding: 15px 0 0;
}

@media screen and (max-width: 1090px) {
  .header__logo {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
    width: 100%;
    padding: 10px;
    background: #fff;
  }
}

.header__logo a {
  display: block;
}

.header__logo .image {
  display: block;
}

@media screen and (max-width: 1090px) {
  .header__logo .image {
    display: inline-block;
    margin-left: 5px;
    vertical-align: middle;
  }
}

.header__logo .image img {
  width: 340px;
  height: 56px;
  transition: .2s;
}

@media screen and (max-width: 1090px) {
  .header__logo .image img {
    width: 250px;
    height: 40px;
  }
}

@media screen and (max-width: 1090px) {
  .gnavi {
    display: none;
  }
}

.gnavi__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .gnavi__list {
    display: block;
    width: auto;
  }
}

.gnavi__list > li > a {
  position: relative;
  display: block;
  margin: 0 10px;
  padding: 30px 0;
  font-size: 16px;
  font-weight: 700;
}

.gnavi__list > li > a::before {
  position: absolute;
  bottom: 20px;
  left: 50%;
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: #D00014;
  transition: .2s;
}

.gnavi__list > li > a:hover {
  color: #403D3C;
}

.gnavi__list > li > a:hover::before {
  width: 100%;
  transform: translateX(-50%);
}

.gnavi__list > li:hover .gnavi__sub--wrap {
  top: 88px;
  visibility: visible;
  opacity: 1;
}

.gnavi__title {
  height: 100%;
  color: #fff;
  font-size: 32px;
}

.gnavi__title a {
  position: relative;
  display: block;
  height: 100%;
  color: #fff;
  -webkit-font-smoothing: antialiased;
}

.gnavi__title a:hover {
  color: #fff;
}

.gnavi__title a:hover .sub::before {
  width: 110%;
}

.gnavi__title .en {
  display: block;
  padding-top: 30px;
  font-family: "Roboto", sans-serif;
  font-size: 34px;
  letter-spacing: 5px;
  line-height: 1.3;
}

.gnavi__title .ja {
  display: block;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.gnavi__title .sub {
  position: absolute;
  bottom: 30px;
  right: 50px;
  display: inline-block;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.gnavi__title .sub::after {
  display: inline-block;
  content: "";
  width: 7px;
  height: 7px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: translateX(8px) rotate(45deg);
}

.gnavi__title .sub::before {
  position: absolute;
  bottom: -3px;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #fff;
  transition: .2s;
}

.gnavi__sub {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .gnavi__sub {
    width: auto;
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .gnavi__sub {
    display: block;
    width: auto;
  }
}

.gnavi__sub--wrap {
  position: absolute;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #6bc8ee 0%, #6bc8ee 50%, #403D3C 50%, #403D3C 100%);
  position: absolute;
  visibility: hidden;
  top: 88px;
  -webkit-transition: .5s;
  transition: .5s;
  opacity: 0;
  z-index: 90;
}

.gnavi__sub > dt {
  width: 20%;
}

.gnavi__sub > dt > a {
  display: block;
}

.gnavi__sub > dd {
  position: relative;
  width: 20%;
  padding: 30px;
  background: #403D3C;
}

.gnavi__sub > dd:not(:last-child)::before {
  position: absolute;
  top: 50%;
  right: 0;
  content: "";
  display: block;
  width: 1px;
  height: 75%;
  background: #fff;
  transform: translateY(-50%);
}

.gnavi__sub > dd.col3 {
  width: 26.6666666%;
  padding: 30px 70px;
}

.gnavi__sub > dd > a {
  display: block;
}

.gnavi__sub > dd > a img {
  transition: .5s;
}

.gnavi__sub > dd > a:hover img {
  transform: scale(1.1);
}

.gnavi__sub > dd > a:hover .gnavi__sub__title {
  background-position: 8px 8px;
}

.gnavi__sub__image {
  overflow: hidden;
}

.gnavi__sub__title {
  position: relative;
  margin-top: 20px;
  background: url(../img/common/icn_arrow_bold.svg) 0 8px no-repeat;
  background-size: 30px 10px;
  color: #fff;
  font-size: 20px;
  line-height: 1.3;
  text-align: center;
  transition: .2s;
}

.gnavi__sub li a .gnavi__sub__image {
  position: relative;
  overflow: hidden;
}

.gnavi__sub li a .gnavi__sub__image::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: .2s;
  z-index: 100;
}

.gnavi__sub li a .gnavi__sub__image img {
  transition: .5s;
}

.gnavi__sub li a:hover .gnavi__sub__image::before {
  opacity: 1;
}

.gnavi__sub li a:hover .gnavi__sub__image img {
  transform: scale(1.1, 1.1);
}

.snavi {
  margin-top: 20px;
}

@media screen and (max-width: 1090px) {
  .snavi {
    display: none;
  }
}

.snavi__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .snavi__list {
    display: block;
    width: auto;
  }
}

.snavi__list li {
  padding-right: 20px;
}

.snavi__list li a {
  position: relative;
  display: block;
  width: 170px;
  padding-left: 50px;
  background: #2a3293;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
}

.snavi__list li a::after {
  position: absolute;
  top: 50%;
  left: -15px;
  content: "";
  display: block;
  width: 44px;
  height: 9px;
  background: url(../img/common/icn_arrow_long.svg) 0 0 no-repeat;
  background-size: 44px 9px;
  transform: translateY(-70%);
  transition: .2s;
}

.snavi__list li a:hover::after {
  left: 0;
}

.header.changed {
  background: rgba(255, 255, 255, 0.9);
}

.header.changed .header__logo {
  padding: 5px 0 0;
}

.header.changed .header__logo .image {
  width: 280px;
}

.header.changed .header__logo .image img {
  width: 280px;
  height: 46px;
}

.header.changed .gnavi__list > li > a {
  padding: 15px 0;
}

.header.changed .gnavi__list > li > a::before {
  bottom: 15px;
}

.header.changed .gnavi__list > li:hover .gnavi__sub--wrap {
  top: 58px;
}

.header.changed .snavi {
  margin-top: 8px;
}

.header.changed .snavi__list li:last-child a {
  line-height: 42px;
}

.sp-navi {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(64, 61, 60, 0.95);
  z-index: 110;
}

.sp-navi--inner {
  height: 100vh;
  padding-top: 60px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.spnav__list {
  padding: 5vw;
}

.spnav__list > li {
  border-bottom: 1px solid #fff;
}

.spnav__list > li a, .spnav__list > li span {
  position: relative;
  display: block;
  padding: 12px 0;
  font-size: 20px;
  text-align: left;
}

.spnav__list > li > a, .spnav__list > li > span {
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.spnav__list > li > a .en, .spnav__list > li > span .en {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  letter-spacing: 3px;
}

.spnav__list > li > a .ja, .spnav__list > li > span .ja {
  display: inline-block;
  width: 8em;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 15px;
  font-weight: 300;
  transform: translateY(4px);
}

.spnav__list > li > a::after {
  border-top-width: 2px;
  border-right-width: 2px;
}

.spnav__list > li > span::before {
  position: absolute;
  top: 50%;
  right: 10px;
  content: "";
  display: block;
  width: 16px;
  height: 2px;
  background: #fff;
  transform: translateX(calc(50% - 1px));
  transform-origin: center;
  transition: .2s;
}

.spnav__list > li > span::after {
  position: absolute;
  top: 50%;
  right: 10px;
  content: "";
  display: block;
  width: 2px;
  height: 0;
  background: #fff;
  transform: translateY(calc(-50% + 1px));
  transform-origin: center;
  transition: .2s;
}

.spnav__list > li .active-title::after {
  height: 16px;
}

.spnav__list__sub {
  display: none;
  padding-bottom: 20px;
}

.spnav__list__sub li a {
  padding: 5px 30px;
  color: #fff;
  font-size: 18px;
}

.spnav__list__sub li:last-child {
  border-bottom: none;
}

.spnav__privacy {
  text-align: center;
}

.spnav__privacy a {
  color: #fff;
}

.spnav__info {
  padding: 30px 0 200px;
  color: #fff;
  text-align: center;
}

.spnav__info__contact {
  margin: 0 5vw 20px;
}

.spnav__info__contact a {
  display: block;
  background: #6bc8ee;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 50px;
  text-indent: -40px;
}

.spnav__info__contact a span {
  padding-left: 50px;
  background: url(../img/common/icn_arrow_bold.svg) left 50% no-repeat;
  background-size: 40px 15px;
}

.spnav__info__tel {
  line-height: 1.2;
  margin: 0 5vw;
}

.spnav__info__tel p {
  margin-bottom: 5px;
}

.spnav__info__tel p a {
  display: block;
  color: #fff;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 26px;
  font-weight: 500;
}

.spnav__info__tel p a span {
  padding: 7px 0 10px 34px;
  display: inline-block;
  background: url(../img/common/icn_tel.svg) left center no-repeat;
  background-size: 24px 24px;
}

.spnav__info__address {
  padding-top: 20px;
  font-size: 16px;
  line-height: 1.2;
}

.spnav__info__address span {
  display: inline-block;
  margin-bottom: 8px;
  padding: 2px 8px 3px 10px;
  border: 1px solid #686C67;
  font-size: 13px;
  font-weight: 500;
}

.spnav__info__logo {
  padding-top: 20px;
}

.spnav__info__logo img {
  width: 240px;
  height: 40px;
}

.spnav__info__name {
  font-size: 20px;
}

.pagetop {
  position: fixed;
  right: 20px;
  bottom: 70px;
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background: #2a3293;
  text-indent: -1000em;
  cursor: pointer;
  z-index: 1000;
}

@media screen and (max-width: 760px) {
  .pagetop {
    bottom: 70px;
    width: 40px;
    height: 40px;
  }
}

.pagetop::after {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  display: block;
  width: 26px;
  height: 26px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: translate(-48%, -30%) rotate(45deg);
}

@media screen and (max-width: 760px) {
  .pagetop::after {
    width: 10px;
    height: 10px;
  }
}

.pagetop:hover::after {
  animation: 1s ease .05s infinite pagetop;
}

.btm-contact {
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
  background: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 760px) {
  .btm-contact {
    margin-bottom: 0;
  }
}

.btm-contact__inner {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 0;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .btm-contact__inner {
    width: auto;
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .btm-contact__inner {
    padding: 30px 0;
  }
}

.btm-contact .title-btm__01 {
  color: #2a3293;
}

.btm-contact .title-btm__01 .en {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  letter-spacing: 3px;
  line-height: 1.2;
}

@media screen and (max-width: 760px) {
  .btm-contact .title-btm__01 .en {
    font-size: 33px;
    font-size: 8.8vw;
  }
}

.btm-contact .title-btm__01 .ja {
  display: block;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.2;
}

@media screen and (max-width: 760px) {
  .btm-contact .title-btm__01 .ja {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.btm-contact__message {
  margin: 30px 0;
  color: #403D3C;
  font-size: 18px;
}

@media screen and (max-width: 760px) {
  .btm-contact__message {
    font-size: 13px;
    font-size: 3.46667vw;
    margin: 20px 0;
  }
}

.btm-contact__tel {
  position: relative;
  margin-bottom: 30px;
  font-size: 46px;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .btm-contact__tel {
    font-size: 32px;
    font-size: 8.53333vw;
    margin-bottom: 20px;
  }
}

.btm-contact__tel span {
  padding-left: 40px;
  background: url(../img/common/icn_tel_r.svg) 0 center no-repeat;
  background-size: 36px 36px;
  color: #2a3293;
  font-weight: 500;
  transition: .2s;
}

@media screen and (max-width: 760px) {
  .btm-contact__tel span {
    padding-left: 32px;
    background-size: 28px 28px;
    background-position: 0 calc(50% + 2px);
  }
}

@media screen and (min-width: 761px) {
  .btm-contact__tel a:hover span {
    background-position: 0 calc(50% + 2px);
  }
}

.btm-contact__button a {
  position: relative;
  display: inline-block;
  padding: 0 70px 0 50px;
  background: #fff;
  font-size: 21px;
  line-height: 70px;
}

@media screen and (max-width: 760px) {
  .btm-contact__button a {
    font-size: 16px;
    font-size: 4.26667vw;
    line-height: 56px;
  }
}

.btm-contact__button a span {
  position: relative;
  display: inline-block;
  color: #fff;
  z-index: 1;
}

.btm-contact__button a::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 100%;
  background: #2a3293;
  transition: .3s;
}

.btm-contact__button a::after {
  position: absolute;
  right: 20px;
  top: 50%;
  content: "";
  display: block;
  width: 40px;
  height: 15px;
  background: url(../img/common/icn_arrow_bold.svg) 0 0 no-repeat;
  background-size: 40px 15px;
  transform: translateY(-50%);
  transition: .3s;
}

@media screen and (min-width: 761px) {
  .btm-contact__button a:hover span {
    color: #2a3293;
  }
}

@media screen and (min-width: 761px) {
  .btm-contact__button a:hover::before {
    left: auto;
    right: 0;
    width: 0;
  }
}

@media screen and (min-width: 761px) {
  .btm-contact__button a:hover::after {
    background-image: url(../img/common/icn_arrow_bold_r.svg);
  }
}

.footer {
  padding: 70px 0 0;
  background: url(../img/common/bg_footer.jpg) 0 0 no-repeat;
  background-size: cover;
  color: #fff;
}

@media screen and (max-width: 760px) {
  .footer {
    padding-bottom: 0;
  }
}

.footer__wrap {
  padding-top: 40px;
  background: rgba(2, 0, 0, 0.75);
}

.footer__inner {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .footer__inner {
    width: auto;
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .footer__inner {
    display: block;
    width: auto;
  }
}

.footer a {
  color: #fff;
}

@media screen and (min-width: 761px) {
  .footer a:hover {
    color: #6bc8ee;
  }
}

.footer__info {
  width: 30%;
}

@media screen and (max-width: 760px) {
  .footer__info {
    width: 100%;
  }
}

.footer__info__logo {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 760px) {
  .footer__info__logo {
    display: block;
    text-align: center;
  }
}

.footer__info__logo img {
  width: 240px;
  height: 40px;
}

.footer__info__address {
  padding-top: 10px;
  padding-left: 20px;
  font-size: 12px;
}

@media screen and (max-width: 760px) {
  .footer__info__address {
    font-size: 11px;
    font-size: 2.93333vw;
    margin-bottom: 10px;
    padding-left: 24%;
  }
}

.fnavi {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  width: 70%;
  margin-bottom: 50px;
}

@media screen and (max-width: 760px) {
  .fnavi {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .fnavi {
    display: none;
  }
}

.fnavi a {
  position: relative;
  display: inline-block;
  font-size: 17px;
}

.fnavi__list {
  margin-right: 5%;
  line-height: 2;
}

.fnavi__list:nth-child(1) {
  width: 22%;
}

.fnavi__list:nth-child(2) {
  width: 23%;
}

.fnavi__list:nth-child(3) {
  width: 23%;
}

.fnavi__list:nth-child(4) {
  width: 32%;
  margin-right: 0;
}

.fnavi__list > li {
  margin-bottom: 10px;
}

.fnavi__list__sub li {
  font-size: 15px;
}

.fnavi__list__sub li a::before {
  content: "・ ";
}

.copyright {
  padding-bottom: 20px;
  text-align: center;
  font-size: 12px;
}

@media screen and (max-width: 760px) {
  .copyright {
    padding: 15px 0;
    font-size: 11px;
  }
}

.copyright a {
  color: #fff;
}

@media screen and (min-width: 761px) {
  .copyright a:hover {
    color: #fff;
  }
}

.side .widget-area .widget {
  margin-bottom: 30px;
}

@media screen and (max-width: 760px) {
  .side .widget-area .widget {
    margin-bottom: 15px;
  }
}

.side .widget-area .widget ul {
  padding: 10px 0 0;
}

.side .widget-area .widget ul li a {
  position: relative;
  display: block;
  padding: 10px 0 10px 18px;
  border-bottom: 1px dotted #403D3C;
  line-height: 1.2;
}

.side .widget-area .widget ul li a::before {
  position: absolute;
  top: 10px;
  left: 0;
  content: '・';
}

.side .widget-area .widget ul li a:hover {
  color: #6bc8ee;
}

.side .widget-area .widget-blog-calendar {
  border: 1px solid #686C67;
  font-family: roboto;
  text-align: center;
}

.side .widget-area .widget-blog-calendar td, .side .widget-area .widget-blog-calendar th {
  border-top: 1px solid #F3F2EE;
  border-right: 1px solid #F3F2EE;
}

.side .widget-area .widget-blog-calendar td > a {
  display: block;
  background: #f3fafe;
  color: #000;
  text-decoration: underline;
}

.side .widget-area .widget-blog-calendar th {
  border-top: 1px solid #686C67;
  border-bottom: 1px solid #686C67;
  font-weight: normal;
}

.side .widget-area .widget-blog-calendar th.saturday {
  color: #00f;
}

.side .widget-area .widget-blog-calendar th.sunday {
  color: #f00;
}

.sp-btn {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  z-index: 1900;
  width: 60px;
  height: 60px;
}

@media screen and (max-width: 1090px) {
  .sp-btn {
    display: block;
  }
}

.panel-btn {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  text-decoration: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.panel-btn .close {
  background: transparent;
}

.panel-btn .close::before, .panel-btn .close::after {
  margin-top: -2px;
  left: -6px;
}

.panel-btn .close::before {
  width: 36px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.panel-btn .close::after {
  width: 36px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 27px;
  height: 3px;
  border-radius: 3px;
  background: #2a3293;
  -webkit-transition: .2s;
  transition: .2s;
  transform: translate(calc(-50% + 5px), -50%);
}

.panel-btn-icon::before, .panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 3px;
  border-radius: 3px;
  background: #2a3293;
  -webkit-transition: .3s;
  transition: .3s;
}

.panel-btn-icon::before {
  width: 36px;
  margin-top: -13px;
  transform: translateX(-9px);
}

.panel-btn-icon::after {
  width: 18px;
  margin-top: 9px;
  transform: translateX(9px);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10000;
}

/* ローディングのアニメーション部分のCSS (https://projects.lukehaas.me/css-loaders/) */
/* SQUARE */
.loader-animation,
.loader-animation:before,
.loader-animation:after {
  background: #6bc8ee;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader-animation {
  height: 10px;
  left: 50%;
  margin: -5px 0 0 -5px;
  position: absolute;
  top: 40%;
  width: 10px;
  color: #6bc8ee;
  text-indent: -9999em;
  font-size: 10px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-animation:before,
.loader-animation:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader-animation:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader-animation:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

/* CIRCLE */
.loader-circle {
  position: absolute;
  top: 40%;
  color: #6bc8ee;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.loader-image {
  position: absolute;
  left: 50%;
  top: 40%;
  display: block;
  width: 53px;
  height: 64px;
  background: url(../img/common/logo_arrow.svg) no-repeat;
  background-size: 53px 64px;
  transform: translate(-50%, -50%);
  animation: 1s ease .05s infinite circle;
}

.loader-logo {
  position: relative;
  display: block;
  left: 50%;
  top: 40%;
  width: 150px;
  height: 30px;
  margin-left: -70px;
  margin-top: 70px;
  background: url(../img/common/logo.svg) no-repeat;
  background-size: 150px 20px;
}

.js-scroll-fade {
  position: relative;
  opacity: 0;
  transform: translate(0, 40px);
  transition: all 1s cubic-bezier(0.155, 0.91, 0.695, 0.95);
}

.js-scroll-fade.is-animation {
  opacity: 1;
  transform: translate(0, 0);
}

.title-top__01 {
  font-family: "Roboto", sans-serif;
  font-size: 33px;
  font-weight: 500;
  letter-spacing: 4px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-top__01 {
    font-size: 19px;
    font-size: 5.06667vw;
  }
}

.title-top__01 a {
  color: #403D3C;
}

.title-top__01 a:hover {
  color: #6bc8ee;
}

.title-top__02 {
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .title-top__02 {
    text-align: center;
  }
}

.title-top__02 a {
  position: relative;
  display: inline-block;
  padding-right: 90px;
}

@media screen and (max-width: 760px) {
  .title-top__02 a {
    padding: 0 60px;
  }
}

.title-top__02 a::before {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 60px;
  height: 1px;
  background: #6bc8ee;
  transition: .3s;
  transform: translateY(-8px);
}

@media screen and (max-width: 760px) {
  .title-top__02 a::before {
    width: 30px;
  }
}

.title-top__02 a::after {
  position: absolute;
  top: 50%;
  right: 18px;
  content: "";
  display: block;
  width: 20px;
  height: 1px;
  background: #6bc8ee;
  transform: translateY(-15px) rotate(45deg);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .title-top__02 a::after {
    width: 12px;
    transform: translateY(-12px) rotate(45deg);
  }
}

.title-top__02 a:hover::before {
  right: 10px;
  width: 70px;
}

.title-top__02 a:hover::after {
  right: 8px;
}

.title-top__02 a:hover .en {
  color: #403D3C;
}

.title-top__02 a:hover .ja {
  color: #6bc8ee;
}

.title-top__02 .en {
  margin-bottom: 10px;
  color: #6bc8ee;
  font-family: "Roboto", sans-serif;
  font-size: 63px;
  font-weight: 300;
  letter-spacing: 8px;
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .title-top__02 .en {
    font-size: 34px;
    font-size: 9.06667vw;
    margin-bottom: 5px;
    letter-spacing: 4px;
    text-align: center;
  }
}

.title-top__02 .ja {
  font-size: 21px;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-top__02 .ja {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.title-side__01 {
  padding: 5px 10px;
  background: #f3fafe;
  color: #6bc8ee;
  font-weight: 500;
}

.title-page__01 {
  border-bottom: 4px solid #6bc8ee;
  font-size: 32px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .title-page__01 {
    border-bottom: 2px solid #6bc8ee;
    font-size: 18px;
  }
}

.title-page__02 {
  color: #6bc8ee;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .title-page__02 {
    font-size: 19px;
    font-size: 5.06667vw;
  }
}

.button__01 {
  text-align: right;
}

@media screen and (max-width: 760px) {
  .button__01 {
    text-align: center;
  }
}

.button__01 a {
  position: relative;
  display: inline-block;
  border: 1px solid #2a3293;
  background: #fff;
  font-size: 18px;
  line-height: 50px;
}

@media screen and (max-width: 760px) {
  .button__01 a {
    font-size: 16px;
    font-size: 4.26667vw;
    border: 0;
    background: #6bc8ee;
    line-height: 40px;
  }
}

.button__01 a span {
  position: relative;
  display: block;
  padding: 0 70px 0 50px;
  color: #2a3293;
  z-index: 1;
}

@media screen and (max-width: 760px) {
  .button__01 a span {
    color: #fff;
  }
}

.button__01 a span::before {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background: #2a3293;
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .button__01 a span::before {
    background: #fff;
  }
}

.button__01 a span::after {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background: #2a3293;
  transform: translateY(-4px) rotate(45deg);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .button__01 a span::after {
    width: 7px;
    background: #fff;
    transform: translateY(-3px) rotate(45deg);
  }
}

.button__01 a::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 0;
  background: #f3fafe;
  transition: .3s;
}

@media screen and (min-width: 761px) {
  .button__01 a:hover::before {
    width: 100%;
  }
}

@media screen and (min-width: 761px) {
  .button__01 a:hover span::before {
    right: 10px;
    width: 40px;
  }
}

@media screen and (min-width: 761px) {
  .button__01 a:hover span::after {
    right: 10px;
  }
}

.button__02 {
  text-align: center;
}

@media screen and (max-width: 760px) {
  .button__02 {
    text-align: center;
  }
}

.button__02 a {
  position: relative;
  display: inline-block;
  border: 1px solid #2a3293;
  background: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 92px;
  text-align: left;
}

@media screen and (max-width: 760px) {
  .button__02 a {
    font-size: 18px;
    font-size: 4.8vw;
    line-height: 65px;
  }
}

.button__02 a span {
  position: relative;
  display: block;
  width: 310px;
  padding: 0 70px 0 50px;
  color: #2a3293;
  z-index: 1;
}

@media screen and (max-width: 760px) {
  .button__02 a span {
    width: 60vw;
    padding: 0 50px 0 30px;
  }
}

.button__02 a span::before {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background: #2a3293;
  transition: .3s;
}

.button__02 a span::after {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 10px;
  height: 1px;
  background: #2a3293;
  transform: translateY(-4px) rotate(45deg);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .button__02 a span::after {
    width: 7px;
    transform: translateY(-3px) rotate(45deg);
  }
}

.button__02 a::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 0;
  background: #f3fafe;
  transition: .3s;
}

@media screen and (min-width: 761px) {
  .button__02 a:hover::before {
    width: 100%;
  }
}

@media screen and (min-width: 761px) {
  .button__02 a:hover span::before {
    right: 10px;
    width: 40px;
  }
}

@media screen and (min-width: 761px) {
  .button__02 a:hover span::after {
    right: 10px;
  }
}

.table-form tr {
  border-bottom: 1px solid #d2d2d2;
}

.table-form tr:last-child {
  border-bottom: 0;
}

.table-form th, .table-form td {
  padding: 10px 25px;
}

@media screen and (max-width: 760px) {
  .table-form th, .table-form td {
    display: block;
    width: 100%;
  }
}

.table-form th {
  width: 230px;
  padding-right: 15px;
  font-weight: normal;
  text-align: left;
}

@media screen and (max-width: 760px) {
  .table-form th {
    padding: 20px 0 0;
    padding-bottom: 0;
  }
}

.table-form td {
  padding-left: 0;
}

@media screen and (max-width: 760px) {
  .table-form td {
    padding: 10px 0 20px;
  }
}

.table-scroll {
  overflow: auto;
  white-space: nowrap;
}

.table-scroll::-webkit-scrollbar {
  height: 5px;
}

.table-scroll::-webkit-scrollbar-track {
  background: #F1F1F1;
}

.table-scroll::-webkit-scrollbar-thumb {
  background: #BCBCBC;
}

.pagination {
  margin: 60px 0 100px;
  text-align: center;
}

.pagination span.current,
.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 16px;
  font-size: 20px;
  border-radius: 50%;
}

@media screen and (max-width: 760px) {
  .pagination span.current,
  .pagination span.disabled,
  .pagination span.first a,
  .pagination span.last a,
  .pagination span.prev a,
  .pagination span.next a,
  .pagination span.number a {
    margin: 0 1px;
    padding: 6px 11px;
    font-size: 12px;
  }
}

.pagination span.number a {
  background: #CACACA;
  color: #fff;
  text-decoration: none !important;
}

.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a {
  padding: 12px 12px;
  color: #6bc8ee;
}

.pagination span.prev a,
.pagination span.next a {
  text-decoration: underline;
}

.pagination span.current,
.pagination span.number a:hover {
  background: #6bc8ee;
  color: #fff;
}

.pagination a:active {
  background: #6bc8ee;
}

.pagination span.first a,
.pagination span.last a {
  position: relative;
}

.pagination span.first a::before,
.pagination span.last a::after {
  position: absolute;
  top: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 14px;
  margin-top: -6px;
  background: #000;
}

@media screen and (max-width: 760px) {
  .pagination span.first a::before,
  .pagination span.last a::after {
    height: 11px;
    margin-top: -5px;
  }
}

.pagination span.first a::before {
  left: 13px;
}

@media screen and (max-width: 760px) {
  .pagination span.first a::before {
    left: 12px;
  }
}

.pagination span.last a::after {
  right: 13px;
}

@media screen and (max-width: 760px) {
  .pagination span.last a::after {
    right: 12px;
  }
}

.case .pagination span.disabled,
.case .pagination span.prev a,
.case .pagination span.next a {
  border: 0;
}

.case .pagination span.prev a,
.case .pagination span.next a,
.case .pagination span.number a {
  color: #403D3C;
}

.case .pagination span.prev a:hover,
.case .pagination span.next a:hover {
  background: none;
  color: #403D3C;
  text-decoration: underline !important;
}

.case .pagination span.current,
.case .pagination span a:hover {
  background: #666;
  color: #fff;
}

.case .pagination a:active {
  background: #999;
}

.contents-navi {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 120px;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .contents-navi {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .contents-navi {
    margin-top: 20px;
    margin-bottom: 80px;
  }
}

.contents-navi a {
  display: inline-block;
  width: 226px;
  border: 1px solid #ddd;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
}

@media screen and (max-width: 760px) {
  .contents-navi a {
    font-size: 11px;
    font-size: 2.93333vw;
    width: 45.538%;
    line-height: 31px;
  }
}

@media screen and (min-width: 761px) {
  .contents-navi a:hover {
    background: #fff;
    color: #403D3C;
    font-weight: bold;
    text-decoration: none;
  }
}

.contents-navi a.prev-link, .contents-navi a.next-link {
  background: #ddd;
}

.contents-navi a:hover.prev-link, .contents-navi a:hover.next-link {
  background: #eee;
}

.post-navi {
  text-align: center;
}

.post-navi a {
  display: inline-block;
  padding: 5px 80px;
  border-radius: 30px;
  background: #6bc8ee;
  color: #fff;
  font-size: 21px;
}

@media screen and (max-width: 760px) {
  .post-navi a {
    padding: 5px 40px;
    font-size: 17px;
  }
}

.post-navi a:hover {
  color: #fff;
}

.required {
  display: inline-block;
  min-width: 48px;
  margin-left: 10px;
  background: #6bc8ee;
  color: #fff;
  font-size: 14px;
  text-align: center;
  transform: translateY(-1px);
  white-space: nowrap;
}

@media screen and (max-width: 760px) {
  .required {
    font-size: 11px;
    font-size: 2.93333vw;
    min-width: 40px;
    line-height: 1.6;
  }
}

.normal {
  display: inline-block;
  min-width: 48px;
  margin-left: 10px;
  background: #686C67;
  color: #fff;
  font-size: 14px;
  text-align: center;
  transform: translateY(-1px);
  white-space: nowrap;
}

@media screen and (max-width: 760px) {
  .normal {
    font-size: 11px;
    font-size: 2.93333vw;
    min-width: 40px;
    line-height: 1.6;
  }
}

.submit {
  text-align: center;
}

@media screen and (max-width: 760px) {
  .submit {
    margin-left: 4%;
    margin-right: 4%;
  }
}

.submit button, .submit input[type="submit"], .submit input[type="reset"] {
  position: relative;
  background: #6bc8ee;
  color: #fff;
  font-size: 24px;
  font-weight: normal;
  line-height: 60px;
  cursor: pointer;
}

@media screen and (max-width: 760px) {
  .submit button, .submit input[type="submit"], .submit input[type="reset"] {
    font-size: 19px;
    font-size: 5.06667vw;
    width: 100%;
    line-height: 50px;
  }
}

.submit input[type="submit"] {
  width: 240px;
}

.submit input[type="reset"] {
  width: 240px;
}

#BtnMessageConfirm {
  width: 380px;
}

@media screen and (max-width: 760px) {
  #BtnMessageConfirm {
    width: 100%;
  }
}

#BtnMessageBack {
  background: #BCBCBD;
}

@media screen and (max-width: 760px) {
  #BtnMessageBack {
    margin-bottom: 5px;
  }
}

.checkbox {
  display: inline-block;
}

/* reset */
button,
option,
textarea,
select,
input[type="button"],
input[type="submit"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="select"],
input[type="option"],
input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  outline: 0;
  margin: 0;
  background: #fff;
  box-sizing: border-box;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  button,
  option,
  textarea,
  select,
  input[type="button"],
  input[type="submit"],
  input[type="number"],
  input[type="email"],
  input[type="tel"],
  input[type="text"],
  input[type="select"],
  input[type="option"],
  input[type="checkbox"] {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

/*
select,
input[type="select"] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: 1px solid #ccc;
    outline: 0;
    margin: 5px 0;
    background: #fff;
    box-sizing: border-box;
    font-size: 20px;
    @include mq(sp) {
      @include fz_vw(12);
    }
}*/
/* デフォルトのradio、checkboxは非表示 */
input[type=radio],
input[type=checkbox] {
  display: none;
}

/* for firefox */
select {
  text-indent: 0.01px;
  text-overflow: '';
}

textarea,
input[type="number"],
input[type="text"] {
  padding: 15px;
  border: 1px solid #403D3C;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 760px) {
  textarea,
  input[type="number"],
  input[type="text"] {
    padding: 10px 8px;
  }
}

input.form_s {
  width: 10%;
}

@media screen and (max-width: 760px) {
  input.form_s {
    width: 20%;
  }
}

input.form_m {
  width: 70%;
}

@media screen and (max-width: 760px) {
  input.form_m {
    width: 100%;
  }
}

input.form_l {
  width: 90%;
}

@media screen and (max-width: 760px) {
  input.form_l {
    width: 100%;
  }
}

textarea {
  width: 90%;
  height: 120px;
  /* お好みの高さに */
  padding: 10px 10px;
}

@media screen and (max-width: 760px) {
  textarea {
    width: 100%;
  }
}

/* フォーカス時の色変更 */
textarea:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="text"]:focus {
  border: 2px solid #6bc8ee;
}

/* for ie10 ie11 ie系のプルダウンの矢印を消す ie9は非対応 */
select::-ms-expand {
  display: none;
}

/* ラジオボタン */
input[type=radio].form-radio + label {
  position: relative;
  display: inline-block;
  font-size: 21px;
  cursor: pointer;
  padding: 9px 5px 8px 28px;
  margin-right: 10px;
}

@media screen and (max-width: 760px) {
  input[type=radio].form-radio + label {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

input[type=radio].form-radio + label::before,
input[type=radio].form-radio + label::after {
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

input[type=radio].form-radio + label::before {
  width: 22px;
  height: 22px;
  margin-top: -10px;
  background: #FFF;
  border: 1px solid #ccc;
}

input[type=radio].form-radio + label::after {
  left: 6px;
  width: 10px;
  height: 10px;
  margin-top: -4px;
  background: #f4f4f4;
}

input[type=radio]:checked.form-radio + label::after {
  background: #6bc8ee;
}

/* チェックボックス */
.form-checkbox label {
  position: relative;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  padding: 6px 5px 8px 50px;
}

@media screen and (max-width: 760px) {
  .form-checkbox label {
    font-size: 12px;
    font-size: 3.2vw;
    padding-left: 30px;
  }
}

.form-checkbox label::before,
.form-checkbox label::after {
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.form-checkbox label::before {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  background: #FFF;
  border: 1px solid #6bc8ee;
}

@media screen and (max-width: 760px) {
  .form-checkbox label::before {
    width: 24px;
    height: 24px;
    margin-top: -13px;
  }
}

.form-checkbox input[type=checkbox] + label::after {
  width: 24px;
  height: 24px;
  top: 2px;
}

.form-checkbox input[type=checkbox]:checked + label::after {
  position: absolute;
  left: 4px;
  top: 8px;
  content: '';
  display: block;
  width: 28px;
  height: 18px;
  border-top: 6px solid #6bc8ee;
  border-right: 6px solid #6bc8ee;
  transform: rotate(135deg);
}

@media screen and (max-width: 760px) {
  .form-checkbox input[type=checkbox]:checked + label::after {
    width: 22px;
    height: 15px;
    border-width: 5px;
  }
}

.form-select {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-top: 5px;
  margin-bottom: 5px;
}

@media screen and (max-width: 760px) {
  .form-select {
    line-height: 30px;
  }
}

.form-select:before {
  position: absolute;
  display: block;
  content: '';
  width: 16px;
  height: 16px;
  background: #6bc8ee;
  top: 50%;
  right: 8px;
  margin-top: -8px;
  pointer-events: none;
  z-index: 10;
}

.form-select:after {
  position: absolute;
  display: block;
  content: '';
  width: 0;
  height: 0;
  /* ここで下向き矢印指定　今回はCSSで */
  border-top: 6px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  top: 50%;
  right: 11px;
  margin-top: -3px;
  pointer-events: none;
  /* クリック出来るよう */
  z-index: 11;
}

.form-select select {
  padding: 15px 50px 15px 20px;
  border: 1px solid #403D3C;
  position: relative;
}

@media screen and (max-width: 760px) {
  .form-select select {
    font-size: 16px;
    font-size: 4.26667vw;
    height: auto;
    padding: 10px 50px 10px 8px;
  }
}

.form-select select option:first-child {
  color: #6bc8ee;
}

/* ie9対策 */
.form-select select:not(:target) {
  width: 120% \9;
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */
::-webkit-input-placeholder {
  color: #6bc8ee;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 100;
}

/* Firefox 18- */
:-moz-placeholder {
  color: #6bc8ee;
  opacity: 1;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 100;
}

/* Firefox 19+ */
::-moz-placeholder {
  color: #6bc8ee;
  opacity: 1;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 100;
}

/* IE 10+ */
:-ms-input-placeholder {
  color: #6bc8ee;
  font-family: "Noto Sans JP", sans-serif;
  font-weight: 100;
}

.hero {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  .hero {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.hero_wrap {
  background: white;
  background: linear-gradient(180deg, white 30%, #f3fafe 100%);
}

.hero__copy {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0 50px 0 10px;
  background: #fff;
  font-size: 44px;
  line-height: 100px;
}

@media screen and (max-width: 760px) {
  .hero__copy {
    padding: 0 10px 0 5px;
    font-size: 21px;
    line-height: 50px;
  }
}

.hero__copy::before {
  position: absolute;
  left: -80px;
  top: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 80px 100px 0;
  border-color: transparent #ffffff transparent transparent;
}

@media screen and (max-width: 760px) {
  .hero__copy::before {
    left: -30px;
    border-width: 0 30px 50px 0;
  }
}

.hero__logo {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 3;
}

.hero__logo__inner {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
}

@media screen and (max-width: 760px) {
  .hero__logo__inner {
    width: auto;
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .hero__logo__inner {
    padding-top: 20px;
  }
}

.hero__logo img {
  width: 330px;
  height: 384px;
  transform: translateX(-50px);
}

@media screen and (max-width: 760px) {
  .hero__logo img {
    width: 140px;
    height: 171px;
    transform: translateX(30px);
  }
}

.hero__logo__image01 {
  width: 316px;
  height: 384px;
  margin-left: -50px;
}

@media screen and (max-width: 760px) {
  .hero__logo__image01 {
    width: 140px;
    height: 171px;
    margin-left: 30px;
  }
}

.hero__logo__image01 svg {
  width: 315px;
  height: 384px;
}

@media screen and (max-width: 760px) {
  .hero__logo__image01 svg {
    width: 140px;
    height: 171px;
  }
}

.hero__logo__image01 .la {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  fill: transparent;
  animation: logo_anime0 3s ease-in 3s forwards;
}

.hero__logo__image02 {
  margin-top: -190px;
  margin-left: 20px;
  width: 180px;
  height: 27px;
}

@media screen and (max-width: 760px) {
  .hero__logo__image02 {
    position: absolute;
    top: 100px;
    left: 55px;
    width: 90px;
    height: 13px;
    margin-top: 0;
    margin-left: 0;
  }
}

.hero__logo__image02 .lt1 {
  paint-order: stroke;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  fill: transparent;
  animation: logo_anime1 2s ease-in 4s forwards;
}

.hero__logo__image02 svg {
  width: 180px;
  height: 27px;
  overflow: visible;
}

@media screen and (max-width: 760px) {
  .hero__logo__image02 svg {
    width: 90px;
    height: 13px;
  }
}

.hero__logo__image03 {
  margin-left: 15px;
  width: 186px;
  height: 14px;
}

@media screen and (max-width: 760px) {
  .hero__logo__image03 {
    position: absolute;
    top: 114px;
    left: 52px;
    width: 93px;
    height: 7px;
    margin-top: 0;
    margin-left: 0;
  }
}

.hero__logo__image03 .lt2 {
  paint-order: stroke;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  fill: transparent;
  animation: logo_anime2 2s ease-in 5s forwards;
}

.hero__logo__image03 svg {
  overflow: visible;
  width: 186px;
  height: 14px;
}

@media screen and (max-width: 760px) {
  .hero__logo__image03 svg {
    width: 93px;
    height: 7px;
  }
}

@keyframes logo_anime0 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: .5;
    stroke: #D00014;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill: #D00014;
  }
}

@keyframes logo_anime1 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: .5;
    stroke: #D00014;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 3px;
    stroke: #fff;
    fill: #D00014;
  }
}

@keyframes logo_anime2 {
  0% {
    stroke-dashoffset: 2000;
    stroke-width: .5;
    stroke: #403D3C;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 3px;
    stroke: #fff;
    fill: #403D3C;
  }
}

.slider .slider-image {
  vertical-align: bottom;
}

.slider img {
  vertical-align: bottom;
}

.slider .slick-active img {
  /* transform: scale(1.0); */
}

/* TOPPAGE slick slide first image animation */
@-webkit-keyframes slickActive1 {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slickActive1 {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.top-news {
  margin-bottom: 60px;
  padding: 100px 0 60px;
  background: #f3fafe;
}

@media screen and (max-width: 760px) {
  .top-news {
    margin-bottom: 30px;
    padding: 30px 0;
  }
}

.top-news__box {
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 10px 60px;
}

@media screen and (max-width: 760px) {
  .top-news__box {
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 5px 20px;
  }
}

.top-news__feed {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 760px) {
  .top-news__feed {
    width: auto;
    width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .top-news__feed {
    display: block;
    width: auto;
  }
}

.top-news__feed li {
  width: 32%;
  margin-right: 2%;
}

@media screen and (max-width: 760px) {
  .top-news__feed li {
    width: 100%;
    margin-right: 0;
  }
}

.top-news__feed li:nth-child(3n) {
  margin-right: 0;
}

.top-news__feed li a {
  display: block;
  padding: 14px 0;
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .top-news__feed li a {
    font-size: 15px;
    font-size: 4vw;
    padding: 7px 0;
    line-height: 1.5;
  }
}

.top-news__feed li a .image {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}

.top-news__feed li a .image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: .5s;
  transform: scale(1.1);
}

.top-news__feed li a .date {
  margin-right: 20px;
  color: #686C67;
  font-size: 14px;
}

@media screen and (max-width: 760px) {
  .top-news__feed li a .date {
    font-size: 12px;
    font-size: 3.2vw;
    margin-right: 10px;
  }
}

.top-news__feed li a .category {
  display: inline-block;
  margin-right: 20px;
  padding: 0 20px 2px;
  background: #2a3293;
  color: #fff;
  font-size: 12px;
  text-align: center;
  transform: translateY(-2px);
}

@media screen and (max-width: 760px) {
  .top-news__feed li a .category {
    margin-right: 0;
    font-size: 10px;
  }
}

.top-news__feed li a .title {
  display: block;
  margin-top: 10px;
  line-height: 1.5;
  transition: .5s;
}

@media screen and (max-width: 760px) {
  .top-news__feed li a .title {
    display: block;
    margin-top: 4px;
  }
}

@media screen and (min-width: 761px) {
  .top-news__feed li a:hover {
    transform: translateY(1px);
  }
}

.top-news__feed li a:hover .image img {
  transform: scale(1);
}

@media screen and (min-width: 761px) {
  .top-news__feed li a:hover .title {
    text-decoration: underline;
  }
}

.top-news .button__01 {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  .top-news .button__01 {
    width: auto;
    width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

.top-service {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 100px;
  padding-top: 140px;
}

@media screen and (max-width: 760px) {
  .top-service {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .top-service {
    margin-bottom: 50px;
    padding-top: 50px;
  }
}

.top-service__logo {
  position: absolute;
  top: 140px;
  right: 220px;
}

@media screen and (max-width: 760px) {
  .top-service__logo {
    position: static;
    padding: 20px 0 0;
    text-align: center;
  }
}

.top-service__logo img {
  width: 196px;
  height: 240px;
}

@media screen and (max-width: 760px) {
  .top-service__logo img {
    width: 36vw;
    height: 160px;
  }
}

.top-service__box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
  padding-bottom: 30px;
}

@media screen and (max-width: 760px) {
  .top-service__box {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .top-service__box {
    padding-bottom: 0;
  }
}

.top-service__box__image {
  width: 660px;
}

@media screen and (max-width: 760px) {
  .top-service__box__image {
    width: auto;
  }
}

.top-service__box__text {
  align-self: center;
}

@media screen and (max-width: 760px) {
  .top-service__box__text {
    position: static;
    width: 80vw;
    margin: 0 auto;
    transform: translateY(-30px);
  }
}

.top-service__box__text a {
  display: block;
  width: 420px;
  padding: 30px;
  color: #fff;
  background: #6bc8ee;
  transform: translateX(-30px);
}

@media screen and (max-width: 760px) {
  .top-service__box__text a {
    width: 80vw;
    padding: 20px;
    transform: translate(0);
  }
}

@media screen and (min-width: 761px) {
  .top-service__box__text a:hover {
    color: #fff;
    transform: translate(-30px, 3px);
  }
}

@media screen and (min-width: 761px) {
  .top-service__box__text a:hover .top-service__box__text__button span::before {
    right: 10px;
    width: 40px;
  }
}

@media screen and (min-width: 761px) {
  .top-service__box__text a:hover .top-service__box__text__button span::after {
    right: 10px;
  }
}

.top-service__box__text__title {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
  font-size: 35px;
  font-weight: 300;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .top-service__box__text__title {
    font-size: 23px;
    font-size: 6.13333vw;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }
}

.top-service__box__text__copy {
  margin-bottom: 30px;
  font-size: 18px;
}

@media screen and (max-width: 760px) {
  .top-service__box__text__copy {
    font-size: 13px;
    font-size: 3.46667vw;
    margin-bottom: 15px;
  }
}

.top-service__box__text__button {
  text-align: right;
}

.top-service__box__text__button span {
  position: relative;
  display: inline-block;
  padding: 4px 60px 5px 30px;
  border: 1px solid #fff;
  font-size: 17px;
  letter-spacing: 3px;
}

@media screen and (max-width: 760px) {
  .top-service__box__text__button span {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.top-service__box__text__button span::before {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background: #fff;
  transition: .3s;
}

.top-service__box__text__button span::after {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: translateY(-4px) rotate(45deg);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .top-service__box__text__button span::after {
    width: 7px;
    transform: translateY(-4px) rotate(45deg);
  }
}

.top-service__box.first {
  padding: 100px 0 30px;
}

@media screen and (max-width: 760px) {
  .top-service__box.first {
    padding: 50px 0 0;
  }
}

.top-service__box.reverse {
  flex-direction: row-reverse;
}

.top-service__box.reverse .top-service__box__text a {
  transform: translateX(30px);
}

@media screen and (max-width: 760px) {
  .top-service__box.reverse .top-service__box__text a {
    transform: translate(0);
  }
}

@media screen and (min-width: 761px) {
  .top-service__box.reverse .top-service__box__text a:hover {
    transform: translate(30px, 3px);
  }
}

.top-service__next--01 {
  position: relative;
  left: 36%;
  width: 70px;
}

@media screen and (max-width: 760px) {
  .top-service__next--01 {
    width: 35px;
    margin-bottom: 20px;
  }
}

.top-service__next--01 svg {
  width: 70px;
  height: 120px;
}

@media screen and (max-width: 760px) {
  .top-service__next--01 svg {
    width: 35px;
    height: 60px;
  }
}

.top-service__next--02 {
  position: relative;
  left: 60%;
  width: 70px;
}

@media screen and (max-width: 760px) {
  .top-service__next--02 {
    width: 35px;
    margin-bottom: 20px;
  }
}

.top-service__next--02 svg {
  width: 70px;
  height: 120px;
}

@media screen and (max-width: 760px) {
  .top-service__next--02 svg {
    width: 35px;
    height: 60px;
  }
}

@keyframes na_anime {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }
  50% {
    fill: transparent;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-width: 0;
    fill: #D00014;
  }
}

.top-area {
  background: url(../img/top/img_map_03.png) center 200px no-repeat;
}

@media screen and (max-width: 760px) {
  .top-area {
    background: none;
  }
}

.top-area__inner {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 1200px;
}

@media screen and (max-width: 760px) {
  .top-area__inner {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .top-area__inner {
    height: auto;
  }
}

.top-area__main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  top: 130px;
  left: 0;
}

@media screen and (max-width: 760px) {
  .top-area__main {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .top-area__main {
    position: static;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.top-area__main__title {
  padding: 25px 30px;
  background: #6bc8ee;
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 760px) {
  .top-area__main__title {
    font-size: 18px;
    font-size: 4.8vw;
    padding: 5px 5vw;
  }
}

.top-area__main__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 450px;
  padding: 30px 0 30px 50px;
  background: #f3fafe;
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .top-area__main__list {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .top-area__main__list {
    width: auto;
    padding: 5px 5vw;
  }
}

.top-area__main__list li {
  width: 45%;
  margin-right: 5%;
}

@media screen and (max-width: 760px) {
  .top-area__main__list li {
    width: auto;
    margin-right: 0;
    border-bottom: 1px solid #403D3C;
  }
}

.top-area__main__list li::before {
  content: "・";
}

@media screen and (max-width: 760px) {
  .top-area__main__list li::before {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .top-area__main__list li:last-child {
    border-bottom: 0;
  }
}

.top-area__main__list li a {
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .top-area__main__list li a {
    display: block;
    padding: 10px 0;
    text-decoration: none;
  }
}

.top-area__part {
  position: absolute;
  padding: 20px 40px 30px 30px;
  background: #6bc8ee;
  color: #fff;
}

@media screen and (max-width: 760px) {
  .top-area__part {
    position: static;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
  }
}

.top-area__part__title {
  margin-bottom: 6px;
  font-size: 22px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 760px) {
  .top-area__part__title {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 0;
    padding: 5px 5vw;
  }
}

.top-area__part__list {
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .top-area__part__list {
    padding: 5px 5vw;
    background: #f3fafe;
  }
}

@media screen and (max-width: 760px) {
  .top-area__part__list li {
    border-bottom: 1px solid #6bc8ee;
  }
}

.top-area__part__list li::before {
  content: "・";
}

@media screen and (max-width: 760px) {
  .top-area__part__list li::before {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .top-area__part__list li:last-child {
    border-bottom: 0;
  }
}

.top-area__part__list li a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .top-area__part__list li a {
    display: block;
    padding: 10px 0;
    color: #6bc8ee;
    text-decoration: none;
  }
}

.top-area__part--01 {
  top: 740px;
  right: 100px;
}

.top-area__part--02 {
  top: 860px;
  right: 400px;
}

.top-area__part--03 {
  top: 330px;
  left: 420px;
}

.top-area__part--04 {
  top: 340px;
  left: 0;
}

.top-area__part--05 {
  top: 900px;
  left: 160px;
}

@media screen and (max-width: 1160px) {
  .top-area__part--05 {
    left: 0;
  }
}

.top-area .button__01 {
  position: absolute;
  right: 100px;
  bottom: 70px;
}

@media screen and (max-width: 760px) {
  .top-area .button__01 {
    position: static;
    margin: 30px 0 60px;
  }
}

.top-company {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 80px;
}

@media screen and (max-width: 760px) {
  .top-company {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .top-company {
    padding: 0 0 50px;
  }
}

.top-company__info {
  padding-top: 60px;
}

@media screen and (max-width: 760px) {
  .top-company__info {
    padding: 30px 0 0;
  }
}

.top-company__items {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 90px 0 0;
}

@media screen and (max-width: 760px) {
  .top-company__items {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .top-company__items {
    padding: 40px 0 0;
  }
}

.top-company__item {
  position: relative;
  width: 46.7%;
  margin-bottom: 60px;
}

@media screen and (max-width: 760px) {
  .top-company__item {
    width: auto;
    margin-bottom: 30px;
  }
}

.top-company__item a {
  position: relative;
  display: block;
}

.top-company__item a::after {
  position: absolute;
  right: 0;
  bottom: 0;
  content: "";
  display: block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: #6bc8ee;
  transition: .3s;
  z-index: -1;
}

.top-company__item__title {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  width: 220px;
  padding: 20px;
  background: #fff;
  color: #2a3293;
  line-height: 1;
  transform: translateY(-50%);
}

@media screen and (max-width: 760px) {
  .top-company__item__title {
    width: 140px;
  }
}

.top-company__item__title .ja {
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 4px;
}

@media screen and (max-width: 760px) {
  .top-company__item__title .ja {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 5px;
    letter-spacing: 2px;
  }
}

.top-company__item__title .en {
  font-family: "Roboto", sans-serif;
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .top-company__item__title .en {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.top-company__item__image {
  padding: 0 10px 10px 0;
  overflow: hidden;
  transition: .3s;
}

@media screen and (min-width: 761px) {
  .top-company a:hover .top-company__item__image {
    transform: translate(10px, 10px);
  }
}

@media screen and (min-width: 761px) {
  .top-company a:hover .top-company__item__image img {
    opacity: 1;
  }
}

.top-group {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 150px;
  background: #6bc8ee;
}

@media screen and (max-width: 760px) {
  .top-group {
    width: auto;
    min-width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .top-group {
    margin-bottom: 80px;
  }
}

.top-group::before {
  position: absolute;
  top: 0;
  left: -42px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 308px 42px;
  border-color: transparent transparent #6bc8ee transparent;
}

.top-group::after {
  position: absolute;
  top: 0;
  right: -42px;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 308px 42px 0 0;
  border-color: #6bc8ee transparent transparent transparent;
}

.top-group a {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .top-group a {
    display: block;
    width: auto;
  }
}

.top-group__main {
  padding: 40px;
}

@media screen and (max-width: 760px) {
  .top-group__main {
    display: flex;
    padding: 30px;
  }
}

.top-group__main .button {
  color: #fff;
}

@media screen and (max-width: 760px) {
  .top-group__main .button {
    margin-top: 6px;
  }
}

.top-group__main .button span {
  position: relative;
  display: inline-block;
  padding: 6px 60px 7px 30px;
  border: 1px solid #fff;
  font-size: 17px;
  letter-spacing: 3px;
}

@media screen and (max-width: 760px) {
  .top-group__main .button span {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.top-group__main .button span::before {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background: #fff;
  transition: .3s;
}

.top-group__main .button span::after {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: translateY(-4px) rotate(45deg);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .top-group__main .button span::after {
    width: 7px;
    transform: translateY(-4px) rotate(45deg);
  }
}

.top-group__title {
  color: #fff;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .top-group__title {
    margin-right: 20px;
  }
}

.top-group__title .en {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 63px;
  font-weight: 300;
  letter-spacing: 8px;
  transition: .3s;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 760px) {
  .top-group__title .en {
    font-size: 34px;
    font-size: 9.06667vw;
    margin-bottom: 5px;
    letter-spacing: 4px;
  }
}

.top-group__title .ja {
  margin-bottom: 80px;
  font-size: 21px;
  font-weight: 300;
  text-indent: 10px;
}

@media screen and (max-width: 760px) {
  .top-group__title .ja {
    font-size: 13px;
    font-size: 3.46667vw;
    margin-bottom: 0;
    text-indent: 4px;
  }
}

.top-group__image {
  position: relative;
  margin-right: 30px;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  .top-group__image {
    margin-right: 0;
  }
}

.top-group__image::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 308px 0 0 123px;
  border-color: transparent transparent transparent #6bc8ee;
}

@media screen and (max-width: 760px) {
  .top-group__image::after {
    display: none;
  }
}

.top-group__image img {
  transition: 2s !important;
}

@media screen and (min-width: 761px) {
  .top-group a:hover .button span::before {
    right: 10px;
    width: 40px;
  }
}

@media screen and (min-width: 761px) {
  .top-group a:hover .button span::after {
    right: 10px;
  }
}

@media screen and (min-width: 761px) {
  .top-group a:hover .top-group__image img {
    opacity: 1;
    transform: scale(1.1);
  }
}

.top-recruit {
  position: relative;
  height: 465px;
  margin-bottom: 120px;
  padding-top: 140px;
  background: url(../img/top/bg_recruit.jpg) center no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1090px) {
  .top-recruit {
    height: auto;
    margin-bottom: 60px;
    padding-top: 40px;
    padding-bottom: 80px;
    background-image: url(../img/top/sp/bg_recruit.jpg);
  }
}

.top-recruit .button__02 {
  margin-bottom: 40px;
}

@media screen and (max-width: 760px) {
  .top-recruit .button__02 {
    margin-bottom: 20px;
  }
}

.top-recruit__main {
  position: absolute;
  top: 80px;
  left: 0;
}

@media screen and (max-width: 1090px) {
  .top-recruit__main {
    position: static;
  }
}

.top-recruit__title {
  width: 400px;
  margin-bottom: 20px;
  padding: 40px 0;
  background: #6bc8ee;
  color: #fff;
  line-height: 1;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .top-recruit__title {
    width: 60vw;
    margin-bottom: 10px;
    padding: 20px 0;
  }
}

.top-recruit__title .en {
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 55px;
  font-weight: 300;
  letter-spacing: 8px;
  transition: .3s;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 760px) {
  .top-recruit__title .en {
    font-size: 34px;
    font-size: 9.06667vw;
    margin-bottom: 5px;
    letter-spacing: 4px;
  }
}

.top-recruit__title .ja {
  font-size: 21px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .top-recruit__title .ja {
    font-size: 13px;
    font-size: 3.46667vw;
    margin-bottom: 0;
  }
}

.top-recruit__copy {
  margin-left: 50px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .top-recruit__copy {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.page-title {
  position: relative;
  height: 170px;
  padding-top: 35px;
  background: url(../img/common/bg_pagetitle.png) center no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  .page-title {
    height: 99px;
    padding-top: 24px;
    background: url(../img/common/sp/bg_pagetitle.png) center no-repeat;
    background-size: contain;
  }
}

.page-title__main {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 38px;
  font-weight: 500;
  line-height: 1.5;
  -webkit-text-size-adjust: none;
}

@media screen and (max-width: 760px) {
  .page-title__main {
    font-size: 21px;
  }
}

.page-title__sub {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.5;
  -webkit-text-size-adjust: none;
}

@media screen and (max-width: 760px) {
  .page-title__sub {
    font-size: 15px;
  }
}

.sub-navi {
  background: #403D3C;
}

.sub-navi__list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

@media screen and (max-width: 760px) {
  .sub-navi__list {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 760px) {
  .sub-navi__list li {
    width: 50%;
    border-bottom: 1px solid #fff;
  }
}

@media screen and (max-width: 760px) {
  .sub-navi__list li:nth-child(odd) {
    border-right: 1px solid #fff;
  }
}

.sub-navi__list li a {
  display: block;
  padding: 6px 25px 10px;
  color: #fff;
  font-size: 18px;
}

@media screen and (max-width: 760px) {
  .sub-navi__list li a {
    font-size: 16px;
    text-align: center;
  }
}

.sub-navi__list li a:hover {
  background: #fff;
  color: #6bc8ee;
}

.sub-navi__list li.current a {
  background: #fff;
  color: #403D3C;
  font-weight: 500;
}

.page-navi {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  padding-top: 50px;
  border-top: 1px solid #6bc8ee;
}

@media screen and (max-width: 760px) {
  .page-navi {
    width: auto;
    width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .page-navi {
    margin-bottom: 40px;
    padding-top: 30px;
  }
}

.page-navi ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.page-navi li {
  width: 230px;
}

@media screen and (max-width: 760px) {
  .page-navi li {
    width: 44vw;
    margin-bottom: 2vw;
  }
}

.page-navi li a {
  position: relative;
  display: block;
  height: 140px;
  padding-top: 50px;
  text-align: center;
  overflow: hidden;
}

@media screen and (max-width: 760px) {
  .page-navi li a {
    font-size: 16px;
    font-size: 4.26667vw;
    height: 87px;
    padding-top: 30px;
  }
}

.page-navi li a::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: .5s;
}

.page-navi li a::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(47, 142, 222, 0.2);
  transition: .5s;
}

.page-navi li a:hover::before {
  transform: scale(1.2, 1.2);
}

.page-navi li a:hover::after {
  opacity: 0;
}

.page-navi li a span {
  position: relative;
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.page-navi__service li {
  width: 300px;
}

@media screen and (max-width: 760px) {
  .page-navi__service li {
    width: 100%;
    margin-bottom: 20px;
  }
}

.page-navi__service li a {
  height: 190px;
  padding-top: 75px;
}

@media screen and (max-width: 760px) {
  .page-navi__service li a {
    font-size: 25px;
    font-size: 6.66667vw;
    height: 180px;
    padding-top: 70px;
  }
}

.page-navi__service li a span {
  font-size: 27px;
}

@media screen and (max-width: 760px) {
  .page-navi__company li a span {
    font-size: 16px;
    font-size: 4.26667vw;
  }
}

.page-navi__service li:nth-child(1) a::before {
  background-image: url(../img/service/img_service_02.jpg);
}

.page-navi__service li:nth-child(2) a::before {
  background-image: url(../img/service/img_service_03.jpg);
}

.page-navi__service li:nth-child(3) a::before {
  background-image: url(../img/service/img_service_01.jpg);
}

.page-navi__company li:nth-child(1) a::before {
  background-image: url(../img/company/img_company_02.jpg);
}

.page-navi__company li:nth-child(2) a::before {
  background-image: url(../img/company/img_company_01.jpg);
}

.page-navi__company li:nth-child(3) a::before {
  background-image: url(../img/company/img_company_03.jpg);
}

.page-navi__company li:nth-child(4) a::before {
  background-image: url(../img/company/img_company_04.jpg);
}

.bge-contents a:not([href]):hover img {
  opacity: 1;
}

.blog-index {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
}

@media screen and (max-width: 760px) {
  .blog-index {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .blog-index {
    width: auto;
  }
}

.blog-index .post {
  width: 32%;
  margin-right: 2%;
}

@media screen and (max-width: 760px) {
  .blog-index .post {
    width: 100%;
    margin-right: 0;
  }
}

.blog-index .post:nth-child(3n) {
  margin-right: 0;
}

.blog-index .post a {
  position: relative;
  display: block;
  padding: 20px 2%;
  font-size: 16px;
  line-height: 1.4;
}

@media screen and (max-width: 760px) {
  .blog-index .post a {
    font-size: 13px;
    font-size: 3.46667vw;
    padding: 16px 0;
  }
}

.blog-index .post a:hover .image img {
  transform: scale(1);
}

.blog-index .post a:hover .title {
  text-decoration: underline;
}

.blog-index .post a .image {
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}

.blog-index .post a .image img {
  width: 100%;
  height: 220px;
  object-fit: cover;
  transition: .5s;
  transform: scale(1.1);
}

.blog-index .post a .date {
  display: inline-block;
  margin-right: 10px;
  color: #686C67;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .blog-index .post a .date {
    font-size: 14px;
  }
}

.blog-index .post a .category {
  display: inline-block;
  padding: 1px 14px 2px;
  background: #686C67;
  color: #fff;
  font-size: 12px;
  text-align: center;
  transform: translateY(-2px);
}

@media screen and (max-width: 760px) {
  .blog-index .post a .category {
    font-size: 12px;
  }
}

.blog-index .post a .title {
  display: block;
  padding-top: 10px;
  font-weight: 300;
  transition: .5s;
}

@media screen and (max-width: 760px) {
  .blog-index .post a .title {
    font-size: 14px;
    font-size: 3.73333vw;
    width: 100%;
    padding-top: 5px;
  }
}

.blog-single {
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  line-height: 2.2;
}

@media screen and (max-width: 760px) {
  .blog-single {
    width: auto;
    font-size: 14px;
    line-height: 1.8;
  }
}

.blog-single a:hover img {
  opacity: 1 !important;
}

.blog-single .post {
  margin-bottom: 90px;
}

@media screen and (max-width: 760px) {
  .blog-single .post {
    margin-bottom: 40px;
  }
}

.blog-single .meta {
  margin: 20px 0;
  text-align: right;
}

@media screen and (max-width: 760px) {
  .blog-single .meta {
    margin: 10px 0;
  }
}

.blog-single .meta .date {
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .blog-single .meta .date {
    font-size: 14px;
  }
}

.blog-single .meta .category {
  margin-right: 10px;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .blog-single .meta .category {
    font-size: 14px;
  }
}

.blog-single .meta .category a {
  color: #6bc8ee;
}

.blog-single .meta .category a:hover {
  text-decoration: underline;
}

.blog-single .eyecatch {
  text-align: center;
  margin-bottom: 60px;
}

@media screen and (max-width: 760px) {
  .blog-single .eyecatch {
    margin-bottom: 20px;
  }
}

.blog-single .post-discription {
  margin-bottom: 30px;
}

.blog-single .post-main a {
  color: #6bc8ee;
}

.blog-single .post-main a:hover {
  text-decoration: underline;
}

/* index */
.contact-index {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media screen and (max-width: 760px) {
  .contact-index {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .contact-index {
    display: block;
    width: auto;
  }
}

.contact-index__inner {
  width: 470px;
  padding: 60px 0 0;
  background: #F3F2EE;
}

@media screen and (max-width: 760px) {
  .contact-index__inner {
    width: auto;
    margin-bottom: 60px;
    padding: 30px 0 0;
  }
}

.contact-index__title .bge-title-h2 {
  padding: 20px 0;
  background: #fff;
  text-align: center;
  font-size: 25px;
}

@media screen and (max-width: 760px) {
  .contact-index__title .bge-title-h2 {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.contact-index__box {
  padding: 40px 30px;
}

@media screen and (max-width: 760px) {
  .contact-index__box {
    padding: 30px 5vw;
  }
}

.contact-index__button {
  margin-bottom: 40px;
}

@media screen and (max-width: 760px) {
  .contact-index__button {
    padding: 0 3vw;
    margin-bottom: 20px;
  }
}

.contact-index__button a {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 70px 0 50px;
  background: #fff;
  font-size: 24px;
  line-height: 70px;
  appearance: none;
}

@media screen and (max-width: 760px) {
  .contact-index__button a {
    padding: 0 20px 0 0;
    font-size: 18px;
    line-height: 56px;
  }
}

.contact-index__button a span {
  position: relative;
  display: inline-block;
  color: #fff;
  z-index: 1;
}

.contact-index__button a::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 100%;
  background: #6bc8ee;
  transition: .3s;
}

.contact-index__button a::after {
  position: absolute;
  right: 20px;
  top: 50%;
  content: "";
  display: block;
  width: 40px;
  height: 15px;
  background: url(../img/common/icn_arrow_bold.svg) 0 0 no-repeat;
  background-size: 40px 15px;
  transform: translateY(-50%);
  transition: .3s;
}

.contact-index__button a:hover span {
  color: #6bc8ee;
}

.contact-index__button a:hover::before {
  left: auto;
  right: 0;
  width: 0;
}

.contact-index__button a:hover::after {
  background-image: url(../img/common/icn_arrow_bold_r.svg);
}

.contact-index__main {
  padding: 30px;
  background: #fff;
}

@media screen and (max-width: 760px) {
  .contact-index__main {
    padding: 15px;
  }
}

.contact-index__main__title {
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .contact-index__main__title {
    margin-bottom: 10px;
  }
}

.contact-index__main__title .bge-title-h3 {
  padding: 15px 0;
  border-top: 1px solid #6bc8ee;
  border-bottom: 1px solid #6bc8ee;
  color: #6bc8ee;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .contact-index__main__title .bge-title-h3 {
    font-size: 16px;
    font-size: 4.26667vw;
    padding: 8px 0;
  }
}

.contact-index__main__number {
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 500;
  line-height: 1.3;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .contact-index__main__number {
    font-size: 24px;
    font-size: 6.4vw;
    margin-bottom: 5px;
  }
}

.contact-index__main__number span {
  font-size: 30px;
}

@media screen and (max-width: 760px) {
  .contact-index__main__number span {
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.contact-index__main__name {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .contact-index__main__name {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

/* form */
.form-info {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  .form-info {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.form-info__title {
  color: #6bc8ee;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .form-info__title {
    font-size: 19px;
    font-size: 5.06667vw;
  }
}

.form-info__message {
  margin: 60px 0;
  padding: 20px 0;
  border-top: 1px solid #403D3C;
  border-bottom: 1px solid #403D3C;
  font-size: 21px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .form-info__message {
    font-size: 13px;
    font-size: 3.46667vw;
    margin: 30px 0;
    padding: 10px 0;
  }
}

.form-index {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  .form-index {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.form-table tr th {
  width: 280px;
  padding: 16px 0 0;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  vertical-align: top;
}

@media screen and (max-width: 760px) {
  .form-table tr th {
    font-size: 15px;
    font-size: 4vw;
    display: block;
    width: 100%;
    padding: 0;
  }
}

.form-table tr td {
  padding: 5px 0;
  font-size: 20px;
}

@media screen and (max-width: 760px) {
  .form-table tr td {
    font-size: 15px;
    font-size: 4vw;
    display: block;
    width: 100%;
    padding-top: 0;
    padding-bottom: 30px;
  }
}

.form-table tr td input[type=text] {
  width: 100%;
}

.form-table tr td textarea {
  width: 100%;
}

.form-agree {
  margin: 120px 0;
}

@media screen and (max-width: 760px) {
  .form-agree {
    margin: 60px 0;
  }
}

.form-agree__title {
  margin-bottom: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #6bc8ee;
  font-size: 24px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .form-agree__title {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.form-agree__check {
  font-size: 21px;
}

@media screen and (max-width: 760px) {
  .form-agree__check {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.form-agree__check__text {
  margin-bottom: 20px;
}

@media screen and (max-width: 760px) {
  .form-agree__check__text {
    margin-bottom: 10px;
  }
}

.form-agree__check__list {
  margin-bottom: 120px;
}

@media screen and (max-width: 760px) {
  .form-agree__check__list {
    margin-bottom: 60px;
  }
}

.form-agree .form-checkbox {
  text-align: center;
}

.form-agree .form-checkbox label {
  font-size: 21px;
}

@media screen and (max-width: 760px) {
  .form-agree .form-checkbox label {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.alert-message {
  margin-bottom: 30px;
  padding: 30px 40px;
  border: 6px solid #f3fafe;
}

@media screen and (max-width: 760px) {
  .alert-message {
    padding: 15px 20px;
  }
}

.error-message {
  color: #403D3C !important;
  font-weight: bold;
}

.form-error {
  border: 2px solid #6bc8ee !important;
  background: #f3fafe !important;
}

.form-confirm {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  .form-confirm {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.form-confirm .form-notice {
  margin: 60px 0;
  padding: 20px 0;
  border-top: 1px solid #403D3C;
  border-bottom: 1px solid #403D3C;
  font-size: 21px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .form-confirm .form-notice {
    font-size: 13px;
    font-size: 3.46667vw;
    margin: 30px 0;
    padding: 10px 0;
  }
}

.form-confirm .form-table {
  margin-bottom: 120px;
}

@media screen and (max-width: 760px) {
  .form-confirm .form-table {
    margin-bottom: 60px;
  }
}

.form-confirm .form-table tr {
  border-top: 1px solid #F3F2EE;
  border-bottom: 1px solid #F3F2EE;
}

.form-confirm .form-table th, .form-confirm .form-table td {
  padding: 20px 0;
}

@media screen and (max-width: 760px) {
  .form-confirm .form-table th, .form-confirm .form-table td {
    padding: 0;
  }
}

.form-end {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 120px;
}

@media screen and (max-width: 760px) {
  .form-end {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .form-end {
    margin-top: 60px;
  }
}

.form-end__text {
  margin-bottom: 120px;
}

@media screen and (max-width: 760px) {
  .form-end__text {
    margin-bottom: 60px;
  }
}

body.service_index .main, body.service_3pl .main, body.service_shipping .main, body.service_warehouse .main {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  body.service_index .main, body.service_3pl .main, body.service_shipping .main, body.service_warehouse .main {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  body.service_index .main .bge-contents > div, body.service_3pl .main .bge-contents > div, body.service_shipping .main .bge-contents > div, body.service_warehouse .main .bge-contents > div {
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  body.service_index .main .bge-contents .bgb-service-header, body.service_3pl .main .bge-contents .bgb-service-header, body.service_shipping .main .bge-contents .bgb-service-header, body.service_warehouse .main .bge-contents .bgb-service-header {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  body.service_index .main .bge-contents .service-flow, body.service_3pl .main .bge-contents .service-flow, body.service_shipping .main .bge-contents .service-flow, body.service_warehouse .main .bge-contents .service-flow {
    margin-left: 0;
    margin-right: 0;
  }
}

.service-index {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: relative;
  margin-bottom: 120px;
  padding-bottom: 30px;
}

@media screen and (max-width: 760px) {
  .service-index {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .service-index {
    margin-bottom: 50px;
    padding-bottom: 0;
  }
}

.service-index__image {
  position: relative;
  z-index: 8;
  width: 660px;
  margin-right: -90px;
}

@media screen and (max-width: 760px) {
  .service-index__image {
    width: auto;
    margin-right: 0;
  }
}

.service-index__title {
  position: relative;
  align-self: center;
  z-index: 9;
}

@media screen and (max-width: 760px) {
  .service-index__title {
    width: 80vw;
    margin: 0 auto;
    transform: translateY(-30px);
  }
}

.service-index__title a {
  display: block;
  width: 420px;
  padding: 30px;
  color: #fff;
  background: #6bc8ee;
}

@media screen and (max-width: 760px) {
  .service-index__title a {
    width: 80vw;
    padding: 20px;
    transform: translate(0);
  }
}

@media screen and (min-width: 761px) {
  .service-index__title a:hover {
    color: #fff;
    transform: translate(0, 3px);
  }
}

@media screen and (min-width: 761px) {
  .service-index__title a:hover .top-service__box__text__button span::before {
    right: 10px;
    width: 40px;
  }
}

@media screen and (min-width: 761px) {
  .service-index__title a:hover .top-service__box__text__button span::after {
    right: 10px;
  }
}

.service-index__title h2 {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff;
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
}

@media screen and (max-width: 760px) {
  .service-index__title h2 {
    font-size: 23px;
    font-size: 6.13333vw;
    margin-bottom: 10px;
    padding-bottom: 8px;
  }
}

.service-index__title p {
  margin-bottom: 30px;
  font-size: 18px;
}

@media screen and (max-width: 760px) {
  .service-index__title p {
    font-size: 13px;
    font-size: 3.46667vw;
    margin-bottom: 15px;
  }
}

.service-index__title .more {
  margin-bottom: 0;
  text-align: right;
}

.service-index__title .more span {
  position: relative;
  display: inline-block;
  padding: 4px 60px 5px 30px;
  border: 1px solid #fff;
  font-size: 15px;
  letter-spacing: 3px;
}

@media screen and (max-width: 760px) {
  .service-index__title .more span {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.service-index__title .more span::before {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 30px;
  height: 1px;
  background: #fff;
  transition: .3s;
}

.service-index__title .more span::after {
  position: absolute;
  top: 50%;
  right: 20px;
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
  transform: translateY(-4px) rotate(45deg);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .service-index__title .more span::after {
    width: 7px;
    transform: translateY(-4px) rotate(45deg);
  }
}

.service-index.first {
  padding: 100px 0 30px;
}

@media screen and (max-width: 760px) {
  .service-index.first {
    padding: 50px 0 0;
  }
}

.reverse .service-index {
  flex-direction: row-reverse;
}

.reverse .service-index .service-index__image {
  margin-right: 0;
  margin-left: -90px;
}

@media screen and (max-width: 760px) {
  .reverse .service-index .service-index__image {
    margin-left: 0;
  }
}

.service-copy {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .service-copy {
    font-size: 21px;
    font-size: 5.6vw;
  }
}

.service-header {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 100px;
  padding-bottom: 40px;
}

@media screen and (max-width: 760px) {
  .service-header {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .service-header {
    margin-bottom: 80px;
    padding-bottom: 0;
  }
}

.service-header__text {
  position: relative;
  z-index: 8;
  width: 780px;
  margin: -130px auto 0;
  padding: 40px 80px;
  background: #6bc8ee;
  color: #fff;
  font-size: 20px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .service-header__text {
    font-size: 13px;
    font-size: 3.46667vw;
    width: auto;
    margin: -50px 5vw 0;
    padding: 20px 5vw;
  }
}

@media screen and (max-width: 760px) {
  .service-3pl-01 img {
    width: 60vw;
  }
}

.area-map {
  margin-bottom: 80px;
  background: url(../img/top/img_map_03.png) center 200px no-repeat;
}

@media screen and (max-width: 760px) {
  .area-map {
    margin-bottom: 40px;
    background: none;
  }
}

.area-map__inner {
  max-width: 1200px;
  min-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 1200px;
}

@media screen and (max-width: 760px) {
  .area-map__inner {
    width: auto;
    min-width: auto;
    margin-left: 5vw;
    margin-right: 5vw;
  }
}

@media screen and (max-width: 760px) {
  .area-map__inner {
    height: auto;
  }
}

.area-map__main {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  top: 130px;
  left: 0;
}

@media screen and (max-width: 760px) {
  .area-map__main {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .area-map__main {
    position: static;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
}

.area-map__main__title {
  padding: 25px 30px;
  background: #403D3C;
  color: #fff;
  font-size: 22px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 760px) {
  .area-map__main__title {
    font-size: 18px;
    font-size: 4.8vw;
    padding: 5px 5vw;
  }
}

.area-map__main__title .bge-title-h2, .area-map__main__title .bge-title-h3 {
  padding: 0;
  background: none;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-map__main__title .bge-title-h2, .area-map__main__title .bge-title-h3 {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

.area-map__main__title a {
  display: block;
  color: #fff;
}

.area-map__main ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 450px;
  padding: 30px 0 30px 50px;
  background: #F3F2EE;
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .area-map__main ul {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .area-map__main ul {
    width: auto;
    padding: 5px 5vw;
  }
}

.area-map__main ul li {
  width: 45%;
  margin-right: 5%;
}

@media screen and (max-width: 760px) {
  .area-map__main ul li {
    width: auto;
    margin-right: 0;
    border-bottom: 1px solid #403D3C;
  }
}

.area-map__main ul li::before {
  content: "・";
}

@media screen and (max-width: 760px) {
  .area-map__main ul li::before {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .area-map__main ul li:last-child {
    border-bottom: 0;
  }
}

.area-map__main ul li a {
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .area-map__main ul li a {
    display: block;
    padding: 10px 0;
    text-decoration: none;
  }
}

.area-map__part {
  position: absolute;
  padding: 20px 40px 30px 30px;
  background: #6bc8ee;
  color: #fff;
}

@media screen and (max-width: 760px) {
  .area-map__part {
    position: static;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0;
    text-align: center;
  }
}

.area-map__part__title {
  margin-bottom: 6px;
  font-size: 22px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
}

@media screen and (max-width: 760px) {
  .area-map__part__title {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 0;
    padding: 5px 5vw;
  }
}

.area-map__part__title .bge-title-h2, .area-map__part__title .bge-title-h3 {
  padding: 0;
  background: none;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-map__part__title .bge-title-h2, .area-map__part__title .bge-title-h3 {
    font-size: 18px;
    font-size: 4.8vw;
  }
}

.area-map__part__title a {
  display: block;
  color: #fff;
}

.area-map__part ul {
  font-size: 17px;
}

@media screen and (max-width: 760px) {
  .area-map__part ul {
    padding: 5px 5vw;
    background: #f3fafe;
  }
}

@media screen and (max-width: 760px) {
  .area-map__part ul li {
    border-bottom: 1px solid #6bc8ee;
  }
}

.area-map__part ul li::before {
  content: "・";
}

@media screen and (max-width: 760px) {
  .area-map__part ul li::before {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .area-map__part ul li:last-child {
    border-bottom: 0;
  }
}

.area-map__part ul li a {
  color: #fff;
  text-decoration: underline;
}

@media screen and (max-width: 760px) {
  .area-map__part ul li a {
    display: block;
    padding: 10px 0;
    color: #6bc8ee;
    text-decoration: none;
  }
}

.area-map__part--01 {
  top: 740px;
  right: 100px;
}

.area-map__part--02 {
  top: 860px;
  right: 400px;
}

.area-map__part--03 {
  top: 330px;
  left: 420px;
}

.area-map__part--04 {
  top: 340px;
  left: 0;
}

.area-map__part--05 {
  top: 900px;
  left: 160px;
}

@media screen and (max-width: 1160px) {
  .area-map__part--05 {
    left: 0;
  }
}

.area-parts1 {
  margin: 0 0 20px;
  padding: 20px 0 0;
  border-top: 1px solid #403D3C;
}

@media screen and (max-width: 760px) {
  .area-parts1 {
    margin: 0 0 12px;
    padding: 12px 0 0;
  }
}

.area-parts1.last {
  border-bottom: 1px solid #403D3C;
}

.area-parts1__name {
  font-size: 24px;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .area-parts1__name {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.area-parts1__info {
  font-size: 23px;
  font-weight: 300;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-parts1__info {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.last .area-parts1 {
  margin-bottom: 140px;
  padding-bottom: 20px;
  border-bottom: 1px solid #403D3C;
}

@media screen and (max-width: 760px) {
  .last .area-parts1 {
    margin-bottom: 50px;
    padding-bottom: 12px;
  }
}

.area-parts2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #403D3C;
}

@media screen and (max-width: 760px) {
  .area-parts2 {
    display: block;
    width: auto;
  }
}

@media screen and (max-width: 760px) {
  .area-parts2 {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.area-parts2__body {
  width: 760px;
}

@media screen and (max-width: 760px) {
  .area-parts2__body {
    width: auto;
  }
}

.area-parts2__photo {
  width: 360px;
}

@media screen and (max-width: 760px) {
  .area-parts2__photo {
    width: auto;
  }
}

.area-parts2__name {
  margin-bottom: 6px;
  font-size: 26px;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .area-parts2__name {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.area-parts2__address {
  font-size: 23px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-parts2__address {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.area-parts2__link {
  margin: 15px 0;
  text-align: left;
}

.area-parts2__link a {
  display: inline-block;
  padding: 0 20px 4px;
  border-radius: 20px;
  background: #6bc8ee;
  color: #fff;
  font-size: 15px;
  appearance: none;
}

@media screen and (max-width: 760px) {
  .area-parts2__link a {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.area-parts2__link a:hover {
  transform: translateY(2px);
}

.area-parts2__link .bgt-btn-container {
  text-align: left;
}

.area-parts2__info {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-parts2__info {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.area-parts2__description {
  font-size: 16px;
}

@media screen and (max-width: 760px) {
  .area-parts2__description {
    font-size: 13px;
    font-size: 3.46667vw;
    margin-bottom: 20px;
  }
}

.last .area-parts2 {
  margin-bottom: 140px;
  padding-bottom: 0;
  border-bottom: none;
}

@media screen and (max-width: 760px) {
  .last .area-parts2 {
    margin-bottom: 50px;
  }
}

.area-parts3 {
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #403D3C;
}

.area-parts3__name {
  margin-bottom: 6px;
  font-size: 26px;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .area-parts3__name {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.area-parts3__address {
  font-size: 23px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-parts3__address {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.area-parts3__link {
  margin: 15px 0;
  text-align: left;
}

.area-parts3__link a {
  display: inline-block;
  padding: 0 20px 4px;
  border-radius: 20px;
  background: #6bc8ee;
  color: #fff;
  font-size: 15px;
  appearance: none;
}

@media screen and (max-width: 760px) {
  .area-parts3__link a {
    font-size: 12px;
    font-size: 3.2vw;
  }
}

.area-parts3__link a:hover {
  transform: translateY(2px);
}

.area-parts3__link .bgt-btn-container {
  text-align: left;
}

.area-parts3__info {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .area-parts3__info {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.area-parts3__description {
  font-size: 16px;
}

@media screen and (max-width: 760px) {
  .area-parts3__description {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.last .area-parts3 {
  margin-bottom: 140px;
  padding-bottom: 0;
  border-bottom: none;
}

@media screen and (max-width: 760px) {
  .last .area-parts3 {
    margin-bottom: 50px;
  }
}

.main .bgb-area-parts1, .main .bgb-area-parts2, .main .bgb-area-parts3 {
  margin-top: -80px;
  padding-top: 80px;
}

body.company_index .bge-contents {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 760px) {
  body.company_index .bge-contents {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  body.company_index .bge-contents {
    display: block;
    width: auto;
  }
}

body.company_index .wrapper {
  overflow: hidden;
}

.company-message_bge {
  margin-bottom: 100px;
}

@media screen and (max-width: 760px) {
  .company-message_bge {
    margin-bottom: 50px;
  }
}

.company-about_bge {
  position: relative;
  margin-bottom: 100px;
  padding: 100px 0;
  background: #f3fafe;
}

@media screen and (max-width: 760px) {
  .company-about_bge {
    margin-bottom: 50px;
    padding: 50px 0;
  }
}

.company-about_bge::before {
  position: absolute;
  top: 0;
  left: -50%;
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background: #f3fafe;
}

.company-about_bge::after {
  position: absolute;
  top: 0;
  right: -50%;
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background: #f3fafe;
}

.company-structure_bge {
  margin-bottom: 100px;
}

@media screen and (max-width: 760px) {
  .company-structure_bge {
    margin-bottom: 50px;
  }
}

.company-index {
  width: 470px;
  margin-bottom: 120px;
}

@media screen and (max-width: 760px) {
  .company-index {
    width: auto;
    margin-bottom: 50px;
  }
}

.company-index__image {
  overflow: hidden;
}

.company-index__image img {
  transition: .5s;
}

.company-index__image a:hover img {
  transform: scale(1.1, 1.1);
}

.company-index__title {
  position: relative;
  z-index: 5;
}

.company-index__title a {
  display: block;
  width: 340px;
  margin: -50px auto 0;
  padding: 18px 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  color: #2a3293;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .company-index__title a {
    width: 250px;
    margin: -40px auto 0;
    padding: 13px 0;
  }
}

.company-index__title a:hover {
  transform: translate(2px, 2px);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.company-index__title h2 {
  font-size: 23px;
  font-weight: 500;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .company-index__title h2 {
    font-size: 19px;
    font-size: 5.06667vw;
  }
}

.company-index__title h3 {
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: 300;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .company-index__title h3 {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.company-index__title p.more {
  position: relative;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .company-index__title p.more {
    font-size: 13px;
    font-size: 3.46667vw;
  }
}

.company-index__title p.more::before {
  position: absolute;
  top: 50%;
  right: -40px;
  content: "";
  display: block;
  width: 28px;
  height: 1px;
  background: #2a3293;
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .company-index__title p.more::before {
    width: 20px;
  }
}

.company-index__title p.more::after {
  position: absolute;
  top: 50%;
  right: -36px;
  content: "";
  display: block;
  width: 9px;
  height: 9px;
  border-right: 1px solid #2a3293;
  border-top: 1px solid #2a3293;
  transform: rotate(45deg) translateY(-6px);
  transition: .3s;
}

@media screen and (max-width: 760px) {
  .company-index__title p.more::after {
    width: 7px;
    height: 7px;
    transform: rotate(45deg) translateY(-4px);
  }
}

.company-index__title a:hover p.more::before {
  width: 0;
  right: -100px;
}

.company-index__title a:hover p.more::after {
  right: -100px;
}

.company-about-01 {
  font-size: 42px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .company-about-01 {
    font-size: 22px;
    font-size: 5.86667vw;
  }
}

.company-logo {
  width: 196px;
  height: 234px;
  margin: 0 auto 60px;
}

@media screen and (max-width: 760px) {
  .company-logo {
    width: 108px;
    height: 132px;
    margin-bottom: 30px;
  }
}

.company-logo img {
  width: 196px;
  height: 234px;
}

@media screen and (max-width: 760px) {
  .company-logo img {
    width: 108px;
    height: 132px;
  }
}

.company-logo__image01 {
  width: 196px;
  height: 234px;
}

@media screen and (max-width: 760px) {
  .company-logo__image01 {
    width: 108px;
    height: 132px;
  }
}

.company-logo__image01 svg {
  width: 196px;
  height: 234px;
}

@media screen and (max-width: 760px) {
  .company-logo__image01 svg {
    width: 108px;
    height: 132px;
  }
}

.company-logo__image01 .la {
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  fill: transparent;
  animation: logo_anime0 3s ease-in 1s forwards;
}

.company-logo__image02 {
  margin-top: -126px;
  margin-left: 32px;
  width: 126px;
  height: 19px;
}

@media screen and (max-width: 760px) {
  .company-logo__image02 {
    width: 70px;
    height: 11px;
    margin-top: -75px;
    margin-left: 18px;
  }
}

.company-logo__image02 .lt1 {
  paint-order: stroke;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  fill: transparent;
  animation: logo_anime1 2s ease-in 2s forwards;
}

.company-logo__image02 svg {
  width: 126px;
  height: 19px;
  overflow: visible;
}

@media screen and (max-width: 760px) {
  .company-logo__image02 svg {
    width: 70px;
    height: 11px;
  }
}

.company-logo__image03 {
  margin-left: 30px;
  width: 130px;
  height: 10px;
}

@media screen and (max-width: 760px) {
  .company-logo__image03 {
    width: 73px;
    height: 6px;
    margin-left: 17px;
  }
}

.company-logo__image03 .lt2 {
  paint-order: stroke;
  stroke-dasharray: 2000;
  stroke-dashoffset: 0;
  fill: transparent;
  animation: logo_anime2 2s ease-in 3s forwards;
}

.company-logo__image03 svg {
  overflow: visible;
  width: 130px;
  height: 10px;
}

@media screen and (max-width: 760px) {
  .company-logo__image03 svg {
    width: 73px;
    height: 6px;
  }
}

.company-about-02 {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
}

@media screen and (max-width: 760px) {
  .company-about-02 {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .company-about-02 {
    margin-bottom: 75px;
  }
}

.company-about-02 table tr {
  border-top: 1px solid #6bc8ee;
  border-bottom: 1px solid #6bc8ee;
}

.company-about-02 table tr th {
  width: 20%;
  padding: 16px 30px;
  color: #2a3293;
  font-size: 21px;
  font-weight: 500;
  text-align: left;
  vertical-align: top;
}

@media screen and (max-width: 760px) {
  .company-about-02 table tr th {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding: 10px 0 0;
  }
}

.company-about-02 table tr td {
  width: 80%;
  padding: 16px 30px;
  font-size: 21px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .company-about-02 table tr td {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding: 0 0 10px;
  }
}

.company-about-03 {
  width: 660px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 760px) {
  .company-about-03 {
    width: auto;
  }
}

.company-message-01 {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  width: 316px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 760px) {
  .company-message-01 {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .company-message-01 {
    width: 106px;
    margin-bottom: 40px;
  }
}

.company-message-02 {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-size: 38px;
  font-weight: 400;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .company-message-02 {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .company-message-02 {
    font-size: 21px;
    font-size: 5.6vw;
  }
}

.company-message-03 {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  font-size: 21px;
}

@media screen and (max-width: 760px) {
  .company-message-03 {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .company-message-03 {
    font-size: 14px;
    font-size: 3.73333vw;
    margin-bottom: 30px;
  }
}

.company-message-04 {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  text-align: right;
  font-family: fot-seurat-pron, sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 760px) {
  .company-message-04 {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .company-message-04 {
    margin-bottom: 75px;
  }
}

.company-message-04 img {
  display: inline !important;
  margin: 0 !important;
}

@media screen and (max-width: 760px) {
  .company-message-04 img {
    width: 210px;
  }
}

.company-message-04 span {
  display: inline-block;
  margin-left: 20px;
  font-size: 40px;
  letter-spacing: 5px;
  vertical-align: middle;
}

@media screen and (max-width: 760px) {
  .company-message-04 span {
    font-size: 16px;
    letter-spacing: 2px;
  }
}

.company-message-05 {
  width: 660px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 760px) {
  .company-message-05 {
    width: auto;
  }
}

.company-history-01 {
  width: 800px;
  margin: 0 auto 120px;
}

@media screen and (max-width: 760px) {
  .company-history-01 {
    width: auto;
    margin-bottom: 40px;
  }
}

.company-history-01 table tr th {
  position: relative;
  width: 30%;
  padding: 0 40px 60px 40px;
  font-size: 21px;
  font-weight: 300;
  text-align: left;
  vertical-align: top;
}

@media screen and (max-width: 760px) {
  .company-history-01 table tr th {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding: 0 0 0 20px;
  }
}

.company-history-01 table tr th::before {
  position: absolute;
  top: 10px;
  left: 0;
  content: "";
  display: block;
  width: 2px;
  height: 100%;
  background: #B3B3B3;
}

.company-history-01 table tr th::after {
  position: absolute;
  top: 10px;
  left: -8px;
  z-index: 5;
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  background: #6bc8ee;
  transform: rotate(45deg);
}

@media screen and (max-width: 760px) {
  .company-history-01 table tr th::after {
    top: 7px;
    left: -6px;
    width: 12px;
    height: 12px;
  }
}

.company-history-01 table tr td {
  position: relative;
  width: 70%;
  padding-bottom: 60px;
  font-size: 21px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .company-history-01 table tr td {
    font-size: 14px;
    font-size: 3.73333vw;
    display: block;
    width: 100%;
    padding-left: 20px;
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 760px) {
  .company-history-01 table tr td::before {
    position: absolute;
    top: 10px;
    left: 0;
    content: "";
    display: block;
    width: 2px;
    height: 100%;
    background: #B3B3B3;
  }
}

.company-history-01 table tr:last-child th::before, .company-history-01 table tr:last-child td::before {
  display: none;
}

.company-history-02 {
  width: 660px;
  margin: 0 auto 40px;
}

@media screen and (max-width: 760px) {
  .company-history-02 {
    width: auto;
  }
}

body.company_csr .bge-contents {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  body.company_csr .bge-contents {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 760px) {
  .company-csr-01 .bgt-box__image-container img {
    width: 40vw;
  }
}

.group-index {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid #403D3C;
}

@media screen and (max-width: 760px) {
  .group-index {
    margin-top: 20px;
    padding-top: 15px;
  }
}

.group-index__name {
  margin-bottom: 6px;
  font-size: 26px;
  font-weight: 400;
}

@media screen and (max-width: 760px) {
  .group-index__name {
    font-size: 17px;
    font-size: 4.53333vw;
  }
}

.group-index__address {
  font-size: 23px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .group-index__address {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.group-index__tel {
  font-size: 23px;
  line-height: 1.5;
}

@media screen and (max-width: 760px) {
  .group-index__tel {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.group-index__link {
  margin: 15px 0 10px;
  text-align: left;
}

.group-index__link > div {
  display: inline-block;
}

.group-index__link a {
  display: inline-block;
  padding: 0 20px 2px;
  border-radius: 20px;
  background: #6bc8ee;
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  appearance: none;
}

@media screen and (max-width: 760px) {
  .group-index__link a {
    font-size: 12px;
    font-size: 3.2vw;
    padding: 4px 16px 5px;
  }
}

.group-index__link a:hover {
  transform: translateY(2px);
}

.group-index__link__url {
  margin-left: 10px;
}

.group-index__link__url a {
  background: #403D3C;
}

.group-index__link .bgt-btn-container {
  text-align: left;
}

.last .group-index {
  margin-bottom: 140px;
  padding-bottom: 30px;
  border-bottom: 1px solid #403D3C;
}

@media screen and (max-width: 760px) {
  .last .group-index {
    margin-bottom: 50px;
    padding-bottom: 15px;
  }
}

.no-url .group-index__link__url {
  display: none;
}

.no-map .group-index__link__map {
  display: none;
}

.recruit-index {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 760px) {
  .recruit-index {
    width: auto;
    width: auto;
    margin-left: 0;
    margin-right: 0;
  }
}

.recruit-index__header {
  margin-bottom: 60px;
  padding: 20px 0;
  border-top: 1px solid #403D3C;
  border-bottom: 1px solid #403D3C;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .recruit-index__header {
    font-size: 15px;
    font-size: 4vw;
    margin-bottom: 30px;
  }
}

.recruit-index__message {
  margin-bottom: 80px;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .recruit-index__message {
    font-size: 14px;
    font-size: 3.73333vw;
    margin-bottom: 50px;
  }
}

.recruit-index__contact__message {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .recruit-index__contact__message {
    font-size: 18px;
    font-size: 4.8vw;
    margin-bottom: 15px;
  }
}

.recruit-index__contact__message a {
  display: inline-block;
  color: #6bc8ee;
}

.recruit-index__contact__message a::before {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: "";
  display: block;
  width: 0;
  height: 1px;
  background: #6bc8ee;
  transition: .5s;
}

.recruit-index__contact__message a:hover {
  transform: translateY(1px);
}

.recruit-index__contact__message a:hover::before {
  left: 0;
  width: 100%;
}

.recruit-index__contact__message a[target=_blank]::after {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 6px;
  background: url(../img/common/icn_target_r.svg) right top no-repeat;
  background-size: 24px 24px;
  transform: translateY(3px);
}

@media screen and (max-width: 760px) {
  .recruit-index__contact__message a[target=_blank]::after {
    width: 16px;
    height: 16px;
    margin-left: 2px;
    background-position: right top;
    background-size: 16px 16px;
    transform: translateY(1px);
  }
}

.recruit-index__box {
  width: 520px;
  margin: 0 auto 80px;
  padding: 30px 0;
  background: #f3fafe;
  text-align: center;
}

@media screen and (max-width: 760px) {
  .recruit-index__box {
    width: auto;
    margin-bottom: 50px;
    padding: 15px 0;
  }
}

.recruit-index__box__number {
  font-size: 38px;
  font-weight: 500;
}

@media screen and (max-width: 760px) {
  .recruit-index__box__number {
    font-size: 26px;
    font-size: 6.93333vw;
  }
}

.recruit-index__box__number span {
  font-size: 30px;
}

@media screen and (max-width: 760px) {
  .recruit-index__box__number span {
    font-size: 20px;
    font-size: 5.33333vw;
  }
}

.recruit-index__box__name {
  font-size: 20px;
  font-weight: 300;
}

@media screen and (max-width: 760px) {
  .recruit-index__box__name {
    font-size: 14px;
    font-size: 3.73333vw;
  }
}

.recruit-index__button {
  margin-bottom: 40px;
}

@media screen and (max-width: 760px) {
  .recruit-index__button {
    padding: 0 3vw;
    margin-bottom: 20px;
  }
}

.recruit-index__button a {
  position: relative;
  display: inline-block;
  padding: 0 90px 0 70px;
  border: 3px solid #6bc8ee;
  background: #fff;
  font-size: 24px;
  line-height: 80px;
  appearance: none;
}

@media screen and (max-width: 760px) {
  .recruit-index__button a {
    width: 100%;
    padding: 0 20px 0 0;
    font-size: 18px;
    line-height: 56px;
  }
}

.recruit-index__button a span {
  position: relative;
  display: inline-block;
  color: #fff;
  z-index: 1;
}

.recruit-index__button a::before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 100%;
  background: #6bc8ee;
  transition: .3s;
}

.recruit-index__button a::after {
  position: absolute;
  right: 20px;
  top: 50%;
  content: "";
  display: block;
  width: 40px;
  height: 15px;
  background: url(../img/common/icn_arrow_bold.svg) 0 0 no-repeat;
  background-size: 40px 15px;
  transform: translateY(-50%);
  transition: .3s;
}

@media screen and (min-width: 761px) {
  .recruit-index__button a:hover span {
    color: #6bc8ee;
  }
}

@media screen and (min-width: 761px) {
  .recruit-index__button a:hover::before {
    left: auto;
    right: 0;
    width: 0;
  }
}

@media screen and (min-width: 761px) {
  .recruit-index__button a:hover::after {
    background-image: url(../img/common/icn_arrow_bold_r.svg);
  }
}
