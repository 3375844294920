body.company_index {
  .bge-contents {
    @include box-wrap();
    @include flex(wrap,space-between);
  }
  .wrapper {
    overflow: hidden;
  }
}

.company-message_bge {
  margin-bottom: 100px;
  @include mq(sp) {
    margin-bottom: 50px;
  }
}
.company-about_bge {
  position: relative;
  margin-bottom: 100px;
  padding: 100px 0;
  background: $sub;
  @include mq(sp) {
    margin-bottom: 50px;
    padding: 50px 0;
  }
  &::before {
    position: absolute;
    top: 0;
    left: -50%;
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background: $sub;
  }
  &::after {
    position: absolute;
    top: 0;
    right: -50%;
    content: '';
    display: block;
    width: 50%;
    height: 100%;
    background: $sub;
  }
}
.company-structure_bge {
  margin-bottom: 100px;
  @include mq(sp) {
    margin-bottom: 50px;
  }
}

.company-index {
  width: 470px;
  margin-bottom: 120px;
  @include mq(sp) {
    width: auto;
    margin-bottom: 50px;
  }
  &__image {
    overflow: hidden;
    img {
      transition: .5s;
    }
    a {
      &:hover {
        img {
          transform: scale(1.1,1.1);
        }
      }
    }
  }
  &__title {
    position: relative;
    z-index: 5;

    a {
      display: block;
      width: 340px;
      margin: -50px auto 0;
      padding: 18px 0;
      box-shadow: 2px 2px 5px rgba(0,0,0,.4);
      background: #fff;
      color: $hard;
      text-align: center;
      @include mq(sp) {
        width: 250px;
        margin: -40px auto 0;
        padding: 13px 0;
      }
      &:hover {
        transform: translate(2px,2px);
        box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      }
    }

    h2 {
      font-size: 23px;
      font-weight: 500;
      line-height: 1.5;
      @include mq(sp) {
        @include fz_vw(19);
      }
    }
    h3 {
      margin-bottom: 8px;
      font-size: 15px;
      font-weight: 300;
      line-height: 1.5;
      @include mq(sp) {
        @include fz_vw(13);
      }
    }
    p.more {
      position: relative;
      display: inline-block;
      font-size: 15px;
      font-weight: 400;
      @include mq(sp) {
        @include fz_vw(13);
      }
      &::before {
        position: absolute;
        top: 50%;
        right: -40px;
        content: "";
        display: block;
        width: 28px;
        height: 1px;
        background: $hard;
        transition: .3s;
        @include mq(sp) {
          width: 20px;
        }
      }
      &::after {
        position: absolute;
        top: 50%;
        right: -36px;
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-right: 1px solid $hard;
        border-top: 1px solid $hard;
        transform: rotate(45deg) translateY(-6px);
        transition: .3s;
        @include mq(sp) {
          width: 7px;
          height: 7px;
          transform: rotate(45deg) translateY(-4px);
        }
      }
    }

    a:hover {
      p.more::before {
        width: 0;
        right: -100px
      }
      p.more::after {
        right: -100px;
      }
    }
  }
}

.company-about-01 {
  font-size: 42px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(22);
  }
}

.company-logo {
  width: 196px;
  height: 234px;
  margin: 0 auto 60px;
  @include mq(sp) {
    width: 108px;
    height: 132px;
    margin-bottom: 30px;
  }
  img {
    width: 196px;
    height: 234px;
    @include mq(sp) {
      width: 108px;
      height: 132px;
    }
  }

  &__image01 {
    width: 196px;
    height: 234px;
    @include mq(sp) {
      width: 108px;
      height: 132px;
    }
    svg {
      width: 196px;
      height: 234px;
      @include mq(sp) {
        width: 108px;
        height: 132px;
      }
    }
    .la {
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      fill: transparent;
      animation: logo_anime0 3s ease-in 1s forwards;
    }
  }
  &__image02 {
    margin-top: -126px;
    margin-left: 32px;
    width: 126px;
    height: 19px;
    @include mq(sp) {
      width: 70px;
      height: 11px;
      margin-top: -75px;
      margin-left: 18px;
    }
    .lt1 {
      paint-order: stroke;
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      fill: transparent;
      animation: logo_anime1 2s ease-in 2s forwards;
    }
    svg {
      width: 126px;
      height: 19px;
      overflow: visible;
      @include mq(sp) {
        width: 70px;
        height: 11px;
      }
    }
  }
  &__image03 {
    margin-left: 30px;
    width: 130px;
    height: 10px;
    @include mq(sp) {
      width: 73px;
      height: 6px;
      margin-left: 17px;
    }
    .lt2 {
      paint-order: stroke;
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      fill: transparent;
      animation: logo_anime2 2s ease-in 3s forwards;
    }
    svg {
      overflow: visible;
      width: 130px;
      height: 10px;
      @include mq(sp) {
        width: 73px;
        height: 6px;
      }
    }
  }
}

.company-about-02 {
  @include box-wrap();
  margin-bottom: 120px;
  @include mq(sp) {
    margin-bottom: 75px;
  }
  table {
    tr {
      border-top: 1px solid $main;
      border-bottom: 1px solid $main;
      th {
        width: 20%;
        padding: 16px 30px;
        color: $hard;
        font-size: 21px;
        font-weight: 500;
        text-align: left;
        vertical-align: top;
        @include mq(sp) {
          @include fz_vw(14);
          display: block;
          width: 100%;
          padding: 10px 0 0;
        }
      }
      td {
        width: 80%;
        padding: 16px 30px;
        font-size: 21px;
        font-weight: 300;
        @include mq(sp) {
          @include fz_vw(14);
          display: block;
          width: 100%;
          padding: 0 0 10px;
        }
      }
    }
  }
}

.company-about-03 {
  width: 660px;
  margin: 0 auto 40px;
  @include mq(sp) {
    width: auto;
  }
}


.company-message-01 {
  @include box-wrap();
  width: 316px;
  margin: 0 auto 120px;
  @include mq(sp) {
    width: 106px;
    margin-bottom: 40px;
  }
}

.company-message-02 {
  @include box-wrap();
  font-size: 38px;
  font-weight: 400;
  line-height: 1.5;
  @include mq(sp) {
    @include fz_vw(21);
  }
}

.company-message-03 {
  @include box-wrap();
  margin-bottom: 100px;
  font-size: 21px;
  @include mq(sp) {
    @include fz_vw(14);
    margin-bottom: 30px;
  }
}

.company-message-04 {
  @include box-wrap();
  margin-bottom: 120px;
  text-align: right;
  font-family: $font-seurat;
  font-weight: 600;
  @include mq(sp) {
    margin-bottom: 75px;
  }
  img {
    display: inline !important;
    margin: 0 !important;
    @include mq(sp) {
      width: 210px;
    }
  }
  span {
    display: inline-block;
    margin-left: 20px;
    font-size: 40px;
    letter-spacing: 5px;
    vertical-align: middle;
    @include mq(sp) {
      font-size: 16px;
      letter-spacing: 2px;
    }
  }
}

.company-message-05 {
  width: 660px;
  margin: 0 auto 40px;
  @include mq(sp) {
    width: auto;
  }
}

.company-history-01 {
  width: 800px;
  margin: 0 auto 120px;
  @include mq(sp) {
    width: auto;
    margin-bottom: 40px;
  }

  table {
    tr {
      th {
        position: relative;
        width: 30%;
        padding: 0 40px 60px 40px;
        font-size: 21px;
        font-weight: 300;
        text-align: left;
        vertical-align: top;
        @include mq(sp) {
          @include fz_vw(14);
          display: block;
          width: 100%;
          padding: 0 0 0 20px;
        }
        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          content: "";
          display: block;
          width: 2px;
          height: 100%;
          background: #B3B3B3;
        }
        &::after {
          position: absolute;
          top: 10px;
          left: -8px;
          z-index: 5;
          content: "";
          display: block;
          width: 17px;
          height: 17px;
          background: $main;
          transform: rotate(45deg);
          @include mq(sp) {
            top: 7px;
            left: -6px;
            width: 12px;
            height: 12px;
          }
        }
      }
      td {
        position: relative;
        width: 70%;
        padding-bottom: 60px;
        font-size: 21px;
        font-weight: 300;
        @include mq(sp) {
          @include fz_vw(14);
          display: block;
          width: 100%;
          padding-left: 20px;
          padding-bottom: 30px;
        }
        &::before {
          @include mq(sp) {
            position: absolute;
            top: 10px;
            left: 0;
            content: "";
            display: block;
            width: 2px;
            height: 100%;
            background: #B3B3B3;
          }
        }
      }
      &:last-child {
        th, td {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}

.company-history-02 {
  width: 660px;
  margin: 0 auto 40px;
  @include mq(sp) {
    width: auto;
  }
}

body.company_csr {
  .bge-contents {
    @include box-wrap();
  }
}

.company-csr-01 {
  .bgt-box__image-container {
    img {
      @include mq(sp) {
        width: 40vw;
      }
    }
  }
}