.required {
  display: inline-block;
  min-width: 48px;
  margin-left: 10px;
  background: $main;
  color: #fff;
  font-size: 14px;
  text-align: center;
  transform: translateY(-1px);
  white-space: nowrap;
  @include mq(sp) {
    @include fz_vw(11);
    min-width: 40px;
    line-height: 1.6;
  }
}
.normal {
  display: inline-block;
  min-width: 48px;
  margin-left: 10px;
  background: $gray;
  color: #fff;
  font-size: 14px;
  text-align: center;
  transform: translateY(-1px);
  white-space: nowrap;
  @include mq(sp) {
    @include fz_vw(11);
    min-width: 40px;
    line-height: 1.6;
  }
}
.submit {
  text-align: center;
  @include mq(sp) {
    margin-left: 4%;
    margin-right: 4%;
  }
  button, input[type="submit"], input[type="reset"] {
    position: relative;
    background: $main;
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    line-height: 60px;
    cursor: pointer;
    @include mq(sp) {
      @include fz_vw(19);
      width: 100%;
      line-height: 50px;
    }
  }
  input[type="submit"] {
    width: 240px;
  }
  input[type="reset"] {
    width: 240px;
  }
}
#BtnMessageConfirm {
  width: 380px;
  @include mq(sp) {
    width: 100%;
  }
}
#BtnMessageBack {
  background: #BCBCBD;
  @include mq(sp) {
    margin-bottom: 5px;
  }
}

.checkbox {
  display: inline-block;
}

/* reset */

button,
option,
textarea,
select,
input[type="button"],
input[type="submit"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="text"],
input[type="select"],
input[type="option"],
input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: none;
    outline: 0;
    margin: 0;
    background: #fff;
    box-sizing: border-box;
    font-size: 20px;
    @include mq(sp) {
      @include fz_vw(16);
    }
}
/*
select,
input[type="select"] {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    border: 1px solid #ccc;
    outline: 0;
    margin: 5px 0;
    background: #fff;
    box-sizing: border-box;
    font-size: 20px;
    @include mq(sp) {
      @include fz_vw(12);
    }
}*/
/* デフォルトのradio、checkboxは非表示 */

input[type=radio],
input[type=checkbox] {
    display: none;
    //margin-right:10px;
    //font-size:16px;
}

/* for firefox */

select {
    text-indent: 0.01px;
    text-overflow: '';
}




textarea,
input[type="number"],
input[type="text"] {
    padding: 15px;
    border: 1px solid $black;
    margin-top:5px;
    margin-bottom:5px;
    @include mq(sp) {
      padding: 10px 8px;
    }
}
input.form_s {
  width: 10%;
  @include mq(sp) {
    width: 20%;
  }
}
input.form_m {
  width: 70%;
  @include mq(sp) {
    width: 100%;
  }
}
input.form_l {
  width: 90%;
  @include mq(sp) {
    width: 100%;
  }
}
textarea {
  width: 90%;
  height: 120px; /* お好みの高さに */
  padding: 10px 10px;
  @include mq(sp) {
    width: 100%;
  }
}

/* フォーカス時の色変更 */

textarea:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="text"]:focus {
    border: 2px solid $main;
}
/* for ie10 ie11 ie系のプルダウンの矢印を消す ie9は非対応 */

select::-ms-expand {
    display: none;
}

/* ラジオボタン */

input[type=radio].form-radio+label {
    position: relative;
    display: inline-block;
    font-size: 21px;
    cursor: pointer;
    padding: 9px 5px 8px 28px;
    margin-right: 10px;
    @include mq(sp) {
      @include fz_vw(17);
    }
}

input[type=radio].form-radio+label::before,
input[type=radio].form-radio+label::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
}

input[type=radio].form-radio+label::before {
    width: 22px;
    height: 22px;
    margin-top: -10px;
    background: #FFF;
    border: 1px solid #ccc;
}

input[type=radio].form-radio+label::after {
    left: 6px;
    width: 10px;
    height: 10px;
    margin-top: -4px;
    background: #f4f4f4;
}

input[type=radio]:checked.form-radio+label::after {
    background: $main;
}

/* チェックボックス */

.form-checkbox label {
    position: relative;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin-right: 10px;
    padding: 6px 5px 8px 50px;
    @include mq(sp) {
      @include fz_vw(12);
      padding-left: 30px;
    }
}

.form-checkbox label::before,
.form-checkbox label::after {
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
}

.form-checkbox label::before {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background: #FFF;
    border: 1px solid $main;
    @include mq(sp) {
      width: 24px;
      height: 24px;
      margin-top: -13px;
    }
}

.form-checkbox input[type=checkbox]+label::after {
    width: 24px;
    height: 24px;
    top: 2px;
}

.form-checkbox input[type=checkbox]:checked+label::after {
    position: absolute;
    left: 4px;
    top: 8px;
    content: '';
    display: block;
    width: 28px;
    height: 18px;
    border-top: 6px solid $main;
    border-right: 6px solid $main;
    transform: rotate(135deg);
    @include mq(sp) {
      width: 22px;
      height: 15px;
      border-width: 5px;
    }
}

.form-select {
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-top:5px;
    margin-bottom:5px;
    @include mq(sp) {
      line-height: 30px;
    }
}
.form-select:before {
    position: absolute;
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    background: $main;
    top: 50%;
    right: 8px;
    margin-top: -8px;
    pointer-events: none;
    z-index: 10;
}
.form-select:after {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 0;
    /* ここで下向き矢印指定　今回はCSSで */
    border-top: 6px solid transparent;
    border-left: 4px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #fff;
    top: 50%;
    right: 11px;
    margin-top: -3px;
    pointer-events: none;/* クリック出来るよう */
    z-index: 11;
}

.form-select select {
    padding: 15px 50px 15px 20px;
    border: 1px solid $black;
    position: relative;
    @include mq(sp) {
      @include fz_vw(16);
      height: auto;
      padding: 10px 50px 10px 8px;
    }
    option {
      &:first-child {
        color: $main;
      }
    }
}

/* ie9対策 */

.form-select select:not(:target) {
    width: 120% \9;
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */

::-webkit-input-placeholder {
    color: $main;
    font-family: $font-noto;
    font-weight: 100;
}

/* Firefox 18- */

:-moz-placeholder {
    color: $main;
    opacity: 1;
    font-family: $font-noto;
    font-weight: 100;
}

/* Firefox 19+ */

::-moz-placeholder {
    color: $main;
    opacity: 1;
    font-family: $font-noto;
    font-weight: 100;
}

/* IE 10+ */

:-ms-input-placeholder {
    color: $main;
    font-family: $font-noto;
    font-weight: 100;
}
