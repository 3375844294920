.blog-index {
  @include flex(wrap);
  width: 80%;
  margin: 0 auto;
  @include mq(wpc) {
    width: auto;
  }
  @include mq(sp) {
    width: auto;
  }
  .post {
    width: 32%;
    margin-right: 2%;
    @include mq(sp) {
      width: 100%;
      margin-right: 0;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    a {
      position: relative;
      display: block;
      padding: 20px 2%;
      font-size: 16px;
      line-height: 1.4;
      @include mq(sp) {
        @include fz_vw(13);
        padding: 16px 0;
      }
      &:hover {
        .image {
          img {
            transform: scale(1);
          }
        }
        .title {
          text-decoration: underline;
        }
      }
      .image {
        display: block;
        margin-bottom: 20px;
        overflow: hidden;
        img {
          width: 100%;
          height: 220px;
          object-fit: cover;
          transition: .5s;
          transform: scale(1.1);
        }
      }
      .date {
        display: inline-block;
        margin-right: 10px;
        color: $gray;
        font-size: 16px;
        font-weight: 400;
        @include mq(sp) {
          font-size: 14px;
        }
      }
      .category {
        display: inline-block;
        padding: 1px 14px 2px;
        background: $gray;
        color: #fff;
        font-size: 12px;
        text-align: center;
        transform: translateY(-2px);
        @include mq(sp) {
          font-size: 12px;
        }
      }
      .title {
        display: block;
        padding-top: 10px;
        font-weight: 300;
        transition: .5s;
        @include mq(sp) {
          @include fz_vw(14);
          width: 100%;
          padding-top: 5px;
        }
      }
    }
  }
}

.blog-single {
  width: 80%;
  margin: 0 auto;
  font-size: 18px;
  line-height: 2.2;
  @include mq(wpc) {
    width: auto;
  }
  @include mq(sp) {
    width: auto;
    font-size: 14px;
    line-height: 1.8;
  }
  a {
    &:hover {
      img {
        opacity: 1 !important;
      }
    }
  }
  .post {
    margin-bottom: 90px;
    @include mq(sp) {
      margin-bottom: 40px;
    }
  }
  .meta {
    margin: 20px 0;
    text-align: right;
    @include mq(sp) {
      margin: 10px 0;
    }
    .date {
      font-size: 20px;
      @include mq(sp) {
        font-size: 14px;
      }
    }
    .category {
      margin-right: 10px;
      font-size: 20px;
      @include mq(sp) {
        font-size: 14px;
      }
      a {
        color: $main;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .eyecatch {
    text-align: center;
    margin-bottom: 60px;
    @include mq(sp) {
      margin-bottom: 20px;
    }
  }
  .post-discription {
    margin-bottom: 30px;
  }
  .post-main {
    a {
      color: $main;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}