
body.service_index, body.service_3pl, body.service_shipping, body.service_warehouse {
  .main {
    @include box-wrap();
    .bge-contents {
      & > div {
        @include mq(sp) {
          margin-left: 5vw;
          margin-right: 5vw;
        }
      }
      .bgb-service-header {
        @include mq(sp) {
          margin-left: 0;
          margin-right: 0;
        }
      }
      .service-flow {
        @include mq(sp) {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}

.service-index {
  @include flex();
  position: relative;
  margin-bottom: 120px;
  padding-bottom: 30px;
  @include mq(sp) {
    margin-bottom: 50px;
    padding-bottom: 0;
  }
  &__image {
    position: relative;
    z-index: 8;
    width: 660px;
    margin-right: -90px;
    @include mq(sp) {
      width: auto;
      margin-right: 0;
    }
  }
  &__title {
    position: relative;
    align-self: center;
    z-index: 9;
    @include mq(sp) {
      width: 80vw;
      margin: 0 auto;
      transform: translateY(-30px);
    }
    a {
      display: block;
      width: 420px;
      padding: 30px;
      color: #fff;
      background: $main;
      @include mq(sp) {
        width: 80vw;
        padding: 20px;
        transform: translate(0);
      }
      &:hover {
        @include mq(pc) {
          color: #fff;
          transform: translate(0,3px);
        }
        .top-service__box__text__button span::before {
          @include mq(pc) {
            right: 10px;
            width: 40px;
          }
        }
        .top-service__box__text__button span::after {
          @include mq(pc) {
            right: 10px;
          }
        }
      }
    }
    h2 {
      margin-bottom: 20px;
      padding-bottom: 15px;
      border-bottom: 1px solid #fff;
      font-size: 32px;
      font-weight: 300;
      line-height: 1;
      @include mq(sp) {
        @include fz_vw(23);
        margin-bottom: 10px;
        padding-bottom: 8px;
      }
    }
    p {
      margin-bottom: 30px;
      font-size: 18px;
      @include mq(sp) {
        @include fz_vw(13);
        margin-bottom: 15px;
      }
    }
    .more {
      margin-bottom: 0;
      text-align: right;
      span {
        position: relative;
        display: inline-block;
        padding: 4px 60px 5px 30px;
        border: 1px solid #fff;
        font-size: 15px;
        letter-spacing: 3px;
        @include mq(sp) {
          @include fz_vw(13);
        }
        &::before {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "";
          display: block;
          width: 30px;
          height: 1px;
          background: #fff;
          transition: .3s;
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 20px;
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border-right: 1px solid #fff;
          border-top: 1px solid #fff;
          transform: translateY(-4px) rotate(45deg);
          transition: .3s;
          @include mq(sp) {
            width: 7px;
            transform: translateY(-4px) rotate(45deg);
          }
        }
      }
    }
  }
  &.first {
    padding: 100px 0 30px;
    @include mq(sp) {
      padding: 50px 0 0;
    }
  }
}
.reverse {
  .service-index {
    flex-direction: row-reverse;
    .service-index__image {
      margin-right: 0;
      margin-left: -90px;
      @include mq(sp) {
        margin-left: 0;
      }
    }
  }
}


.service-copy {
  font-size: 40px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(21);
  }
}

.service-header {
  @include box-wrap();
  position: relative;
  margin-bottom: 100px;
  padding-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 80px;
    padding-bottom: 0;
  }
  &__image {
  }
  &__text {
    position: relative;
    z-index: 8;
    width: 780px;
    margin: -130px auto 0;
    padding: 40px 80px;
    background: $main;
    color: #fff;
    font-size: 20px;
    font-weight: 300;
    @include mq(sp) {
      @include fz_vw(13);
      width: auto;
      margin: -50px 5vw 0;
      padding: 20px 5vw;
    }
  }
}

.service-3pl-01 {
  img {
    @include mq(sp) {
      width: 60vw;
    }
  }
}
