.hero-fadeIn-01 {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top left;
  transition: .5s ease 1s;
  &.show {
    opacity: 1;
    transform: scaleY(1);
  }
}
.hero-fadeIn-02 {
  opacity: 0;
  transform: translateY(20px);
  transition: 2s ease 3s;
  &.show {
    opacity: 1;
    transform: translateY(0px);
  }
}

.title-fadeLeftIn {
  a {
    opacity: 0;
    transform: translateX(-200px);
    transition: .5s ease;
    .en {
      opacity: 0;
      transform: translateX(-200px);
      transition: .5s ease .5s;
    }
    .ja {
      opacity: 0;
      transform: translateX(-200px);
      transition: .5s ease .7s;
    }
  }
  &.show {
    a, .en, .ja {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}
.title-fadeLeftIn-nl {
  .en {
    opacity: 0;
    transform: translateX(-200px);
    transition: .5s ease;
  }
  .ja {
    opacity: 0;
    transform: translateX(-200px);
    transition: .5s ease .2s;
  }
  &.show {
    .en, .ja {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}
.title-fadeIn-nl {
  .en {
    opacity: 0;
    transform: translateY(20px);
    transition: .5s ease;
  }
  .ja {
    opacity: 0;
    transform: translateY(20px);
    transition: .5s ease .2s;
  }
  &.show {
    .en, .ja {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.scroll-fadeIn {
  opacity: 0;
  transform: translateY(20px);
  transition: 1s ease;
  &.show {
    opacity: 1;
    transform: translateY(0px);
  }
}
.scroll-fadeTopIn {
  opacity: 0;
  transform: translateY(-50px);
  transition: 1s ease;
  &.show {
    opacity: 1;
    transform: translateY(0px);
  }
}
.scroll-fadeLeftIn {
  opacity: 0;
  transform: translateX(-200px);
  transition: .5s ease;
  &.show {
    opacity: 1;
    transform: translateX(0px);
    &.top-service__box__text {
      @include mq(sp) {
        transform: translateY(-30px);
      }
    }
  }
}
.scroll-fadeRightIn {
  opacity: 0;
  transform: translateX(400px);
  transition: .5s ease;
  &.show {
    opacity: 1;
    transform: translateX(0px);
    &.top-service__box__text {
      @include mq(sp) {
        transform: translateY(-30px);
      }
    }
  }
}

.scroll-svg {
  opacity: 0;
  &.show {
    opacity: 1;
    .na {
      stroke-dasharray: 2000;
      stroke-dashoffset: 0;
      stroke-width: .5;
      stroke: $acc !important;
      animation: na_anime 1s ease-in 0s forwards;
    }
  }
}

.scroll-imageIn {
  overflow: hidden;
  a::after {
    transform: translateX(calc(-100% - 10px));
    transition: .5s ease;
  }
  a > div {
    transform: translateX(-100%);
    transition: .5s ease .3s;
  }
  &.show {
    a::after, a > div {
      transform: translateX(0px);
    }
  }
  &.d1 {
    a::after {
      @include mq(pc) {
        transition-delay: .5s;
      }
    }
    a > div {
      @include mq(pc) {
        transition-delay: .8s;
      }
    }
  }
}

