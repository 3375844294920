@charset "utf-8";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255,255,255,.8);
  z-index: 10000;
}

/* ローディングのアニメーション部分のCSS (https://projects.lukehaas.me/css-loaders/) */
/* SQUARE */
.loader-animation,
.loader-animation:before,
.loader-animation:after {
  background:$main;
  -webkit-animation:load1 1s infinite ease-in-out;
  animation:load1 1s infinite ease-in-out;
  width:1em;
  height:4em;
}
.loader-animation {
  height:10px;
  left:50%;
  margin:-5px 0 0 -5px;
  position:absolute;
  top:40%;
  width:10px;
  color:$main;
  text-indent:-9999em;
  font-size:10px;
  -webkit-transform:translateZ(0);
  -ms-transform:translateZ(0);
  transform:translateZ(0);
  -webkit-animation-delay:-0.16s;
  animation-delay:-0.16s;
}
.loader-animation:before,
.loader-animation:after {
  position:absolute;
  top:0;
  content:'';
}
.loader-animation:before {
  left:-1.5em;
  -webkit-animation-delay:-0.32s;
  animation-delay:-0.32s;
}
.loader-animation:after {
  left:1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow:0 0;
    height:4em;
  }
  40% {
    box-shadow:0 -2em;
    height:5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow:0 0;
    height:4em;
  }
  40% {
    box-shadow:0 -2em;
    height:5em;
  }
}
/* CIRCLE */
.loader-circle {
  position:absolute;
  top:40%;
  color: $main;
  font-size: 20px;
  margin: 100px auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}


.loader-image {
  position: absolute;
  left: 50%;
  top: 40%;
  display: block;
  width: 53px;
  height: 64px;
  background: url(../img/common/logo_arrow.svg) no-repeat;
  background-size: 53px 64px;
  transform: translate(-50%,-50%);
  animation: 1s ease .05s infinite circle
}

.loader-logo {
  position: relative;
  display: block;
  left: 50%;
  top: 40%;
  width: 150px;
  height: 30px;
  margin-left: -70px;
  margin-top: 70px;
  background: url(../img/common/logo.svg) no-repeat;
  background-size: 150px 20px;
}

.js-scroll-fade {
  position: relative;
  opacity: 0;
  transform: translate(0, 40px);
  transition: all 1s cubic-bezier(0.155, 0.910, 0.695, 0.950);
  &.is-animation {
      opacity: 1;
      transform: translate(0, 0);
  }
}