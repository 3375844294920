.sp-btn {
  position: fixed;
  display: none;
  top: 0;
  right: 0;
  z-index: 1900;
  width: 60px;
  height: 60px;
  @include mq(nav) {
    display: block;
  }
}
.panel-btn {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 60px;
  text-decoration: none;
  text-align: center;
  @include box-sizing(border-box);
}
.panel-btn .close {
  background: transparent;
}
.panel-btn .close::before, .panel-btn .close::after {
  margin-top: -2px;
  left: -6px;
}
.panel-btn .close::before {
  width: 36px;
  -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
}
.panel-btn .close::after {
  width: 36px;
  -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
}
.panel-btn-icon {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 27px;
  height: 3px;
  border-radius: 3px;
  background: $hard;
  -webkit-transition: .2s;
  transition: .2s;
  transform: translate(calc(-50% + 5px),-50%);
}
.panel-btn-icon::before, .panel-btn-icon::after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  height: 3px;
  border-radius: 3px;
  background: $hard;
  -webkit-transition: .3s;
  transition: .3s;
}
.panel-btn-icon::before {
  width: 36px;
  margin-top: -13px;
  transform: translateX(-9px);
}
.panel-btn-icon::after {
  width: 18px;
  margin-top: 9px;
  transform: translateX(9px);
}
