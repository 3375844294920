$black: #403D3C;
$gray: #686C67;
$light-gray: #F3F2EE;
$acc: #D00014;
$hard: #2a3293;
$main: #6bc8ee;
$sub: #f3fafe;

$base-lh: 1.8;
$base-fs: 18px;
$sp-fs: 13px;
$reset-fs: 13;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

$mincho: "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "ＭＳ 明朝", serif;
$font-yu: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
$font-base: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, sans-serif;
$marugo: "ヒラギノ丸ゴ Pro W4","Hiragino Maru Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

$font-noto: 'Noto Sans JP', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$font-seurat: fot-seurat-pron,sans-serif; /* font-weight: 600 */
