.pagination {
  margin: 60px 0 100px;
  text-align: center;
}
.pagination span.current,
.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a,
.pagination span.number a {
  margin: 0 4px;
  padding: 7px 16px;
  font-size: 20px;
  border-radius: 50%;
  @include mq(sp) {
    margin: 0 1px;
    padding: 6px 11px;
    font-size: 12px;
  }
}
.pagination span.number a {
  background: #CACACA;
  color: #fff;
  text-decoration: none !important;
}
.pagination span.disabled,
.pagination span.first a,
.pagination span.last a,
.pagination span.prev a,
.pagination span.next a {
  padding: 12px 12px;
  color: $main;
}
.pagination span.prev a,
.pagination span.next a {
  text-decoration: underline;
}
.pagination span.current,
.pagination span.number a:hover {
  background: $main;
  color: #fff;
}
.pagination a:active {
  background: $main;
}
.pagination span.first a,
.pagination span.last a {
  position: relative;
}
.pagination span.first a::before,
.pagination span.last a::after {
  position: absolute;
  top: 50%;
  content: "";
  display: block;
  width: 1px;
  height: 14px;
  margin-top: -6px;
  background: #000;
  @include mq(sp) {
    height: 11px;
    margin-top: -5px;
  }
}
.pagination span.first a::before {
  left: 13px;
  @include mq(sp) {
    left: 12px;
  }
}
.pagination span.last a::after {
  right: 13px;
  @include mq(sp) {
    right: 12px;
  }
}

.case {
  .pagination span.disabled,
  .pagination span.prev a,
  .pagination span.next a {
    border: 0;
  }

  .pagination span.prev a,
  .pagination span.next a,
  .pagination span.number a {
    color: $black;
  }

  .pagination span.prev a:hover,
  .pagination span.next a:hover {
    background: none;
    color: $black;
    text-decoration: underline !important;
  }

  .pagination span.current,
  .pagination span a:hover {
    background: #666;
    color: #fff;
  }
  .pagination a:active {
    background: #999;
  }
}

.contents-navi {
  @include base-wrap--sp();
  margin-top: 40px;
  margin-bottom: 120px;
  text-align: center;
  @include mq(sp) {
    margin-top: 20px;
    margin-bottom: 80px;
  }
  a {
    display: inline-block;
    width: 226px;
    border: 1px solid #ddd;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    @include mq(sp) {
      @include fz_vw(11);
      width: 45.538%;
      line-height: 31px;
    }
    &:hover {
      @include mq(pc) {
        background: #fff;
        color: $black;
        font-weight: bold;
        text-decoration: none;
      }
    }
    &.prev-link,
    &.next-link {
      background: #ddd;
    }
    &:hover.prev-link,
    &:hover.next-link {
      background: #eee;
    }
  }
}

.post-navi {
  text-align: center;
  a {
    display: inline-block;
    padding: 5px 80px;
    border-radius: 30px;
    background: $main;
    color: #fff;
    font-size: 21px;
    @include mq(sp) {
      padding: 5px 40px;
      font-size: 17px;
    }
    &:hover {
      color: #fff;
    }
  }
}