.group-index {
  margin-top: 40px;
  padding-top: 30px;
  border-top: 1px solid $black;
  @include mq(sp) {
    margin-top: 20px;
    padding-top: 15px;
  }

  &__name {
    margin-bottom: 6px;
    font-size: 26px;
    font-weight: 400;
    @include mq(sp) {
      @include fz_vw(17);
    }
  }
  &__address {
    font-size: 23px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
  &__tel {
    font-size: 23px;
    line-height: 1.5;
    @include mq(sp) {
      @include fz_vw(14);
    }
  }
  &__link {
    margin: 15px 0 10px;
    text-align: left;
    & > div {
      display: inline-block;
    }
    a {
      display: inline-block;
      padding: 0 20px 2px;
      border-radius: 20px;
      background: $main;
      color: #fff;
      font-size: 19px;
      font-weight: 500;
      appearance: none;
      @include mq(sp) {
        @include fz_vw(12);
        padding: 4px 16px 5px;
      }
      &:hover {
        transform: translateY(2px);
      }

    }
    &__url {
      margin-left: 10px;
      a {
        background: $black;
      }
    }
    .bgt-btn-container {
      text-align: left;
    }
  }
}
.last {
  .group-index {
    margin-bottom: 140px;
    padding-bottom: 30px;
    border-bottom: 1px solid $black;
    @include mq(sp) {
      margin-bottom: 50px;
      padding-bottom: 15px;
    }
  }
}
.no-url {
  .group-index__link__url {
    display: none;
  }
}
.no-map {
  .group-index__link__map {
    display: none;
  }
}