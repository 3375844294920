.bge-contents {
  .bge-title-h2 {
    padding: 10px 20px;
    background: $light-gray;
    font-size: 23px;
    font-weight: 500;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 15px;
    }
  }
  .bge-title-h3 {
    padding-bottom: 8px;
    border-bottom: 1px solid $gray;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    @include mq(sp) {
      font-size: 14px;
    }
  }
}

.title-page__01 {
  .bge-title-h2, .bge-title-h3 {
    background: none;
    border-bottom: 4px solid $main;
    font-size: 32px;
    font-weight: 500;
    @include mq(sp) {
      border-bottom: 2px solid $main;
      font-size: 18px;
    }
  }
}
.title-page__02 {
  .bge-title-h2, .bge-title-h3 {
    background: none;
    color: $main;
    font-size: 34px;
    font-weight: 500;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(19);
    }
  }
}
.title-page__03 {
  .bge-title-h2, .bge-title-h3 {
    margin-bottom: 50px;
    padding: 4px 0;
    background: $black;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(18);
      margin-bottom: 25px;
    }
  }
}
.title-page__04 {
  .bge-title-h2, .bge-title-h3 {
    margin-bottom: 50px;
    padding: 4px 0;
    background: $main;
    color: #fff;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(18);
      margin-bottom: 25px;
    }
  }
}
.title-page__05 {
  .bge-title-h2, .bge-title-h3 {
    margin-bottom: 50px;
    padding: 0;
    background: none;
    color: $black;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(23);
      margin-bottom: 25px;
    }
  }
}
.title-page__06 {
  margin: 160px 0 90px;
  @include mq(sp) {
    margin: 100px 0 50px;
  }
  .bgt-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 0 auto;
    background: $main;
    transform: rotate(45deg);
    @include mq(sp) {
      width: 120px;
      height: 120px;
    }
    &::after {
      position: absolute;
      right: 40px;
      bottom: 40px;
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
      transform: rotate(90deg);
      @include mq(sp) {
        right: 20px;
        bottom: 20px;
        width: 14px;
        height: 14px;
      }
    }
  }
  .bge-title-h2, .bge-title-h3 {
    display: block;
    margin-left: 12px;
    padding: 30px 0 0;
    border: 0;
    color: #fff;
    font-size: 33px;
    font-weight: 300;
    transform: rotate(-45deg);
    @include mq(sp) {
      @include fz_vw(20);
      margin-left: 4px;
      padding: 16px 0 0;
    }
  }
}
.title-page__07 {
  margin-bottom: 40px !important;
  .bge-title-h2, .bge-title-h3, .bge-title-h4 {
    margin-bottom: 40px;
    padding: 10px 0;
    border-top: 1px solid $main;
    border-bottom: 1px solid $main;
    background: none;
    color: $main;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.5;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(20);
      margin-bottom: 25px;
      padding: 5px 0;
    }
  }
}

[data-bgb].bgb-opt--mb-maxlarge {
    margin: 0 0 100px;
    @include mq(sp) {
      margin: 0 0 50px;
    }
}
.bgb-opt--mb-large {
  margin-bottom: 180px !important;
  @include mq(sp) {
    margin-bottom: 4em !important;
  }
}
[data-bgb="text-image1"] .bgt-grid--first {
  @include mq(sp) {
  }
}

[data-bgb="text-image1"] {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  @include mq(sp) {
    flex-wrap: wrap;
  }
  &::before, &::after {
    display: none;
  }
  .bgt-grid {
    float: none;
    align-self: center;
  }
  .bgt-grid--first {
    padding-left: 0;
    padding-right: 20px;
    @include mq(sp) {
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
  .bgt-grid--last {
    padding-left: 20px;
    padding-right: 0;
    @include mq(sp) {
      margin-bottom: 10px;
      padding-left: 0;
    }
  }
}

[data-bgb="text-image2"] {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  justify-content: space-between;
  @include mq(sp) {
    flex-wrap: wrap;
  }
  &::before, &::after {
    display: none;
  }
  .bgt-grid {
    float: none;
    align-self: center;
  }
  .bgt-grid--first {
    padding-left: 20px;
    padding-right: 0;
    @include mq(sp) {
      margin-bottom: 10px;
      padding-left: 0;
    }
  }
  .bgt-grid--last {
    padding-left: 0;
    padding-right: 20px;
    @include mq(sp) {
      margin-bottom: 10px;
      padding-right: 0;
    }
  }
}

.reverse-sp {
  &[data-bgb="text-image1"], &[data-bgb="text-image2"] {
    @include mq(sp) {
      flex-direction: column-reverse;
    }
  }
}

.bge-contents.bge_contents.bge_content {
  .image-sp-change {
    &__pc {
      position: relative;
      padding-bottom: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        content: "　パソコン版　";
        background: #ff0;
      }
    }
    &__sp {
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        content: "　スマホ版　";
        background: #ff0;
      }
    }
  }
}
.wrapper .main .contents .bge-contents {
  .image-sp-change {
    &__pc {
      @include mq(sp) {
        display: none;
      }
    }
    &__sp {
      display: none;
      @include mq(sp) {
        display: block;
      }
    }
  }
}
