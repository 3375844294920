.table-form {
  tr {
    border-bottom: 1px solid #d2d2d2;
  }
  tr:last-child {
    border-bottom: 0;
  }
  th, td {
    padding: 10px 25px;
    @include mq(sp) {
      display: block;
      width: 100%;
    }
  }
  th {
    width: 230px;
    padding-right: 15px;
    font-weight: normal;
    text-align: left;
    @include mq(sp) {
      padding: 20px 0 0;
      padding-bottom: 0;
    }
  }
  td {
    padding-left: 0;
    @include mq(sp) {
      padding: 10px 0 20px;
    }
  }
}
.table-scroll {
  overflow: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: #F1F1F1;
  }
  &::-webkit-scrollbar-thumb {
    background: #BCBCBC;
  }
}