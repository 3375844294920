.title-top__01 {
  font-family: $font-roboto;
  font-size: 33px;
  font-weight: 500;
  letter-spacing: 4px;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(19);
  }
  a {
    color: $black;
    &:hover {
      color: $main;
    }
  }
}

.title-top__02 {
  line-height: 1;
  @include mq(sp) {
    text-align: center;
  }
  a {
    position: relative;
    display: inline-block;
    padding-right: 90px;
    @include mq(sp) {
      padding: 0 60px;
    }
    &::before {
      position: absolute;
      top: 50%;
      right: 20px;
      content: "";
      display: block;
      width: 60px;
      height: 1px;
      background: $main;
      transition: .3s;
      transform: translateY(-8px);
      @include mq(sp) {
        width: 30px;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 18px;
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      background: $main;
      transform: translateY(-15px) rotate(45deg);
      transition: .3s;
      @include mq(sp) {
        width: 12px;
        transform: translateY(-12px) rotate(45deg);
      }
    }
    &:hover {
      &::before {
        right: 10px;
        width: 70px;
      }
      &::after {
        right: 8px;
      }
      .en {
        color: $black;
      }
      .ja {
        color: $main;
      }
    }
  }
  .en {
    margin-bottom: 10px;
    color: $main;
    font-family: $font-roboto;
    font-size: 63px;
    font-weight: 300;
    letter-spacing: 8px;
    transition: .3s;
    @include mq(sp) {
      @include fz_vw(34);
      margin-bottom: 5px;
      letter-spacing: 4px;
      text-align: center;
    }
  }
  .ja {
    font-size: 21px;
    font-weight: 300;
    text-align: center;
    @include mq(sp) {
      @include fz_vw(13);
    }
  }
}

.title-side__01 {
  padding: 5px 10px;
  background: $sub;
  color: $main;
  font-weight: 500;
}

.title-page__01 {
  border-bottom: 4px solid $main;
  font-size: 32px;
  font-weight: 500;
  @include mq(sp) {
    border-bottom: 2px solid $main;
    font-size: 18px;
  }
}
.title-page__02 {
  color: $main;
  font-size: 34px;
  font-weight: 500;
  text-align: center;
  @include mq(sp) {
    @include fz_vw(19);
  }
}



